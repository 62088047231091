import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';

/* Page de gestion des incoterms */ 
export default class FormCodifIncoterm extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [], 
            formData: {
                id: 0,
                libelle: '',
                actifCodif: "Oui",
                codeCodif: "",
                paramCodif: "INCOTERM" 
            },
            showindicator: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);

        this.appservice = new AppServices();
    }


    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }

    /* Récupération de la liste globale des codifications */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_codification(this.state.formData.paramCodif).then(data => {
            this.setState({ liste_data: data, showindicator: false }); 
        }); 
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                libelle: '',
                codeCodif: "",
                actifCodif: "Oui",
                paramCodif: "INCOTERM" 
            }
        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }


//login:aichyouk
    /* Enregistrer les informations du formulaire codification */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.libelle == "" || this.state.formData.codeCodif == "" || this.state.formData.actifCodif == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_codification(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data(); 
                    }
                }
            });
        }
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle codification */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des classes en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("LISTE_INCOTERM");
    }  


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des codifications */
    actionTemplate(rowData, column) {
        const items = [
            { 
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
        ]
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
    }

    /* Fonction de suppression de la codification sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_codification(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();  
                }
            }); 
        }
    }


    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 570 }}> 
                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="p-mb-4" style={{ paddingTop: 2, paddingBottom: 2 }}  left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <br />

                        {/* Tableau contenant la liste des codifications existantes dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} showGridlines
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single"
                            onRowClick={e => this.setState({ selectedData: e.data }, () => {
                            })}> 
                            <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                            <Column field="libelle" header="Incoterm" filter={true} filterMatchMode='contains' />
                            <Column field="codeCodif" header="Code" filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                            <Column field="actifCodif" header="Actif" filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                        </DataTable>

                        {/* Boite de dialogue d'ajout et de modification d'une codification  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid"> 
                                <div className="field">
                                    <label htmlFor="libelle">Incoterm *</label>
                                    <InputText  id="name1" value={this.state.formData.libelle} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelle })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelle', '', this.state.formData) })}
                                        required autoFocus />

                                    {this.state.submit && !this.state.formData.libelle && <small className="p-invalid" style={{ color: 'red' }}>Le champs incoterm est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="libelle">Code *</label>
                                    <InputText  id="name1" value={this.state.formData.codeCodif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.codeCodif })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'codeCodif', '', this.state.formData) })}
                                        required  />
                                    {this.state.submit && !this.state.formData.codeCodif && <small className="p-invalid" style={{ color: 'red' }}>Le champs code est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="actifCodif">Actif *</label>
                                    <Dropdown  autoWidth={false} value={this.state.formData.actifCodif} filter={true} filterBy='label' filterMatchMode='contains'
                                        options={[{value: "Oui",label: "Oui"},{value: "Non",label: "Non"}]}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'actifCodif', '', this.state.formData) })}
                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.actifCodif })} />
                                    {this.state.submit && !this.state.formData.actifCodif && <small className="p-invalid" style={{ color: 'red' }}>Le champs actif est obligatoire.</small>}
                                </div> 
                                
                            </div>
                        </Dialog> 
                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedData.libelle}</b>?</span>}
                            </div>
                        </Dialog> 
                    </div>
                </div>
            </div >
        );
    }
}
