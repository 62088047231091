import React, { useState, useEffect, useRef } from 'react';
import AppServices from '../service/AppServices';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { v4 as uuidv4 } from 'uuid';
import { Dialog } from 'primereact/dialog';

const Dashboard = (props) => {
    const appservice = new AppServices();
    const [chartOptions, setChartOptions] = useState({});
    const [liste_st_dmd_statut, setListe_st_dmd_statut] = useState([]);
    const [liste_st_dmm_type, setListe_st_dmd_type] = useState([]);
    const [liste_st_dmd_mois, setListe_st_dmd_mois] = useState([]);
    const [nbDem, setNbDem] = useState(0);
    const [nbEc, setNbEc] = useState(0);
    const [nbRejet, setNbRejet] = useState(0);
    const [nbSuspendu, setNbSuspendu] = useState(0);
    const [ent, setEnt] = useState(0);

    const [nbVal, setNbVal] = useState(0);
    const [showindicator, setShowindicator] = useState(false);
    const [liste_action, setListe_action] = useState([]);
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        refreshDashboard();
        setShowindicator(true);
        appservice.get_liste_last_actions('Connexion').then((data) => {
            setShowindicator(false);
            setListe_action(data);
        });
    }, []);


    const refreshDashboard = () => {
        setShowindicator(true);
        setVisible(true);
        appservice.get_dashboard().then((data) => {
            setShowindicator(false);
            setVisible(false);
             if (data !== null && data !== undefined) {
                setNbVal(data.nbVal);
                setNbRejet(data.nbRejet);
                setNbEc(data.nbEc);
                setNbDem(data.nbDem);
                setNbSuspendu(data.nbSuspendu);
                setEnt(data.ent);
                setListe_st_dmd_mois(data.liste_st_dmd_mois);
                setListe_st_dmd_type(data.liste_st_dmd_type);
                setListe_st_dmd_statut(data.liste_st_dmd_statut);
            } 
        });
    }

    const showChartEvolution = () => {
        let liste_label = [];
        let liste_ds_tarif = [];
        let liste_ds_valeur = [];
        let liste_ds_origine = [];

        if (liste_st_dmd_mois.length > 0) {
            liste_label = liste_st_dmd_mois.map(x => {
                return x.periode
            });

            liste_ds_tarif = liste_st_dmd_mois.map(x => {
                return x.nbTarif
            });

            liste_ds_origine = liste_st_dmd_mois.map(x => {
                return x.nbOrigine
            });

            liste_ds_valeur = liste_st_dmd_mois.map(x => {
                return x.nbValeur
            });
        }
        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Tarif',
                    data: liste_ds_tarif,
                    fill: false,
                    borderColor: '#022d67',
                    tension: .4
                },
                {
                    label: 'Origine',
                    data: liste_ds_origine,
                    fill: false,
                    borderColor: '#5f78d8',
                    tension: .4
                },
                {
                    label: 'Valeur',
                    data: liste_ds_valeur,
                    fill: false,
                    borderColor: '#13b3af',
                    tension: .4
                },

            ]
        };

        return <div>
            <center>
                <Chart type="line" height='155w' data={chartData} options={chartOptions} />
            </center>
        </div>

    }
 
    const showChartDmdStatut = () => {
        let liste_label = [];
        let liste_ds_val = [];
        let liste_ds_rejet = [];
        let liste_ds_ec = [];
        if (liste_st_dmd_statut.length > 0) {
            liste_label = liste_st_dmd_statut.map(x => {
                return x.type
            });

            liste_ds_val = liste_st_dmd_statut.map(x => {
                return x.nbVal
            });

            liste_ds_rejet = liste_st_dmd_statut.map(x => {
                return x.nbRejet
            });

            liste_ds_ec = liste_st_dmd_statut.map(x => {
                return x.nbEc
            });
        }
        const options = {
            indexAxis: 'x',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: "#000"
                    }
                }
            },
            scales: {
                x: {
                    stacked: false,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                },
                y: {
                    stacked: false,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                }
            }
        };

        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    type: 'bar',
                    label: 'Rendu',
                    backgroundColor: '#689F38',
                    data: liste_ds_val,
                    /* barThickness: 34,
                    borderRadius: 34, */
                },
                {
                    type: 'bar',
                    label: 'En cours',
                    backgroundColor: '#FBC02D',
                    data: liste_ds_ec,
                   /*  barThickness: 34,
                    borderRadius: 34, */
                },
                {
                    type: 'bar',
                    label: 'Rejeté',
                    backgroundColor: '#D32F2F',
                    data: liste_ds_rejet,
                    /* barThickness: 34,
                    borderRadius: 34, */
                },
               
            ]
        };



        return <div>
            <center>
                <Chart type="bar" data={chartData} options={options} height='350w' />
            </center>
        </div>

    }

    const showChartDmdType = () => {
        let liste_label = [];
        let liste_ds = [];
        if (liste_st_dmm_type.length > 0) {
            liste_label = liste_st_dmm_type.map(x => {
                return x.label
            });

            liste_ds = liste_st_dmm_type.map(x => {
                return x.value
            });
        }
        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    backgroundColor: [
                        "#022d67",
                        "#13b3af",
                        "#5f78d8",
                    ],
                    data: liste_ds
                }
            ]
        };

        const options = {
            cutout: '60%'
        };

        return <div>
            <center>
                <Chart type="doughnut" data={chartData} options={options} height='30w' />
            </center>
        </div>

    }

    return (

        <div className="grid dashboard" style={{ backgroundColor: '#f9fafd' }}>
             <Dialog visible={visible} showHeader={false} modal style={{ width: '70px', height: '70px',borderRadius: 17,opacity: 0.6}} contentStyle={{backgroundColor: '#f7f7f7',borderRadius: 17}} onHide={() => setVisible(false)}>
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div>
                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" />
                    </div>
                </div>

            </Dialog>

            {appservice.getProfil() == 'Guest' ?
                <div className="grid">

                    <div className="col-12 banner" style={{ minHeight: 170, width: '99%' }}>
                        <div className="p-fluid formgrid grid" style={{ paddingLeft: 20, paddingTop: 20 }}>

                            <div className="field col-12 md:col-6">
                                <div>
                                    <div className="grid" >
                                        <div className="col-2" >
                                            <img src="assets/layout/images/armoirie.png" style={{ width: '100px' }} alt="apollo-layout" className="logo" />
                                        </div>
                                        <div className="col-10" >
                                            <label style={{ fontSize: 16, color: '#fff', fontWeight: 600, marginTop: 2 }}>
                                                Bienvenue sur votre espace connecté
                                                <hr style={{ width: '100%' }} />
                                            </label>


                                            <span style={{ fontSize: 12, color: '#f7f7f7', fontWeight: 600, width: 300, marginTop: -20, display: 'block' }}>
                                                Système de gestion des demandes anticipées  en matière de classement  tarifaire, origine et valeur
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field col-6 md:col-3">
                                <label style={{ fontSize: 14, color: '#fff', fontWeight: 600 }}>INFORMATIONS DU PROFIL</label><br />
                                {appservice.get_userconnected() !== null && appservice.get_userconnected() !== undefined ?
                                    <div style={{ color: '#f7f7f7', fontSize: 12 }}>
                                        <hr style={{ margin: 1, width: 185 }} />
                                        {appservice.get_userconnected().nom}<br />
                                        {appservice.get_userconnected().username}<br />
                                        {appservice.get_userconnected().idOp.nom}<br />
                                        {appservice.get_userconnected().profil}
                                    </div>
                                    : null}

                            </div>
                            <div className="field col-6 md:col-3">
                                <label style={{ fontSize: 14, color: '#fff', fontWeight: 600 }}>DERNIERES CONNEXIONS</label>
                                {liste_action.length > 0 ?
                                    <div style={{ color: '#f7f7f7', fontSize: 12 }}>
                                        <hr style={{ margin: 1, width: 175 }} />
                                        {liste_action.map(x => {
                                            return <>
                                                <label>{x.date}</label><br />
                                            </>
                                        })}
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>



                    <div className="col-12" style={{ marginTop: 15 }}>
                        <i className="pi pi-chart-bar"></i> <label style={{ color: '#000' }}>Situation de mes demandes</label>
                        <hr style={{ marginTop: 2, color: '#ccc', marginBottom: -3 }} />

                    </div>

                    <>
                        <div className="col-6 md:col-3">
                            <div className="guest-overview-box"><h1>TOTAL DAMANDE</h1>
                                <div className="overview-value">{appservice.formaterValueSep(nbDem)}</div>
                                <div className="overview-ratio">
                                    <div className="overview-direction">
                                        <i className="pi pi-pencil" style={{ fontSize: 25 }}></i>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-6 md:col-3">
                            <div className="guest-overview-box">
                                <h1>TOTAL RENDU</h1>
                                <div className="overview-value">{appservice.formaterValueSep(nbVal)}</div>
                                <div className="overview-ratio">
                                    <div className="overview-direction">
                                        <i className="pi pi-thumbs-up" style={{ fontSize: 25 }}></i>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-6 md:col-3">
                            <div className="guest-overview-box">
                                <h1>TOTAL REJETÉ</h1>
                                <div className="overview-value">{appservice.formaterValueSep(nbRejet)}</div>
                                <div className="overview-ratio">
                                    <div className="overview-direction">
                                        <i className="pi pi-thumbs-down" style={{ fontSize: 25 }}></i>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-6 md:col-3">
                            <div className="guest-overview-box">
                                <h1>TOTAL EN COURS</h1>
                                <div className="overview-value">{appservice.formaterValueSep(nbEc)}</div>
                                <div className="overview-ratio">
                                    <div className="overview-direction">
                                        <i className="pi pi-cog" style={{ fontSize: 25 }}></i>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>



                    <div className='col-12' style={{ marginTop: 20 }} >
                        <div className="section-title">
                            <h3>Faire une demande anticipée de classement</h3>
                        </div>
                        <div className="grid" style={{ marginTop: -35, width: '100%' }} >
                            <div className="col-6 md:col-4">
                                <div className="service-box">
                                    <i className="icofont-cube" style={{ fontSize: 62 }} ></i>
                                    <h3>Tarif</h3>
                                    <Button icon='pi pi-pencil' onClick={(e) => {
                                        window.location = `#/demandes?key=${uuidv4()}&type=Tarif`;
                                    }} label="Faire une nouvelle demande" style={{ borderRadius: 20 }} />
                                </div>
                            </div>

                            <div className="col-6 md:col-4">
                                <div className="service-box">
                                    <i className="icofont-flag" style={{ fontSize: 62 }} ></i>
                                    <h3>Origine</h3>
                                    <Button icon='pi pi-pencil' onClick={(e) => {
                                        window.location = `#/demandes?key=${uuidv4()}&type=Origine`;
                                    }} label="Faire une nouvelle demande" style={{ borderRadius: 20 }} />
                                </div>
                            </div>

                            <div className="col-6 md:col-4">
                                <div className="service-box">
                                    <i className="icofont-money-bag" style={{ fontSize: 62 }} ></i>
                                    <h3>Valeur</h3>
                                    <Button icon='pi pi-pencil' onClick={(e) => {
                                        window.location = `#/demandes?key=${uuidv4()}&type=Valeur`;
                                    }} label="Faire une nouvelle demande" style={{ borderRadius: 20 }} />
                                </div>
                            </div>

                        </div>



                    </div>
                </div>

                :

                <div className="row justify-content-center" >
                    <div className="col-sm-6 col-lg-2">
                        <div className="overview-box overview-box-1"><h1>TOTAL DAMANDE</h1>
                            <div className="overview-value">{appservice.formaterValueSep(nbDem)}</div>
                            <div className="overview-ratio">
                                <div className="overview-direction" style={{borderRadius: '50%', height: 23, width: 25}}>
                                    <i className="pi pi-pencil"></i>
                                </div>

                            </div>
                            <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-2">
                        <div className="overview-box overview-box-2">
                            <h1>TOTAL RENDU</h1>
                            <div className="overview-value">{appservice.formaterValueSep(nbVal)}</div>
                            <div className="overview-ratio">
                                <div className="overview-direction" style={{borderRadius: '50%', height: 23, width: 25}}>
                                    <i className="pi pi-thumbs-up"></i>
                                </div>

                            </div>
                            <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-2">
                        <div className="overview-box overview-box-4">
                            <h1>TOTAL REJETÉ</h1>
                            <div className="overview-value">{appservice.formaterValueSep(nbRejet)}</div>
                            <div className="overview-ratio">
                                <div className="overview-direction" style={{borderRadius: '50%', height: 23, width: 25}}>
                                    <i className="pi pi-thumbs-down"></i>
                                </div>

                            </div>
                            <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-2">
                        <div className="overview-box overview-box-3">
                            <h1>TOTAL EN COURS</h1>
                            <div className="overview-value">{appservice.formaterValueSep(nbEc)}</div>
                            <div className="overview-ratio">
                                <div className="overview-direction" style={{borderRadius: '50%', height: 23, width: 25}}>
                                    <i className="pi pi-cog"></i>
                                </div>

                            </div>
                            <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                        </div>
                    </div>

                
                    <div className="col-sm-6 col-lg-2">
                        <div className="overview-box overview-box-5">
                            <h1>TOTAL SUSPENDU</h1>
                            <div className="overview-value">{appservice.formaterValueSep(nbSuspendu)}</div>
                            <div className="overview-ratio">
                                <div className="overview-direction" style={{borderRadius: '50%', height: 23, width: 25}}>
                                    <i className="pi pi-ban"></i>
                                </div>

                            </div>
                            <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-2">
                        <div className="overview-box overview-box-6">
                            <h1>ENT</h1>
                            <div className="overview-value">{ent} J</div>
                            <div className="overview-ratio">
                                <div className="overview-direction" style={{borderRadius: '50%', height: 23, width: 25}}>
                                    <i className="pi pi-thumbs-up"></i>
                                </div>

                            </div>
                            <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                        </div>
                    </div>


                    <div className="col-12 card" style={{ minHeight: 1200 }}>
                        <div className="row justify-content-center">
                            <div className="col-sm-6 col-lg-6" style={{}}>
                                <i className="pi pi-table"></i> Statistiques des demandes par mois
                                <hr style={{ marginTop: 2 }} />
                                <div className="orders-subtable">
                                    <DataTable value={liste_st_dmd_mois} dataKey='id'
                                        paginator rows={10} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"  showGridlines
                                        emptyMessage="No data found" alwaysShowPaginator={false}>
                                        <Column header="NO" body={(rowData, props) => {
                                            return <div>
                                                <span >{props.rowIndex + 1}</span>
                                            </div>
                                        }} style={{ width: '5%' }} />
                                        <Column field="periode" header="MOIS" />
                                        <Column field="nbRejet" header="REJETE" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nbRejet)}</span>
                                            </div>
                                        }} style={{ width: '15%' }} />
                                        <Column field="nbVal" header="VALIDE" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nb)}</span>
                                            </div>
                                        }} style={{ width: '15%' }} />
                                        <Column field="nbEc" header="TRAITEMENT" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nbEc)}</span>
                                            </div>
                                        }} style={{ width: '15%' }} />
                                        <Column field="nbDem" header="TOTAL" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nbDem)}</span>
                                            </div>
                                        }} style={{ width: '15%' }} />
                                    </DataTable>
                                </div>

                            </div>
 

                            <div className="col-sm-6 col-lg-6" >

                                <i className="pi pi-chart-line"></i> Statistiques des demandes par mois
                                <hr style={{ marginTop: 2 }} />
                                {showChartEvolution()}
                            </div>

                            <div className="col-sm-6 col-lg-6" >
                                <i className="pi pi-chart-line"></i> Statistiques des demandes par statut
                                <hr style={{ marginTop: 2 }} />
                                {showChartDmdStatut()}
                            </div>

                            <div className="col-sm-6 col-lg-6" >
                                <i className="pi pi-chart-line"></i> Statistiques des demandes par type
                                <hr style={{ marginTop: 2 }} />
                                {showChartDmdType()}
                            </div>


                        </div>
                    </div>
                </div>}





        </div >
    )















}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
