import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import AppServices from '../service/AppServices';
import { SplitButton } from 'primereact/splitbutton';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';

/* Page d'édition des statistiques des demandes par statut */
export default class EditionStatDemandeStatut extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            pd1: new Date(),
            pd2: new Date(),
            showindicator: false,
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ], 
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 30);
        this.appservice = new AppServices();
    }



    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }


    /* Récupération de la liste globale des demandes */
    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.appservice.get_liste_stat_demande_statut(spd1, spd2).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }




    getPanelRecherche() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        return <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
            <div className="grid">
                <div className="col-6 md:col-3" >
                    <span className="p-float-label" style={{ marginTop: 8 }}>
                        <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2024:2070" style={{width: '100%'}}/>
                        <label htmlFor="inputtext" >Date début</label>
                    </span>
                </div>
                <div className="col-6 md:col-3" style={{ marginTop: 8 }}>
                    <span className="p-float-label">
                        <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })}  dateFormat="dd/mm/yy" yearNavigator yearRange="2024:2070" style={{width: '100%'}}/>
                        <label htmlFor="inputtext" >Date fin</label>
                    </span>
                </div>
                <div className="col-6 md:col-3" style={{ marginTop: 8 }}>
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                        <SplitButton label="Rechercher" icon="pi pi-search"  onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                    }
                </div>

            </div>

        </Panel>;
    }



    /* Exportation du tableau contenant la liste des messages en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("STAT_DEMANDE_STATUT_");
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => { 
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'TYPE': x.type,'TOTAL': x.nbDem,'VALIDE': x.nbVal,'REJETE': x.nbRejet,'EN_COURS': x.nbEc}
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'STAT_DEMANDE_STATUT_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    /* Bloc visuel (propre à React) */
    render() {

        let liste_label = [];
        let liste_ds_val = [];
        let liste_ds_rejet = [];
        let liste_ds_ec = [];

        liste_label = this.state.liste_data.map(x => {
            return x.type
        });

        liste_ds_val = this.state.liste_data.map(x => {
            return x.nbVal
        });

        liste_ds_rejet = this.state.liste_data.map(x => {
            return x.nbRejet
        });

        liste_ds_ec = this.state.liste_data.map(x => {
            return x.nbEc
        });

        const options = {
            indexAxis: 'x',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: "#000"
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                }
            }
        };

        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    type: 'bar',
                    label: 'Validé',
                    backgroundColor: '#97ce74',
                    data: liste_ds_val
                },
                {
                    type: 'bar',
                    label: 'Rejeté',
                    backgroundColor: '#d66452',
                    data: liste_ds_rejet
                },
                {
                    type: 'bar',
                    label: 'En cours',
                    backgroundColor: '#e3b572',
                    data: liste_ds_ec
                },
            ]
        };


        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 500, paddingBlock: 2 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {this.getPanelRecherche()}

                        {/* Tableau contenant la liste  des factures */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} showGridlines
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={false}>
                            <Column body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} header="No" style={{ width: '5%' }} />
                            <Column field="type" header="TYPE"  sortable />
                            <Column field="nbDem" header="TOTAL" sortable style={{ width: '10%' }} />
                            <Column field="nbVal" header="VALIDE" sortable style={{ width: '10%' }} />
                            <Column field="nbRejet" header="REJETE" sortable style={{ width: '10%' }} />
                            <Column field="nbEc" header="EN COURS" sortable style={{ width: '10%' }} />
                        </DataTable>

                        <Chart type="bar" data={chartData} options={options} height='300w' />

                    </div>
                </div>
            </div >
        );
    }
}
