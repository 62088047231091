import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { InputMask } from 'primereact/inputmask';

/* Page de gestion des Operateurs */
export default class FormOperateur extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [],
            formData: {
                id: 0,
                nom: "",
                logo: "",
                anneeCreation: "",
                codeNif: '',
                rccm: "",
                statutJuridique: "", 
                adresse: "",
                type: "",
                clee: "",
                code: "",
                suspendu: false,
                motifSuspension: "",
                telephone: "",
                email: "",
            },
            liste_type_op: [
                { value: 'Transitaire', label: 'Transitaire' },
                { value: 'Opérateur', label: 'Opérateur' },
                { value: 'Courtier', label: 'Courtier' },
                { value: 'Administration', label: 'Administration' },
            ],
            liste_statut_juridique: [
                { value: 'SA', label: 'SA' },
                { value: 'SASU', label: 'SASU' },
                { value: 'GIE', label: 'GIE' },
                { value: 'SARL', label: 'SARL' },
                { value: 'SARLU', label: 'SARLU' },
            ],
            showindicator: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
            visible_dlg_toogle: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.conf_toogle_data = this.conf_toogle_data.bind(this);
        this.appservice = new AppServices();
    }

    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }


    /* Récupération de la liste globale des opérateurs */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_operateur().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                nom: "",
                logo: "",
                anneeCreation: "",
                codeNif: "",
                rccm: "",
                statutJuridique: "",
                adresse: "",
                type: "",
                clee: this.appservice.get_key(),
                code: "",
                suspendu: false,
                motifSuspension: "",
                telephone: "",
                email: "",
            }
        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }


    /* Enregistrer les informations du formulaire opérateur */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.nom == "" || this.state.formData.anneeCreation == "" || this.state.formData.telephone == "" || this.state.formData.email == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_operateur(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'un nouveau opérateur */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des classes en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("LISTE_OPERATEUR");
    }


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des codifications */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
        ]
        if (rowData.suspendu == false) {
            items.push(
                {
                    label: 'Suspendre',
                    icon: 'pi pi-ban',
                    command: (e) => {
                        rowData.action = ' suspendre ';
                        rowData.suspendu = true;
                        this.conf_toogle_data(rowData);
                    }
                }
            )
        } else {
            items.push(
                {
                    label: 'Activer',
                    icon: 'pi pi-check',
                    command: (e) => {
                        rowData.action = ' activer ';
                        rowData.suspendu = false;
                        this.conf_toogle_data(rowData);
                    }
                }
            )
        }
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
    }


    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_toogle_data(data) {
        this.setState({ visible_dlg_toogle: true });
        this.setState({ selectedData: data });
    }

    /* Fonction de suspension ou d'activation de l'utilisateur sélectionné */
    toogle_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne' });
        } else {
            this.appservice.save_utilisateur(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }

    /* Fonction de suppression de la dvaleur sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_operateur(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }


    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 570 }}>
                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="p-mb-4" style={{ paddingTop: 2, paddingBottom: 2 }}  left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <br />

                        {/* Tableau contenant la liste des notifications existantes dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single"
                            onRowClick={e => this.setState({ selectedData: e.data }, () => {
                            })}>
                            <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                            <Column field="nom" header="Nom opérateur" filter={true} filterMatchMode='contains' />
                            <Column field="type" header="Type" filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                            <Column field="telephone" header="telephone" filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                            <Column field="statutJuridique" header="Statut" filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                            <Column field="email" header="Emaim" filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                            <Column field="adresse" header="Adresse" filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                        </DataTable>



                        {/* Boite de dialogue d'ajout et de modification des Opérateurs  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '80%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
 
                            <div className="row justify-content-center">

                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="nom">Nom *</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputText id="nom" value={this.state.formData.nom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.nom })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nom', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.nom && <small className="p-invalid" style={{ color: 'red' }}>Le champs nom est obligatoire.</small>}
                                </div>

                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="type">Type * </label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <Dropdown id='type' options={this.state.liste_type_op} value={this.state.formData.type}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'type', '', this.state.formData) })} autoWidth={false} style={{ width: '100%' }}
                                        filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.type })} />
                                    {this.state.submit && !this.state.formData.type && <small className="p-invalid" style={{ color: 'red' }}>Le champs type est obligatoire</small>}
                                </div>

                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="rccm">Téléphone *</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputMask mask="999999999" id="name" value={this.state.formData.telephone} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.telephone })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telephone', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le champs téléphone est obligatoire.</small>}
                                </div>

                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="email">Email *</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputText id="email" value={this.state.formData.email} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.email })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'email', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.email && <small className="p-invalid" style={{ color: 'red' }}>Le champs email est obligatoire</small>}
                                </div>


                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="anneeCreation">Année création *</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputMask mask="9999"  id="anneeCreation" value={this.state.formData.anneeCreation} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.anneeCreation })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'anneeCreation', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.anneeCreation && <small className="p-invalid" style={{ color: 'red' }}>Le champs année de creation est obligatoire.</small>}
                                </div>


                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="rccm">Code NIF *</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputText id="rccm" value={this.state.formData.codeNif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.codeNif })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'codeNif', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.codeNif && <small className="p-invalid" style={{ color: 'red' }}>Le champs NIF est obligatoire.</small>}
                                </div>

                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="rccm">RCCM *</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputText id="rccm" value={this.state.formData.rccm} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.rccm })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'rccm', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.rccm && <small className="p-invalid" style={{ color: 'red' }}>Le champs rccm est obligatoire.</small>}
                                </div>


                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="statutJuridique">Statut Juridique * </label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <Dropdown id='statutJuridique' options={this.state.liste_statut_juridique} value={this.state.formData.statutJuridique}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'statutJuridique', '', this.state.formData) })} autoWidth={false} style={{ width: '100%' }}
                                        filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.statutJuridique })} />
                                    {this.state.submit && !this.state.formData.statutJuridique && <small className="p-invalid" style={{ color: 'red' }}>Le champs statut juridique est obligatoire</small>}
                                </div>


                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="adresse">Adresse * </label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputText autoWidth={false} value={this.state.formData.adresse} filter={true} filterBy='label' filterMatchMode='contains'
                                        options={this.state.adresse}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'adresse', '', this.state.formData) })}
                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.adresse })} />
                                    {this.state.submit && !this.state.formData.adresse && <small className="p-invalid" style={{ color: 'red' }}>Le champs adresse est obligatoire</small>}
                                </div>

                                <div className="col-sm-12 col-lg-2">
                                    <label htmlFor="code">Code SYDONIA</label>
                                </div>
                                <div className="col-sm-12 col-lg-4">
                                    <InputText id="code" value={this.state.formData.code} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.code })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'code', '', this.state.formData) })}
                                    />
                                </div>


                            </div>
                        </Dialog>

                        {/* Boite de dialogue de confirmation de la suspension ou l'activation */}
                        <Dialog visible={this.state.visible_dlg_toogle} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_toogle: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_toogle: false });
                                        this.toogle_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_toogle: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment {this.state.selectedData.action} <b>{this.state.selectedData.login}</b> ?</span>}
                            </div>
                        </Dialog>



                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedData.nom}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div >
        );
    }
}
