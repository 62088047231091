import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-6">
              <div className="footer-item">

                <div className="footer-contact pe-2">

                <img src="assets/layout/images/log-footer.png" width={500} alt="apollo-layout"  />
                <hr style={{color: '#f7f7f7'}}/>
                  {/* <h3>Contact Us</h3> */}
                  <ul>
                    <li>
                      <i className="icofont-ui-message"></i>
                      <a href="mailto:contact@dgd.gov.gn">contact@dgd.gov.gn</a>
                      <a href="mailto:dactov@dgd.gov.gn">dactov@dgd.gov.gn</a>
                    </li>
                    <li>
                      <i className="icofont-stock-mobile"></i>
                      <a href="tel:+224663990099">Tél: +224 663 99 00 99</a>
                      <a href="tel:+224623990099">Tél: +224 623 99 00 00</a>
                    </li>
                    <li>
                      <i className="icofont-location-pin"></i>
                      Rond Point du Port, Kaloum
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-quick">
                  <h3>Liens nationaux</h3>
                  <ul>
                    <li>
                      <a target="_blank" href="https://presidence.gov.gn">PRG (DCI)</a>
                    </li>
                    <li>
                      <a target="_blank"  href="https://primature.gov.gn">Primature</a>
                    </li>
                    <li>
                      <a  target="_blank" href="https://www.sgg.gov.gn">SGG</a>
                    </li>
                    <li>
                      <a target="_blank"  href="https://mbudget.gov.gn">MB</a>
                    </li>
                    <li>
                      <a  target="_blank" href="https://dgd.gov.gn/">DGD</a>
                    </li>
                    <li>
                      <a  target="_blank" href="https://dgi.gov.gn">DGI</a>
                    </li>
                    <li>
                      <a  target="_blank" href="https://www.bcrg-guinee.org">BCRG</a>
                    </li>
                    <li>
                      <a  target="_blank" href="https://guceg.gov.gn/">GUCEG</a>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-quick">
                  <h3>Liens internationaux</h3>
                  <ul>
                    <li>
                      <a href="https://www.wcoomd.org/fr.aspx">OMD</a>
                    </li>
                    <li>
                      <a href="https://www.wto.org/indexfr.htm">OMC</a>
                    </li>
                    <li>
                      <a href="https://www.imf.org/fr/Home">FMI</a>
                    </li>
                    <li>
                      <a href="https://www.afd.fr/fr">AFD</a>
                    </li>
                    <li>
                      <a href="https://www.banquemondiale.org/fr/home">BM</a>
                    </li>
                    <li>
                      <a href="https://www.uemoa.int/">UEMOA</a>
                    </li>
                    <li>
                      <a href="https://au.int/fr/node/3587">UA (OHADA, BAD)</a>
                    </li>
                    <li>
                      <a href="https://ecowas.int/">CEDAO (BCEAO…)</a>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>

{/*             <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-feedback">
                  <h3>Feedback</h3>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="your_message"
                        rows="3"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="text-left">
                      <button type="submit" className="btn feedback-btn">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
 */}







          </div>
        </div>
      </footer>

      <div className="copyright-area">
        <div className="container">
          <div className="copyright-item">
            <p>
              Copyright &copy; {currentYear} Développé par {" "}
              <a href="#" target="_blank">
                Option Plus Technologie
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
