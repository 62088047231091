import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import AppServices from '../service/AppServices';
import { SplitButton } from 'primereact/splitbutton';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

/* Page d'édition des statistiques générales des demandes */
export default class EditionStatDemande extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            pd1: new Date(),
            pd2: new Date(),
            nbDem: 0,
            nbEc: 0,
            nbRejet: 0,
            nbVal: 0,

            nbTarif: 0,
            nbOrigine: 0,
            nbValeur: 0,

            nbTarifEc: 0,
            nbTarifRejet: 0,
            nbTarifVal: 0,

            nbOrigineEc: 0,
            nbOrigineRejet: 0,
            nbOrigineVal: 0,

            nbValeurEc: 0,
            nbValeurRejet: 0,
            nbValeurVal: 0,
            showindicator: false,
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ],
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 30);
        this.appservice = new AppServices();
    }



    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }


    /* Récupération de la liste globale des demandes */
    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.setState({
            showindicator: true,
            nbDem: 0,
            nbEc: 0,
            nbRejet: 0,
            nbVal: 0,

            nbTarif: 0,
            nbOrigine: 0,
            nbValeur: 0,

            nbTarifEc: 0,
            nbTarifRejet: 0,
            nbTarifVal: 0,

            nbOrigineEc: 0,
            nbOrigineRejet: 0,
            nbOrigineVal: 0,

            nbValeurEc: 0,
            nbValeurRejet: 0,
            nbValeurVal: 0,
            liste_data: []
        });

        this.appservice.get_liste_stat_demande_mois(spd1, spd2).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let nbDem= 0;
                    let nbEc= 0;
                    let nbRejet= 0;
                    let nbVal= 0;

                    let nbTarif= 0;
                    let nbOrigine= 0;
                    let nbValeur= 0;

                    let nbTarifEc= 0;
                    let nbTarifRejet= 0;
                    let nbTarifVal= 0;

                    let nbOrigineEc= 0;
                    let nbOrigineRejet= 0;
                    let nbOrigineVal= 0;

                    let nbValeurEc= 0;
                    let nbValeurRejet= 0;
                    let nbValeurVal= 0;

                    for (let i = 0; i < data.length; i++) {
                        nbDem += data[i].nbDem;
                        nbEc += data[i].nbEc;
                        nbRejet += data[i].nbRejet;
                        nbVal += data[i].nbVal;
                        nbTarif += data[i].nbTarif;
                        nbOrigine += data[i].nbOrigine;
                        nbValeur += data[i].nbValeur;
                        nbTarifEc += data[i].nbTarifEc;
                        nbTarifRejet += data[i].nbTarifRejet;
                        nbTarifVal += data[i].nbTarifVal;
                        nbOrigineEc += data[i].nbOrigineEc;
                        nbOrigineRejet += data[i].nbOrigineRejet;
                        nbOrigineVal += data[i].nbOrigineVal;
                        nbValeurEc += data[i].nbValeurEc;
                        nbValeurRejet += data[i].nbValeurRejet;
                        nbValeurVal += data[i].nbValeurVal;
                    }

                    this.setState({
                        nbDem: nbDem,
                        nbEc: nbEc,
                        nbRejet: nbRejet,
                        nbVal: nbVal,
            
                        nbTarif: nbTarif,
                        nbOrigine: nbOrigine,
                        nbValeur: nbValeur,
            
                        nbTarifEc: nbTarifEc,
                        nbTarifRejet: nbTarifRejet,
                        nbTarifVal: nbTarifVal,
            
                        nbOrigineEc: nbOrigineEc,
                        nbOrigineRejet: nbOrigineRejet,
                        nbOrigineVal: nbOrigineVal,
            
                        nbValeurEc: nbValeurEc,
                        nbValeurRejet: nbValeurRejet,
                        nbValeurVal: nbValeurVal,
                    });

                }
            }
        });
    }




    getPanelRecherche() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        return <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
            <div className="grid">
                <div className="col-6 md:col-3" >
                    <span className="p-float-label" style={{ marginTop: 8 }}>
                        <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2024:2070" style={{ width: '100%' }} />
                        <label htmlFor="inputtext" >Date début</label>
                    </span>
                </div>
                <div className="col-6 md:col-3" style={{ marginTop: 8 }}>
                    <span className="p-float-label">
                        <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2024:2070" style={{ width: '100%' }} />
                        <label htmlFor="inputtext" >Date fin</label>
                    </span>
                </div>
                <div className="col-6 md:col-3" style={{ marginTop: 8 }}>
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                        <SplitButton label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                    }
                </div>

            </div>

        </Panel>;
    }



    /* Exportation du tableau contenant la liste des messages en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("STAT_DEMANDE_");
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'TYPE': x.type, 'TOTAL': x.nbDem, 'VALIDE': x.nbVal, 'REJETE': x.nbRejet, 'EN_COURS': x.nbEc, 'TOTAL_TARIF': x.nbTarif, 'TARIF_VALIDE': x.nbTarifVal, 'TARIF_REJETE': x.nbTarifRejet, 'TARIF_EN_COURS': x.nbTarifRejet, 'TOTAL_ORIGINE': x.nbOrigine, 'ORIGINE_VALIDE': x.nbOrigineVal, 'ORIGINE_REJETE': x.nbOrigineRejet, 'ORIGINE_EN_COURS': x.nbOrigineEc, 'TOTAL_VALEUR': x.nbValeur, 'VALEUR_VALIDE': x.nbValeur, 'VALEUR_REJETE': x.nbValeurRejet, 'VALEUR_EN_COURS': x.nbValeurEc    }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'STAT_DEMANDE_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    /* Bloc visuel (propre à React) */
    render() {

        let headerGroup = <ColumnGroup>
        <Row >
            <Column header="MOIS" rowSpan={2} />
            <Column header="VALIDE" rowSpan={2} />
            <Column header="REJETE" rowSpan={2} />
            <Column header="EN COURS" rowSpan={2} />
            <Column header="TOTAL" rowSpan={2} />

            <Column header="TARIF" colSpan={4} />
            <Column header="ORIGINE" colSpan={4} />
            <Column header="VALEUR" colSpan={4} />
        </Row>
        <Row>
            <Column header='VALIDE' style={{textAlign: 'center'}} />
            <Column header='REJETE'  />
            <Column header='EN COURS'  />
            <Column header="TOTAL" />

            <Column header='VALIDE'  />
            <Column header='REJETE'  />
            <Column header='EN COURS'  />
            <Column header="TOTAL" />

            <Column header='VALIDE'  />
            <Column header='REJETE'  />
            <Column header='EN COURS'  />
            <Column header="TOTAL" />

        </Row>
    </ColumnGroup>;

    let footerGroup = <ColumnGroup>
        <Row >
            <Column footer="TOTAUX" footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
            <Column footer={this.state.nbVal} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbRejet} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbEc} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbDem} style={{ color: '#000000', fontWeight: 'bold' }} />
            
            <Column footer={this.state.nbTarifVal} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbTarifRejet} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbTarifEc} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbTarif} style={{ color: '#000000', fontWeight: 'bold' }} />
            
            <Column footer={this.state.nbOrigineVal} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbOrigineRejet} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbOrigineEc} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbOrigine} style={{ color: '#000000', fontWeight: 'bold' }} />
           
            <Column footer={this.state.nbValeurVal} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbValeurRejet} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbValeurEc} style={{ color: '#000000', fontWeight: 'bold' }} />
            <Column footer={this.state.nbValeur} style={{ color: '#000000', fontWeight: 'bold' }} />

        </Row>
    </ColumnGroup>;



        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 500, paddingBlock: 2 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {this.getPanelRecherche()}

                        {/* Tableau contenant la liste  des factures */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} showGridlines headerColumnGroup={headerGroup} footerColumnGroup={footerGroup}
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" 
                            emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={false} >
                            <Column field="periode" header="MOIS" sortable />

                            <Column field="nbVal" header="VALIDE" sortable style={{ width: '5%' }} />
                            <Column field="nbRejet" header="REJETE" sortable style={{ width: '5%' }} />
                            <Column field="nbEc" header="EN COURS" sortable style={{ width: '5%' }} />
                            <Column field="nbDem" header="TOTAL" sortable style={{ width: '5%' }} />

                            <Column field="nbTarifVal" header="VALIDE" sortable style={{ width: '5%' }} />
                            <Column field="nbTarifRejet" header="REJETE" sortable style={{ width: '5%' }} />
                            <Column field="nbTarifEc" header="EN COURS" sortable style={{ width: '5%' }} />
                            <Column field="nbTarif" header="TOTAL" sortable style={{ width: '5%' }} />

                            <Column field="nbOrigineVal" header="VALIDE" sortable style={{ width: '5%' }} />
                            <Column field="nbOrigineRejet" header="REJETE" sortable style={{ width: '5%' }} />
                            <Column field="nbOrigineEc" header="EN COURS" sortable style={{ width: '5%' }} />
                            <Column field="nbOrigine" header="TOTAL" sortable style={{ width: '5%' }} />

                            <Column field="nbValeurVal" header="VALIDE" sortable style={{ width: '5%' }} />
                            <Column field="nbValeurRejet" header="REJETE" sortable style={{ width: '5%' }} />
                            <Column field="nbValeurEc" header="EN COURS" sortable style={{ width: '5%' }} />
                            <Column field="nbValeur" header="TOTAL" sortable style={{ width: '5%' }} />

                        </DataTable>


                    </div>
                </div>
            </div >
        );
    }
}
