import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Button } from 'primereact/button';

const HeroSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={false}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="home-slider"
      >
        <SwiperSlide> 
          <div 
            className="slider-item"
            style={{
              backgroundImage: `url(assets/layout/images/home-slider-doctav.jpg)`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape">
                     {/*  <img
                        src="/images/home-one/home-slider1.png"
                        alt="Shape"
                      /> */}
                    </div>

                    <h1>Bienvenue sur DACTOV</h1>
                    <p style={{backgroundColor: '#0c42c0',color: '#fff',fontSize: 16,padding: 10,fontWeight: 350,opacity: 0.9}}>
                      La plateforme de gestion des demandes anticipées en en matière de classement tarifaire, origine et valeur
                    </p>

                    <div className="common-btn" style={{marginBottom: 15}}>
                      <Button icon='pi pi-pencil' label="Inscription"  onClick={(e)=>{
                        window.location = "#/register";
                      }}  className="cmn-btn-left"/>
                     
                      <Button icon='pi pi-user' label="Connexion"  onClick={(e)=>{
                        window.location = "#/login";
                      }} className="cmn-btn-right"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>








        {/* <SwiperSlide>
          <div
            className="slider-item"
            style={{
              backgroundImage: `url(/images/home-one/home-slider-bg.jpg)`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-two">
                      <img
                        src="/images/home-one/home-slider2.png"
                        alt="Shape"
                      />
                    </div>
                    <h1>Caring Health is Important Than All</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida.
                    </p>

                    <div className="common-btn">
                      <a href="/appointment">Get Appointment</a>
                      <a href="/about" className="cmn-btn-right">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="slider-item"
            style={{
              backgroundImage: `url(/images/home-one/home-slider-bg.jpg)`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="slider-text">
                    <div className="slider-shape-three">
                      <img
                        src="/images/home-one/home-slider3.png"
                        alt="Shape"
                      />
                    </div>
                    <h1>We Offer Highly Treatments</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida.
                    </p>

                    <div className="common-btn">
                      <a href="/appointment">Get Appointment</a>
                      
                      <a href="/about" className="cmn-btn-right">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
    </>
  );
};

export default HeroSlider;
