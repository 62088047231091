import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import AppServices from '../service/AppServices';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Steps } from 'primereact/steps';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

export class Register extends Component {
    constructor() {
        super();
        this.state = {
            showindicator: false,
            submit: false,
            submitUser: false,
            submitEmail: false,
            submitLogin: false,
            succeed: false,

            liste_statut_juridique: [
                { value: 'SA', label: 'SA' },
                { value: 'SASU', label: 'SASU' },
                { value: 'GIE', label: 'GIE' },
                { value: 'SARL', label: 'SARL' },
                { value: 'SARLU', label: 'SARLU' },
            ],
            activeIndex: 0,
            formData: {
                id: 0,
                nom: "",
                login: "",
                pwd: "",
                confPwd: "",
                changerPwd: false,
                profil: "Guest",
                statut: "En attente",
                photo: "nophoto.png",
                telephone: "",
                idOp: { id: 0 }, //Clé étrangère de la table Opérateur  
            },

            formDataOp: {
                id: 0,
                nom: "",
                logo: "",
                anneeCreation: "",
                codeNif: '',
                rccm: "",
                statutJuridique: "",
                adresse: "",
                type: "",
                clee: "",
                code: "",
                suspendu: false,
                motifSuspension: "",
                telephone: "",
                email: "",
            },
            liste_type_op: [
                { value: 'Transitaire', label: 'Transitaire' },
                { value: 'Courtier', label: 'Courtier' },
                { value: 'Opérateur', label: 'Opérateur' },
            ],
            stepItems: [
                {
                    label: 'Information',
                    index: 0,
                    command: (event) => {

                    }
                },
                {
                    label: 'Compte',
                    index: 1,
                    command: (event) => {

                    }
                },
            ],
            msgLogin: '',
            msgEmail: '',
        };
        this.appservice = new AppServices();
    }


    checkForm() {
        if (this.state.activeIndex == 0) {
            this.setState({ submit: true });
            if (this.state.formDataOp.nom == "" || this.state.formDataOp.email == "" || this.state.formDataOp.telephone == "" || this.state.formDataOp.anneeCreation == "" || this.state.formDataOp.codeNif == "" || this.state.formDataOp.rccm == "" || this.state.formDataOp.statutJuridique == "" || this.state.formDataOp.type == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            } else if (this.state.formData.login !== "" && this.state.formData.login !== null && this.state.formData.login !== undefined && this.appservice.validateEmail(this.state.formData.login) == false) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: "L'adresse email renseigné est incorrecte" });
                return;
            }
        } else if (this.state.activeIndex == 1) {
            this.setState({ submitUser: true });
            if (this.state.formData.nom == "" || this.state.formData.login == "" || this.state.formData.telephone == "" || this.state.formData.pwd == "" || this.state.formData.confPwd == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            } else if (this.state.formDataOp.email !== "" && this.state.formDataOp.email !== null && this.state.formDataOp.email !== undefined && this.appservice.validateEmail(this.state.formDataOp.email) == false) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: "L'adresse email renseigné est incorrecte" });
                return;
            }
        } else {
            return;
        }
        this.setState({ activeIndex: this.state.activeIndex + 1 });
    }


    /* Fonction de validation du login */
    checkLogin() {
        this.setState({ submitLogin: true });
        this.appservice.check_login(this.state.formData.login).then(rep => {
            if (rep == true) {
                this.setState({ submitLogin: false, msgLogin: 'Ce email existe déja' });
            } else {
                this.setState({ submitLogin: true, msgLogin: '' });
            }
        })
    }

    /* Fonction de validation du login */
    checkEmail() {
        this.setState({ submitEmail: true });
        this.appservice.check_email_operateur(this.state.formDataOp.email).then(rep => {
            if (rep == true) {
                this.setState({ submitEmail: false, msgEmail: 'Ce email existe déja' });
            } else {
                this.setState({ submitEmail: true, msgEmail: '' });
            }
        })
    }


    register() {
        if (this.state.formData.nom == "" || this.state.formData.telephone == "" || this.state.formData.pwd == "" || this.state.formData.confPwd == "") {
            this.setState({ msg: 'Veuilllez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let form = this.state.formData;
            form.idOp = this.state.formDataOp;
            this.appservice.register(form).then(data => {
                this.setState({ showindicator: false });
                if (data.code == 200) {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Félicitation, votre compte a été créé avec succès, nous vous contacterons dans les meilleurs délais' });
                    this.setState({ succeed: true });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec de création de compte, veuillez ressayer ultérierement' });
                }
            });
        }
    }

    getItem() {
        if (this.state.activeIndex == 0) {
            return <div className="formgrid grid">
                <div className="field col-12 col-lg-6 md:col-6">
                    <InputText id="nom" placeholder="Nom de l'entreprise *" value={this.state.formDataOp.nom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.nom })}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'nom', '', this.state.formDataOp) })}
                        required autoFocus style={{width: '100%'}} />
                    {this.state.submit && !this.state.formDataOp.nom && <small className="p-invalid" style={{ color: 'red' }}>Le champs nom est obligatoire.</small>}
                </div>
                <div className="field col-12 col-lg-6 md:col-6">
                    <Dropdown id='type' placeholder='Type *' options={this.state.liste_type_op} value={this.state.formDataOp.type}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'type', '', this.state.formDataOp) })} autoWidth={false} style={{ width: '100%' }}
                        filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.type })} />
                    {this.state.submit && !this.state.formDataOp.type && <small className="p-invalid" style={{ color: 'red' }}>Le champs type est obligatoire</small>}
                </div>

                <div className="field col-12 col-lg-6 md:col-6">
                    <InputMask mask="999999999" placeholder='Téléphone *' id="name" value={this.state.formDataOp.telephone} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.telephone })}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'telephone', '', this.state.formDataOp) })}
                        required style={{width: '100%'}} />
                    {this.state.submit && !this.state.formDataOp.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le champs téléphone est obligatoire.</small>}
                </div>

                <div className="field col-12 col-lg-6 md:col-6">
                    <InputText id="email" placeholder='Email *' value={this.state.formDataOp.email} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.email })}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'email', '', this.state.formDataOp) })}
                        required onBlur={(e) => {
                            this.checkEmail();
                        }} style={{width: '100%'}} />
                    {this.state.submitEmail == false && this.state.modif_data == false && <small className="p-invalid" style={{ color: 'red' }}>{this.state.msgEmail}.</small>}
                    {this.state.submit && !this.state.formDataOp.email && <small className="p-invalid" style={{ color: 'red' }}>Le champs email est obligatoire</small>}
                </div>

                <div className="field col-12 col-lg-6 md:col-6">
                    <InputMask mask="9999" placeholder='Année création *' id="anneeCreation" value={this.state.formDataOp.anneeCreation} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.anneeCreation })}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'anneeCreation', '', this.state.formDataOp) })}
                        required style={{width: '100%'}} />
                    {this.state.submit && !this.state.formDataOp.anneeCreation && <small className="p-invalid" style={{ color: 'red' }}>Le champs année de creation est obligatoire.</small>}
                </div>

                <div className="field col-12 col-lg-6 md:col-6">
                    <InputText id="rccm" placeholder='Code NIF *' value={this.state.formDataOp.codeNif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.codeNif })}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'codeNif', '', this.state.formDataOp) })}
                        required style={{width: '100%'}} />
                    {this.state.submit && !this.state.formDataOp.codeNif && <small className="p-invalid" style={{ color: 'red' }}>Le champs NIF est obligatoire.</small>}
                </div>


                <div className="field col-12 col-lg-6 md:col-6">
                    <InputText id="rccm" placeholder='N° RCCM *' value={this.state.formDataOp.rccm} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.rccm })}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'rccm', '', this.state.formDataOp) })}
                        required style={{width: '100%'}} />
                    {this.state.submit && !this.state.formDataOp.rccm && <small className="p-invalid" style={{ color: 'red' }}>Le champs rccm est obligatoire.</small>}
                </div>



                <div className="field col-12 col-lg-6 md:col-6">
                    <Dropdown id='statutJuridique' placeholder='Statut Juridique *' options={this.state.liste_statut_juridique} value={this.state.formDataOp.statutJuridique}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'statutJuridique', '', this.state.formDataOp) })} autoWidth={false} style={{ width: '100%' }}
                        filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.statutJuridique })} />
                    {this.state.submit && !this.state.formDataOp.statutJuridique && <small className="p-invalid" style={{ color: 'red' }}>Le champs statut juridique est obligatoire</small>}
                </div>

                <div className="field col-12 col-lg-12 md:col-12">
                    <InputText autoWidth={false} placeholder='Adresse *' value={this.state.formDataOp.adresse} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.adresse}
                        onChange={(e) => this.setState({ formDataOp: this.appservice.onInputChange(e, 'adresse', '', this.state.formDataOp) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOp.adresse })} style={{width: '100%'}} />
                    {this.state.submit && !this.state.formDataOp.adresse && <small className="p-invalid" style={{ color: 'red' }}>Le champs adresse est obligatoire</small>}
                </div>

            </div>;
        } else if (this.state.activeIndex == 1) {
            return <div className="p-fluid formgrid grid">
                    <div className="field col-12 col-lg-6 md:col-6">
                        <label htmlFor="email1">Nom *</label>
                        <InputText id="name" value={this.state.formData.nom} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.nom })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nom', '', this.state.formData) })}
                            required autoFocus />
                        {this.state.submitUser && !this.state.formData.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 col-lg-6 md:col-6">
                        <label htmlFor="email1">Téléphone *</label>
                        <InputMask id="tel" mask="999999999" value={this.state.formData.telephone} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.telephone })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telephone', '', this.state.formData) })}
                            required keyfilter='pint' />
                        {this.state.submitUser && !this.state.formData.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-12">
                        <label htmlFor="name1">Email * </label>
                        <InputText id="name1" value={this.state.formData.login} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.login })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'login', '', this.state.formData) })}
                            required onBlur={(e) => {
                                this.checkLogin();
                            }} />
                        {this.state.submitLogin == false && this.state.modif_data == false && <small className="p-invalid" style={{ color: 'red' }}>{this.state.msgLogin}.</small>}
                        {this.state.submitUser && !this.state.formData.login && <small className="p-invalid" style={{ color: 'red' }}>Le login est obligatoire</small>}
                    </div>

                    <div className="field col-12 col-lg-6 md:col-6">
                        <label htmlFor="emailEtabl1">Mot de passe *</label>
                        <Password value={this.state.formData.pwd} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.pwd })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'pwd', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.pwd && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                    </div>

                    <div className="field col-12 col-lg-6 md:col-6">
                        <label htmlFor="emailEtabl1">Confirmer mot de passe *</label>
                        <Password value={this.state.formData.confPwd} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formData.confPwd })}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'confPwd', '', this.state.formData) })}
                            required />
                        {this.state.submitUser && !this.state.formData.confPwd && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                    </div>
                </div>
        }

    }

    render() {


        return (
            <div className="register-body" style={{ marginTop: -30 }}>
                <Toast ref={(el) => this.toast = el} />
                <div className="register-wrapper">
                    <div className="register-panel">

                        <div style={{ textAlign: 'center' }}>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>

                        <div className="col-12">
                            <div style={{ textAlign: 'center' }}>
                                <img onClick={(e) => window.location = "/"} src="assets/layout/images/logo-dark.png" className="logo" alt="diamond-layout" style={{ width: '190px', cursor: 'pointer' }} />
                            </div>
                            <div className="grid">
                                <div style={{ padding: 10 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h3>Inscription</h3>
                                    </div>
                                    {this.state.succeed == false ?
                                        <>
                                            <Steps model={this.state.stepItems} activeIndex={this.state.activeIndex} onSelect={(e) => this.setState({ activeIndex: e.index })} readOnly={true} style={{ marginTop: 20 }} />
                                            <br />
                                            <div style={{ paddingBlock: 2 }} >
                                                <div className="col-12" style={{ minHeight: 280 }}>
                                                    {this.getItem()}
                                                </div>
                                                <div className="col-12">
                                                    <div>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                            <Button label="RETOUR" disabled={this.state.activeIndex <= 0} icon="pi pi-angle-left" onClick={() => {
                                                                this.setState({ activeIndex: this.state.activeIndex - 1 });
                                                            }} style={{ width: 150 }} />
                                                            {this.state.activeIndex == 1 ?
                                                                <Button label="ENREGISTRER" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.register()} style={{ width: 150 }} className="p-button-success" />
                                                                :
                                                                <Button label="SUIVANT" disabled={this.state.activeIndex >= 1} icon="pi pi-angle-right" onClick={() => {
                                                                    this.checkForm();
                                                                }} iconPos='right' style={{ width: 150 }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <center style={{ marginTop: 80, marginBottom: 50 }}>
                                            <br />
                                            <img src="assets/layout/images/success.png" height={150} />
                                            <br />
                                            <label style={{ fontSize: 18 }}>Félicitation, votre compte a été créé avec succès, nous vous contacterons dans les meilleurs délais</label>
                                            <br /><br />
                                            <Button icon='pi pi-angle-left' style={{ fontSize: 18, width: 200, borderRadius: 20 }} label="Retour" onClick={(e) => {
                                                window.location = "/";
                                            }} />
                                        </center>
                                    }
                                </div>

                            </div>

                        </div>


                    </div>
                    <div className="register-image" >
                        <div className="register-image-content" >

                        </div>



                        <div className="image-footer">

                            <p>Suivez nous sur :</p>
                            <div className="icons">
                                <i className="pi pi-facebook" style={{ color: '#1f317d' }}></i>
                                <i className="pi pi-twitter" style={{ color: '#1f317d' }}></i>
                                <i className="pi pi-youtube" style={{ color: '#1f317d' }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}