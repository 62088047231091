import React, { useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';

import Dashboard from './components/Dashboard';
import FormPays from './components/FormPays';
import FormCodifTypeDoc from './components/FormCodifTypeDoc';
import FormCodifObjetDem from './components/FormCodifObjetDem';
import FormOperateur from './components/FormOperateur';
import FormUtlisateur from './components/FormUtlisateur';
import FormDemande from './components/FormDemande';

import FormCodifClasseTarif from './components/FormCodifClasseTarif';
import FormCodifDevise from './components/FormCodifDevise';
import FormCodifIncoterm from './components/FormCodifIncoterm';
import FormCodifMethodeEval from './components/FormCodifMethodeEval';
import FormCodifReglesOrigine from './components/FormCodifReglesOrigine';
import FormCodifReglesTarif from './components/FormCodifReglesTarif';
import FormParam from './components/FormParam';
import AppServices from './service/AppServices';

import Documentation from './components/Documentation';
import FormChpwd from './components/FormChpwd';
import FormNotificaiton from './components/FormNotificaiton';
import EditionJournalDemande from './components/EditionJournalDemande';
import EditionStatDemandeStatut from './components/EditionStatDemandeStatut';
import EditionStatDemande from './components/EditionStatDemande';
import EditionStatDemandeOperateur from './components/EditionStatDemandeOperateur';


/*
import FormPays from './components/FormPays';
import FormPays from './components/FormPays';
import FormPays from './components/FormPays';
import FormPays from './components/FormPays';



 */

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import { ProgressBar } from 'primereact/progressbar'



// Global Style
import '../src/assets/disin/css/style.css';
import "../src/assets/disin/css/icofont.min.css";
import "../src/assets/disin/css/bootstrap.min.css";
import "../src/assets/disin/css/animate.css";
import "../src/assets/disin/css/meanmenu.css";


//import "react-tabs/style/react-tabs.css";
//import "../node_modules/react-modal-video/css/modal-video.min.css";
//import "react-accessible-accordion/dist/fancy-example.css";
//import "swiper/css";
//import "swiper/css/bundle";

// Global Style
import "../src/assets/disin/css/responsive.css";
// Global RTL Style
import "../src/assets/disin/css/rtl.css";

//import "swiper/css";
//import "swiper/css/bundle";

import 'swiper/swiper.min.css'
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/thumbs/thumbs.min.css';


//import 'swiper/modules/pagination/pagination.min.css'


// Global Style
//import "assets/disin/css/style.css"; 



import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {

    const [layoutMode, setLayoutMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('blue');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [scheme, setScheme] = useState('light');
    const copyTooltipRef = useRef();
    const location = useLocation();
 
    let menuClick;
    let topbarItemClick;


    const appservice = new AppServices();
    var menu = [];
    var profil = '';
    var nom = '';
    var userconnected = appservice.get_userconnected();

    if (userconnected !== null && userconnected !== undefined) {
        profil = userconnected.profil;
        nom = userconnected.nom;
    }

    if (profil == 'Administrateur') {

        menu = [
            /*  {
                 label: 'Favoris', icon: 'pi pi-fw pi-home',
                 items: [
                     { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/' },
                     { label: 'Gestion opérateurs', icon: 'pi pi-fw pi-home', to: '/operateur' }
                 ]
             }, */
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion demandes', icon: 'pi pi-fw pi-pencil', to: '/demandes' },
            {
                label: 'Paramétrage', icon: 'pi pi-fw pi-cog',
                items: [
                    { label: 'Opérateurs', icon: 'pi pi-fw pi-users', to: '/operateurs' },
                    { label: 'Objets demandes', icon: 'pi pi-fw pi-list', to: '/objets' },
                    { label: "Type documents", icon: "pi pi-fw pi-bookmark", to: "/typedoc" },
                    { label: "Paramètres généraux", icon: "pi pi-sliders-h", to: "/params" },
                    { label: 'Pays', icon: 'pi pi-fw pi-flag-fill', to: '/pays' },
                    { label: 'Compte utilisateur', icon: 'pi pi-fw pi-user', to: '/users' },
                    { label: 'Classements tarifaires', icon: 'pi pi-fw pi-sitemap', to: '/classes' },
                    { label: 'Dévises', icon: 'pi pi-fw pi-dollar', to: '/devises' },
                    { label: 'Incotermes', icon: 'pi pi-fw pi-bookmark', to: '/incoterms' },
                    { label: 'Méthodes évaluation', icon: 'pi pi-fw pi-check-square', to: '/methodes' },
                    { label: 'Règles tarifaires', icon: 'pi pi-fw pi-filter', to: '/reglestarifs' },
                    { label: 'Règles origines', icon: 'pi pi-fw pi-angle-double-down', to: '/reglesorigines' },
                ]
            },
            {
                label: "Reporting", icon: "pi pi-chart-bar",
                items: [
                    { label: "Journal des demandes", icon: "pi pi-fw pi-table", to: "/jrndmd" },
                    { label: "Stat des demandes", icon: "pi pi-fw pi-chart-bar", to: "/statdemande" },
                    { label: "Demandes / statut", icon: "pi pi-fw pi-chart-pie", to: "/stdmdstatut" },
                    { label: "Demandes / opérateur", icon: "pi pi-fw pi-chart-bar", to: "/stdmdop" },
                ]
            },
        ];
    } else if (profil == 'Back office' || profil == 'Superviseur' || profil == 'Directeur') {
        menu = [
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion demandes', icon: 'pi pi-fw pi-pencil', to: '/demandes' },
            {
                label: "Reporting", icon: "pi pi-chart-bar",
                items: [
                    { label: "Journal des demandes", icon: "pi pi-fw pi-table", to: "/jrndmd" },
                    { label: "Stat des demandes", icon: "pi pi-fw pi-chart-bar", to: "/statdemande" },
                    { label: "Demandes / statut", icon: "pi pi-fw pi-chart-pie", to: "/stdmdstatut" },
                    { label: "Demandes / opérateur", icon: "pi pi-fw pi-chart-bar", to: "/stdmdop" },
                ]
            },
        ];
    }else{
        menu = [
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Mes demandes', icon: 'pi pi-fw pi-pencil', to: '/demandes' },
            { label: 'Messages', icon: 'pi pi-fw pi-envelope', to: '/notifications' },
        ];
    }



    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === 'overlay' && !isMobile()) {
            setOverlayMenuActive(prevState => !prevState);
        } else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevState => !prevState);
            else
                setStaticMenuMobileActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState);
    }

    const onDocumentClick = (event) => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu();
        }

        topbarItemClick = false;
        menuClick = false;
    }

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuDesktopInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
        else {
            return true;
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false)
    }

    const isMobile = () => {
        return window.innerWidth < 1025;
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    const isSlim = () => {
        return layoutMode === 'slim';
    }

    const changeMenuMode = (event) => {
        setLayoutMode(event.menuMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);
    }

    const onSchemeChange = (color) => {
        setScheme(color);
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        changeTheme(themeName + '-' + color);
        changeLogo(color);
    }

    const changeTheme = (theme) => {
        setThemeColor(theme.split('-')[0]);
        changeStyleSheetUrl('layout-css', theme, 'layout');
        changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    const onThemeChange = (theme) => {
        setThemeColor(theme)
        changeTheme(theme + '-' + scheme);
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const changeLogo = (scheme) => {
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const logoUrl = `assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.png`;

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-static-inactive': staticMenuDesktopInactive && layoutMode !== 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-overlay-active': overlayMenuActive,
        'p-input-filled': inputStyle === 'filled'
    });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() })

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />


            <AppTopbar
                topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-content">
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={layoutMode} active={menuActive} />
                    <div className="layout-menu-footer">
                        <div className="layout-menu-footer-title">TASKS</div>

                        <div className="layout-menu-footer-content">
                            <ProgressBar value={50} showValue={false}></ProgressBar>
                            Today
                            <ProgressBar value={80} showValue={false}></ProgressBar>
                            Overall
                        </div>
                    </div>
                </div>
            </div>

            <div className="layout-content">
                <AppBreadcrumb />

                <div className="layout-content-container">
                    <Route path="/dashboard" exact render={() => <Dashboard colorMode={scheme} location={location} />} />
                    <Route path="/pays" component={FormPays} />
                    <Route path="/typedoc" component={FormCodifTypeDoc} />
                    <Route path="/objets" component={FormCodifObjetDem} />
                    <Route path="/operateurs" component={FormOperateur} />
                    <Route path="/users" component={FormUtlisateur} />
                    <Route path="/documentation" component={Documentation} />
                    <Route path="/demandes" component={FormDemande} />

                    <Route path="/classes" component={FormCodifClasseTarif} />
                    <Route path="/devises" component={FormCodifDevise} />
                    <Route path="/incoterms" component={FormCodifIncoterm} />
                    <Route path="/methodes" component={FormCodifMethodeEval} />
                    <Route path="/reglesorigines" component={FormCodifReglesOrigine} />
                    <Route path="/reglestarifs" component={FormCodifReglesTarif} />
                    <Route path="/params" component={FormParam} />
                    <Route path="/chpwd" component={FormChpwd} /> 
                    <Route path="/notifications" component={FormNotificaiton} /> 


                    <Route path="/jrndmd" component={EditionJournalDemande} /> 
                    <Route path="/stdmdstatut" component={EditionStatDemandeStatut} /> 
                    <Route path="/statdemande" component={EditionStatDemande} /> 
                    <Route path="/stdmdop" component={EditionStatDemandeOperateur} /> 


                    {/* 
                    
                    
                    
                    
                     <Route path="/documentation" component={Documentation} /> 
                    <Route path="/documentation" component={Documentation} /> 
                    <Route path="/documentation" component={Documentation} /> 
                    <Route path="/documentation" component={Documentation} /> 
                    
                    
                    */}

                </div>

                <AppFooter />

                {staticMenuMobileActive && <div className="layout-mask"></div>}
            </div>


            {/*  <AppConfig themeColor={themeColor} onThemeChange={onThemeChange}
                inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} changeMenuMode={changeMenuMode}
                ripple={ripple} onRippleChange={onRippleChange}
                scheme={scheme} onSchemeChange={onSchemeChange} /> */}
        </div>
    );

}

export default App;
