import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import AppServices from '../service/AppServices';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';

export default class FormChpwd extends Component {
    constructor() {
        super();
        this.state = {
            newPWD: '',
            anPWD: '',
            confnewPWD: '',
            showindicator: false,
            submit: false,
        };
        this.appservice = new AppServices();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mot de passe changé avec succès' });
            this.annule_user();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }


    enreg_user() {
        this.setState({submit: true });
        if (this.state.anPWD == '' || this.state.anPWD == undefined || this.state.newPWD == '' || this.state.newPWD == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.anPWD != this.appservice.get_userconnected().password) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "L'ancien mot de passe est incorrect" });
        } else if (this.state.newPWD != this.state.confnewPWD) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez bien confirmer le nouveau mot de passe" });
        } else if (this.state.anPWD == this.state.newPWD) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "L'ancien et le nouveau mot de passe ne peuvent etre identiques" });
        } else {
            let form = {
                id: this.appservice.get_userconnected().id,
                nom: this.appservice.get_userconnected().nom,
                pwd: this.state.newPWD,
            };
            this.setState({ showindicator: true });
            this.appservice.update_password(form).then(data => {
                this.resultat(data.code, data.contenu);
                this.setState({ showindicator: false });
            });
        }
    }

    annule_user() {
        this.setState({ anPWD: '' });
        this.setState({ newPWD: '' });
        this.setState({ confnewPWD: '' });
    }



    render() {

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />


                <div className="card card-w-title" style={{ height: 800 }}>

                    <div className="p-fluid grid formgrid" >
                        <div className="field col-3">
                            <label >Ancien mot de passe *</label>
                        </div>
                        <div className="field col-9">
                            <Password value={this.state.anPWD} type="password" onChange={(e) => this.setState({ anPWD: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.anPWD })}/>
                            {this.state.submit && !this.state.anPWD && <small className="p-invalid" style={{ color: 'red' }}>Le champs ancien mot de passe est obligatoire.</small>}

                        </div>

                        <div className="field col-3">
                            <label >Nouveau mot de passe *</label>
                        </div>
                        <div className="field col-9">
                            <Password value={this.state.newPWD} type="password" onChange={(e) => this.setState({ newPWD: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.newPWD })}/>
                            {this.state.submit && !this.state.newPWD && <small className="p-invalid" style={{ color: 'red' }}>Le champs nouveau mot de passe est obligatoire.</small>}

                        </div>

                        <div className="field col-3">
                            <label >Confirmer nouveau mot de passe *</label>
                        </div>
                        <div className="field col-9">
                            <Password value={this.state.confnewPWD} type="password" onChange={(e) => this.setState({ confnewPWD: e.target.value })} className={classNames({ 'p-invalid': this.state.submit && !this.state.confnewPWD })} />
                            {this.state.submit && !this.state.confnewPWD && <small className="p-invalid" style={{ color: 'red' }}>La confirmation du mot de passe est obligatoire.</small>}

                        </div>

                    </div>

                    <center>
                        <div className="grid" style={{ width: 300 }}>
                            <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_user()} /></div>
                            <div className="col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_user()} /></div>
                        </div>
                        <br />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>



                </div>
            </div>
        );
    }
}