import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import { InputNumber } from 'primereact/inputnumber';


/* Page de gestion des Demandes */
export default class FormDemande extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            selectedDoc: null,
            selectedMatiere: null,
            liste_data: [],
            liste_param: [],
            liste_incoterm: [],
            liste_devise: [],
            liste_methode_eval: [],
            liste_regle_origine: [],
            liste_regle_tarif: [],
            liste_classe_tarif: [],

            liste_operateur: [],
            liste_type_doc: [],
            liste_matiere: [],
            liste_pays: [],
            liste_objet: [],
            liste_utilisateur: [],
            rowIndex: 0,
            liste_document: [],
            liste_origine: [],
            liste_tarification: [],
            helpContent: '',
            helpField: '',

            selectedTrt: null,
            visible_dlg_historique_trt: false,
            liste_trt: [],


            formDataTarif: {
                id: 0,
                appelationCommerciale: '',
                existeAutreDemande: '',
                refAutreDemande: '',
                existeAutreDecision: '',
                refAutreDecision: '',
                existeProcessusVerif: '',
                refProcessusVerif: '',
                idDem: { id: 0 }, //Clé étrangère de la table Demande 

                idCodifClasseEnvisagee: { id: 0 }, //Clé étrangère de la table Demande 
                idCodifRegleApplicable: { id: 0 }, //Clé étrangère de la table codificaiton des règles
                idCodifRegleUtilisee: { id: 0 }, //Clé étrangère de la table codificaiton des règles 
                idCodifClasseFinale: { id: 0 }, //Clé étrangère de la table codificaiton des classement 
                autresReferences: '',
                refServiceDecision: 'DGD/DLRRI/STOV',

            },
            formDataOrigine: {
                id: 0,
                adrImportExport: '',
                nomImportExport: "",

                appelationCommerciale: "",
                reglesUtilises: "",
                existeAutreDemande: "",
                refAutreDemande: "",
                existeAutreDecision: "",
                refAutreDecision: "",
                existeProcessusVerif: "",
                refProcessusVerif: "",
                cadreJuridique: "",
                demande: { id: 0 }, //Clé étrangère de la table demande
                idPaysImport: { id: 0 }, //Clé étrangère de la table Pays
                idPaysExport: { id: 0 }, // Clé étrangère de la table Pays
                idPaysOrigine: { id: 0 }, // Clé étrangère de la table Pays

                idPaysOrigineFinale: { id: 0 }, // Clé étrangère de la table Pays
                idCodifClasseEnvisagee: { id: 0 }, //Clé étrangère de la table Demande 
                idCodifRegleApplicable: { id: 0 }, //Clé étrangère de la table codificaiton des règles
                idCodifRegleUtilisee: { id: 0 }, //Clé étrangère de la table codificaiton des règles 
                idCodifClasseFinale: { id: 0 }, //Clé étrangère de la table codificaiton des classement 
                autresReferences: '',
                refServiceDecision: 'DGD/DLRRI/STOV',

            },
            formDataValeur: {
                id: 0,
                lienAcheteurVendeur: '',
                descTransaction: "",
                avisDemandeur: "",
                renseignementCompl: '',
                existeAutreDemande: '',
                refAutreDemande: '',
                existeAutreDecision: '',
                refAutreDecision: '',
                existeProcessusVerif: '',
                refProcessusVerif: '',
                idCodifObjet: { id: 0 },
                idDem: { id: 0 },

                idCodifIncoterm: { id: 0 }, //Clé étrangère de la table codificaiton des incoterms
                idCodifDevise: { id: 0 }, //Clé étrangère de la table codificaiton des dévises
                idCodifMethodeEval: { id: 0 }, //Clé étrangère de la table codificaiton des méthodes d'évaluation
                idCodifRegleUtilisee: { id: 0 }, //Clé étrangère de la table codificaiton des règles 
                idCodifClasseFinale: { id: 0 }, //Clé étrangère de la table codificaiton des classement 
                montantDevise: 0,
                taux: 0,
                montantLocal: 0,
                montantFret: 0,
                montantAssurance: 0,
                montantAutresFrais: 0,
                valeurCaf: 0,
                valeurFinale: 0,

                autresReferences: '',
                refServiceDecision: 'DGD/DLRRI/STOV',
            },
            formData: {
                id: 0,
                statut: 'En attente',
                dateDebut: null,
                dateDecision: null,
                delai: null,
                type: "",
                decisionFinale: "",
                motifDecision: "",
                descMarchandise: "",
                date: null,
                idOp: { id: 0 }, //Clé étrangère de la table Opérateur
                idUser: { id: 0 }, //Clé étrangère de la table Utilisateur
                dValeur: { id: 0 }, //Clé étrangère de la table DValeur
                dOrigine: { id: 0 }, //Clé étrangère de la table DOrigine
                dTarif: { id: 0 }, //Clé étrangère de la table Tarification
                feedBackWaiting: false,
            },
            formDataTrt: {
                id: 0,
                date: null,
                libelle: "",
                commentaire: "",
                idDem: { id: '' }, //Clé étrangère de la table Demande 
                idUser: { id: '' }  //Clé étrangère de la table Utilisateur
            },
            liste_operateur: [], //Clé étrangère de la table opérateur
            liste_utilisateur: [], //Clé étrangère de la table Utilisateur
            liste_valeur: [], //Clé étrangère de la table DValeur
            liste_origine: [], //Clé étrangère de la table DOrigine
            liste_tarification: [], //Clé étrangère de la table Tarification
            liste_action: [
                { value: "Demande d'information", label: "Demande d'information" },
                { value: "Confirmation", label: "Confirmation" },
                { value: "Recevabilité", label: "Recevabilité" },
                { value: "Non recevabilité", label: "Non recevabilité" },
                { value: "Rejet", label: "Rejet" },
                { value: "Validation", label: "Validation" },

            ],
            showindicator: false,
            modif_data: false,
            submit: false,
            submitTrt: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
            visible_dlg_help: false,
            visible_dlg_choix_type: false,
            visible_dlg_trt: false,
            visible_dlg_decision: false,
            visible_dlg_upload: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.toogleDlgChoixType = this.toogleDlgChoixType.bind(this);
        this.conf_delete_new_ligne = this.conf_delete_new_ligne.bind(this);
        this.print_decision = this.print_decision.bind(this);

        this.onCellEditComplete = this.onCellEditComplete.bind(this);


        this.appservice = new AppServices();
    }


    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_operateur();
        this.get_liste_type_doc();
        this.get_liste_classe_tarif();
        this.get_liste_param();

        this.get_liste_regle_tarif();
        this.get_liste_origine();
        this.get_liste_regle_origine();
        this.get_liste_objet();
        this.get_liste_incoterm();
        this.get_liste_devise();
        this.get_liste_methode_eval();

        let url = window.location.href;
        const query = new URLSearchParams(url);
        if (query !== null && query !== undefined) {
            if (query.get("type") == 'Tarif' || query.get("type") == 'Origine' || query.get("type") == 'Valeur') {
                let form = this.state.formData;
                form.type = query.get("type");
                this.setState({ formData: form, visible_dlg_choix_type: false }, () => {
                    this.toogleDlg();
                });
            }
        }
    }

    /* Fonction permettant d'imprimer la décision */
    print_decision(data) {
        this.appservice.generer_decision(data);
    }


    /* Fonction permettant de retourner la liste des paramètres */
    get_liste_param() {
        this.setState({ showindicator: true, liste_param: [] });
        this.appservice.get_liste_param().then(data => {
            this.setState({ showindicator: false, liste_param: data });
        });
    }


    /* Fonction permettant de retourner la liste des types de document */
    get_liste_type_doc() {
        this.setState({ showindicator: true, liste_type_doc: [] });
        this.appservice.get_liste_codification('TYPE_DOCUMENT').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle, code: x.codeCodif }
                    });
                    this.setState({ liste_type_doc: liste });
                }
            }
        });
    }

    /* Fonction permettant de retourner la liste des positions tarifaires */
    get_liste_classe_tarif() {
        this.setState({ showindicator: true, liste_classe_tarif: [] });
        this.appservice.get_liste_codification('CLASSE_TARIF').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle, code: x.codeCodif }
                    });
                    this.setState({ liste_classe_tarif: liste });
                }
            }
        });
    }

    /* Fonction permettant de retourner la liste des incoterms */
    get_liste_incoterm() {
        this.setState({ showindicator: true, liste_incoterm: [] });
        this.appservice.get_liste_codification('INCOTERM').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.codeCodif, code: x.codeCodif }
                    });
                    this.setState({ liste_incoterm: liste });
                }
            }
        });
    }

    /* Fonction permettant de retourner la liste des dévises */
    get_liste_devise() {
        this.setState({ showindicator: true, liste_devise: [] });
        this.appservice.get_liste_codification('DEVISE').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.codeCodif, code: x.codeCodif }
                    });
                    this.setState({ liste_devise: liste });
                }
            }
        });
    }

    /* Fonction permettant de retourner la liste des méthodes d'évaluation */
    get_liste_methode_eval() {
        this.setState({ showindicator: true, liste_methode_eval: [] });
        this.appservice.get_liste_codification('METHODE_EVAL').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle, code: x.codeCodif }
                    });
                    this.setState({ liste_methode_eval: liste });
                }
            }
        });
    }

    /* Fonction permettant de retourner la liste des règles de classement tarifaire */
    get_liste_regle_tarif() {
        this.setState({ showindicator: true, liste_regle_tarif: [] });
        this.appservice.get_liste_codification('REGLE_TARIF').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle, code: x.codeCodif }
                    });
                    this.setState({ liste_regle_tarif: liste });
                }
            }
        });
    }

    /* Fonction permettant de retourner la liste des règles d'origine */
    get_liste_regle_origine() {
        this.setState({ showindicator: true, liste_regle_origine: [] });
        this.appservice.get_liste_codification('REGLE_ORIGINE').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle, code: x.codeCodif }
                    });
                    this.setState({ liste_regle_origine: liste });
                }
            }
        });
    }


    /* Fonction permettant de retourner la liste des objets de demande de valeur */
    get_liste_objet() {
        this.setState({ showindicator: true, liste_objet: [] });
        this.appservice.get_liste_codification('OBJET_DEMANDE').then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle, value: x.id }
                    });
                    this.setState({ liste_objet: liste });
                }
            }
        });
    }


    /* Fonction de suppression du document sélectionné */
    conf_delete_new_ligne(data) {
        if (data.newDoc == false) {
            this.appservice.delete_document(data).then(rep => {
                if (rep.code == 200) {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                    for (var i = this.state.liste_document.length; i--;) {
                        if (data.id == this.state.liste_document[i].id) {
                            this.state.liste_document.splice(i, 1);
                            break;
                        }
                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                }
            });
        } else {
            for (var i = this.state.liste_document.length; i--;) {
                if (data.id == this.state.liste_document[i].id) {
                    this.state.liste_document.splice(i, 1);
                    break;
                }
            }
        }

        this.forceUpdate();
    }

    /* Fonction de suppression de la matière sélectionnée */
    conf_delete_new_matiere(data) {
        if (data.newMatiere == false) {
            this.appservice.delete_matiere(data).then(rep => {
                if (rep.code == 200) {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
                    for (var i = this.state.liste_matiere.length; i--;) {
                        if (data.id == this.state.liste_matiere[i].id) {
                            this.state.liste_matiere.splice(i, 1);
                            break;
                        }
                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
                }
            });
        } else {
            for (var i = this.state.liste_matiere.length; i--;) {
                if (data.id == this.state.liste_matiere[i].id) {
                    this.state.liste_matiere.splice(i, 1);
                    break;
                }
            }
        }

        this.forceUpdate();
    }

    /* Fonction de suppression du l'utilisateur sélectionné */
    toogle_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la demande' });
        } else {
            this.appservice.save_demande(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }


    onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event, newRowData } = e;
        let val = newValue + '';
        if (val.length > 0) {
            if (field == 'idTypeDoc') {
                rowData[field] = e.newRowData.idTypeDoc;
            } else {
                rowData[field] = newValue;
            }
        } else {
            event.preventDefault();
        }
    }

    docEditor(options) {
        let updateds = this.state.liste_document;
        return <Dropdown autoWidth={false} value={options.value} filter={true} filterBy='label' filterMatchMode='contains'
            options={this.state.liste_type_doc} onChange={(e) => {
                updateds[options.rowIndex][options.field] = e.value;
                updateds[options.rowIndex].idCodifType.id = e.value;
                this.setState({ liste_document: updateds });
            }} appendTo={document.body} style={{ width: '170px' }} />
    }

    descEditor(options) {
        return <InputText style={{ width: '100%' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }


    matiereEditor(options) {
        return <InputText style={{ width: '100%' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    shEditor(options) {
        return <InputText style={{ width: '100%' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    autreEditor(options) {
        return <InputText style={{ width: '100%' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    origineEditor(options) {
        return <InputText style={{ width: '100%' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    valeurEditor(options) {
        return <InputText style={{ width: '100%' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    /* Récupération de la liste globale des opérateurs */
    get_liste_operateur() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_operateur().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nom, id: x.id }
                    });
                    this.setState({ liste_operateur: liste }, () => {
                        let formData = this.state.formData;
                        formData.idOp = this.appservice.get_userconnected().idOp;
                        this.setState({ formData: formData });
                    });
                }
            }
        });
    }

    /* Récupération de la liste globale des Utilisateurs */
    get_liste_utilisateur() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_utilisateur().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nom, id: x.id }
                    });
                    this.setState({ liste_utilisateur: liste }, () => {
                        let formData = this.state.formData;
                        formData.idUser = this.appservice.get_userconnected().idUser;
                        this.setState({ formData: formData });
                    });
                }
            }
        });
    }

    /* Récupération de la liste globale des DValeurs */
    get_liste_valeur() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_valeur().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.descTransaction, id: x.id }
                    });
                    this.setState({ liste_valeur: liste }, () => {
                        let formData = this.state.formData;
                        formData.dValeur = this.appservice.get_userconnected().dValeur;
                        this.setState({ formData: formData });
                    });
                }
            }
        });
    }

    /* Récupération de la liste globale des pays */
    get_liste_origine() {
        this.setState({ showindicator: true, liste_pays: [] });
        this.appservice.get_liste_pays().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nomFr, id: x.id }
                    });
                    this.setState({ liste_pays: liste });
                }
            }
        });
    }

    /* Récupération de la liste globale des DTarification */
    get_liste_tarification() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_tarification().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.appelationCommerciale, id: x.id }
                    });
                    this.setState({ liste_tarification: liste }, () => {
                        let formData = this.state.formData;
                        formData.dTarif = this.appservice.get_userconnected().dTarif;
                        this.setState({ formData: formData });
                    });
                }
            }
        });
    }

    /* Récupération de la liste globale des traitements */
    get_liste_trt() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_traitement(this.state.selectedData.id).then(data => {
            this.setState({ showindicator: false, liste_trt: data });
        });
    }

    /* Récupération de la liste globale des demandes */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_demande().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                statut: 'En attente',
                dateDebut: null,
                dateDecision: null,
                delai: null,
                type: this.state.formData.type,
                decisionFinale: "",
                motifDecision: "",
                descMarchandise: "",
                reference: this.appservice.get_ref(),
                date: null,
                idOp: { id: 0 }, //Clé étrangère de la table Opérateur
                idUser: { id: 0 }, //Clé étrangère de la table Utilisateur
                dValeur: { id: 0 }, //Clé étrangère de la table DValeur
                dOrigine: { id: 0 }, //Clé étrangère de la table DOrigine
                dTarif: { id: 0 }, //Clé étrangère de la table DTarification  
                feedBackWaiting: false,
            },
            formDataTarif: {
                id: 0,
                appelationCommerciale: '',
                refAutreDemande: '',
                existeAutreDecision: '',
                refAutreDecision: '',
                existeProcessusVerif: '',
                refProcessusVerif: '',
                idDem: { id: 0 }, //Clé étrangère de la table Demande 

                idCodifClasseEnvisagee: { id: 0 }, //Clé étrangère de la table Demande 
                idCodifRegleApplicable: { id: 0 }, //Clé étrangère de la table codificaiton des règles
                idCodifRegleUtilisee: { id: 0 }, //Clé étrangère de la table codificaiton des règles 
                idCodifClasseFinale: { id: 0 }, //Clé étrangère de la table codificaiton des classement 
                autresReferences: '',
                refServiceDecision: 'DGD/DLRRI/STOV',

            },
            formDataOrigine: {
                id: 0,
                adrImportExport: '',
                nomImportExport: "",

                appelationCommerciale: "",
                reglesUtilises: "",
                existeAutreDemande: "",
                refAutreDemande: "",
                existeAutreDecision: "",
                refAutreDecision: "",
                existeProcessusVerif: "",
                refProcessusVerif: "",
                cadreJuridique: "",
                demande: { id: 0 }, //Clé étrangère de la table demande
                idPaysImport: { id: 0 }, //Clé étrangère de la table Pays
                idPaysExport: { id: 0 }, // Clé étrangère de la table Pays
                idPaysOrigine: { id: 0 }, // Clé étrangère de la table Pays

                idPaysOrigineFinale: { id: 0 }, // Clé étrangère de la table Pays
                idCodifClasseEnvisagee: { id: 0 }, //Clé étrangère de la table Demande 
                idCodifRegleApplicable: { id: 0 }, //Clé étrangère de la table codificaiton des règles
                idCodifRegleUtilisee: { id: 0 }, //Clé étrangère de la table codificaiton des règles 
                idCodifClasseFinale: { id: 0 }, //Clé étrangère de la table codificaiton des classement 
                autresReferences: '',
                refServiceDecision: 'DGD/DLRRI/STOV',

            },
            formDataValeur: {
                id: 0,
                lienAcheteurVendeur: '',
                descTransaction: "",
                avisDemandeur: "",
                renseignementCompl: '',
                existeAutreDemande: '',
                refAutreDemande: '',
                existeAutreDecision: '',
                refAutreDecision: '',
                existeProcessusVerif: '',
                refProcessusVerif: '',
                idCodifObjet: { id: 0 },
                idDem: { id: 0 },

                idCodifIncoterm: { id: 0 }, //Clé étrangère de la table codificaiton des incoterms
                idCodifDevise: { id: 0 }, //Clé étrangère de la table codificaiton des dévises
                idCodifMethodeEval: { id: 0 }, //Clé étrangère de la table codificaiton des méthodes d'évaluation
                idCodifRegleUtilisee: { id: 0 }, //Clé étrangère de la table codificaiton des règles 
                idCodifClasseFinale: { id: 0 }, //Clé étrangère de la table codificaiton des classement 
                montantDevise: 0,
                taux: 0,
                montantLocal: 0,
                montantFret: 0,
                montantAssurance: 0,
                montantAutresFrais: 0,
                valeurCaf: 0,
                valeurFinale: 0,

                autresReferences: '',
                refServiceDecision: 'DGD/DLRRI/STOV',
            },
            liste_document: [
                { id: 1, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                { id: 2, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                // { id: 3, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
            ],
            liste_matiere: [
                { id: 1, libelle: '', sh: '', origine: '', valeur: '', origine: '', autre: '', idDemOrigine: { id: 0 }, iduser: this.appservice.get_userconnected().id, newMatiere: true },
                { id: 2, libelle: '', sh: '', origine: '', valeur: '', origine: '', autre: '', idDemOrigine: { id: 0 }, iduser: this.appservice.get_userconnected().id, newMatiere: true },
                // { id: 3, libelle: '', sh: '', origine: '', valeur: '', origine: '', autre: '', idDemOrigine: { id: 0 }, iduser: this.appservice.get_userconnected().id, newMatiere: true },
            ]
        });


        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
        this.setState({ visible_dlg_trt: false });
        this.setState({ visible_dlg_decision: false, visible_dlg_upload: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ showindicator: true });
            if (data.type == 'Tarif') {
                this.appservice.get_dtarif(data.id).then(d => {
                    if (d.idCodifClasseFinale == null || d.idCodifClasseFinale == undefined) {
                        d.idCodifRegleUtilisee = { id: 0 };
                        d.idCodifClasseFinale = { id: 0 };
                    }
                    if (d.refServiceDecision == '') {
                        d.refServiceDecision = 'DGD/DLRRI/STOV';
                    }
                    this.setState({ formDataTarif: d, showindicator: false });
                });
            } else if (data.type == 'Origine') {
                this.appservice.get_dorigine(data.id).then(d => {
                    if (d.idCodifRegleUtilisee == null || d.idCodifRegleUtilisee == undefined) {
                        d.idCodifRegleUtilisee = { id: 0 };
                        d.idCodifClasseFinale = { id: 0 };
                        d.idPaysOrigineFinale = { id: 0 };
                    }
                    if (d.refServiceDecision == '') {
                        d.refServiceDecision = 'DGD/DLRRI/STOV';
                    }
                    this.setState({ formDataOrigine: d, showindicator: false });
                });
            } else {
                this.appservice.get_dvaleur(data.id).then(d => {
                    if (d.idCodifRegleUtilisee == null || d.idCodifRegleUtilisee == undefined) {
                        d.idCodifMethodeEval = { id: 0 };
                        d.idCodifClasseFinale = { id: 0 };
                        d.idCodifRegleUtilisee = { id: 0 };
                    }
                    if (d.refServiceDecision == '') {
                        d.refServiceDecision = 'DGD/DLRRI/STOV';
                    }
                    this.setState({ formDataValeur: d, showindicator: false });
                });
            }
            this.appservice.get_liste_document(data.id).then(d => {
                for (let i = 0; i < d.length; i++) {
                    d[i].newDoc = false;
                }
                this.setState({ liste_document: d, showindicator: false });
            });
            this.appservice.get_liste_matiere(data.id).then(d => {
                for (let i = 0; i < d.length; i++) {
                    d[i].newMatiere = false;
                }
                this.setState({ liste_matiere: d, showindicator: false });
            });

            this.setState({ modif_data: true });
            this.setState({ selectedData: data });
        }
    }

    /* Enregistrer les informations du formulaire traitement */
    enreg_decision() {
        this.setState({ submit: true });
        let form = this.state.formData;
        if (this.state.formData.type == "Tarif") {
            if (this.state.formDataTarif.idCodifRegleUtilisee.id == 0
                || this.state.formDataTarif.idCodifClasseFinale.id == 0
                || this.state.formDataTarif.refServiceDecision == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
            form.dTarif = this.state.formDataTarif;
        }

        if (this.state.formData.type == "Origine") {
            if (this.state.formDataOrigine.idCodifRegleUtilisee.id == 0
                || this.state.formDataOrigine.idCodifClasseFinale.id == 0
                || this.state.formDataOrigine.idPaysOrigineFinale.id == 0
                || this.state.formDataOrigine.refServiceDecision == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
            form.dOrigine = this.state.formDataOrigine;
        }
        if (this.state.formData.type == "Valeur") {
            if (this.state.formDataValeur.idCodifRegleUtilisee.id == 0
                || this.state.formDataValeur.idCodifClasseFinale.id == 0
                || this.state.formDataValeur.idCodifMethodeEval.id == 0
                || this.state.formDataValeur.refServiceDecision == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
            form.dValeur = this.state.formDataValeur;
        }
       
        let formTrt = this.state.formDataTrt;
        formTrt.libelle="Décision";
        formTrt.commentaire="Décision rendue pour la demande";
        formTrt.idUser={id: this.appservice.get_userconnected().id } 
        formTrt.idDem = form;
        this.setState({ showindicator: true });
        this.appservice.save_traitement(formTrt).then(reponse => {
            this.setState({ showindicator: false });
            if (reponse != undefined && reponse != null) {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            }
        });
    }

    /* Enregistrer les informations du formulaire demande */
    enreg_data() {
        if (this.state.formData.statut !== 'En attente' && this.appservice.getProfil() == 'Guest') {
            if (this.state.formData.statut == 'Recevable' && this.state.formData.feedBackWaiting==false) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette demande' });
                return;
            } else {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez plus modifier cette demande' });
                return;
            }
        }
        this.setState({ submit: true });
        if (this.state.formData.idOp.id == 0 || this.state.formData.descMarchandise == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let form = this.state.formData;

            if (this.state.formData.type == "Tarif") {
                if (this.state.formDataTarif.appelationCommerciale == ""
                    || this.state.formDataTarif.idCodifRegleApplicable.id == 0
                    || this.state.formDataTarif.existeAutreDemande == ""
                    || this.state.formDataTarif.existeAutreDecision == ""
                    || this.state.formDataTarif.existeProcessusVerif == ""
                ) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                    return;
                }

                if (this.state.liste_document.length <= 0) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez joindre les differents documents' });
                    return;
                }

                if (this.state.formDataTarif.existeAutreDemande == "Oui" && this.state.formDataTarif.refAutreDemande == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références de la demande' });
                    return;
                }

                if (this.state.formDataTarif.existeAutreDecision == "Oui" && this.state.formDataTarif.refAutreDecision == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références de la décision' });
                    return;
                }

                if (this.state.formDataTarif.existeProcessusVerif == "Oui" && this.state.formDataTarif.refProcessusVerif == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références du processus' });
                    return;
                }
                form.dTarif = this.state.formDataTarif;
            }

            if (this.state.formData.type == "Origine") {
                if (this.state.formDataOrigine.idCodifClasseEnvisagee.id == 0
                    || this.state.formDataOrigine.idCodifRegleApplicable.id == 0
                    || this.state.formDataOrigine.cadreJuridique == ""
                    || this.state.formDataOrigine.idPaysOrigine.id == 0
                    || this.state.formDataOrigine.idPaysExport.id == 0
                    || this.state.formDataOrigine.idPaysImport.id == 0
                ) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                    return;
                }

                if (this.state.formDataOrigine.existeAutreDemande == "Oui" && this.state.formDataOrigine.refAutreDemande == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références de la demande' });
                    return;
                }

                if (this.state.formDataOrigine.existeAutreDecision == "Oui" && this.state.formDataOrigine.refAutreDecision == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références de la décision' });
                    return;
                }

                if (this.state.formDataOrigine.existeProcessusVerif == "Oui" && this.state.formDataOrigine.refProcessusVerif == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références du processus' });
                    return;
                }
                form.dOrigine = this.state.formDataOrigine;
                form.liste_matiere = this.state.liste_matiere.filter(function (el) {
                    return el.libelle !== ""
                });
            }

            if (this.state.formData.type == "Valeur") {
                if (this.state.formDataValeur.lienAcheteurVendeur == ""
                    || this.state.formDataValeur.descTransaction == ""
                    || this.state.formDataValeur.avisDemandeur == ""
                    || this.state.formDataValeur.renseignementCompl == ""
                    || this.state.formDataValeur.idCodifObjet.id == 0
                    || this.state.formDataValeur.idCodifDevise.id == 0
                    || this.state.formDataValeur.idCodifIncoterm.id == 0
                ) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                    return;
                }

                if (this.state.formDataValeur.existeAutreDemande == "Oui" && this.state.formDataValeur.refAutreDemande == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références de la demande' });
                    return;
                }

                if (this.state.formDataValeur.existeAutreDecision == "Oui" && this.state.formDataValeur.refAutreDecision == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références de la décision' });
                    return;
                }

                if (this.state.formDataValeur.existeProcessusVerif == "Oui" && this.state.formDataValeur.refProcessusVerif == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez préciser les références du processus' });
                    return;
                }
                form.dValeur = this.state.formDataValeur;
            }

            form.liste_document = this.state.liste_document.filter(function (el) {
                return el.idCodifType.id !== 0
            });


            let liste = this.state.liste_document.filter(function (el) {
                return el.file !== null && el.file !== undefined
            });
            let liste_files = [];

            if (liste !== null && liste !== undefined) {
                if (liste.length > 0) {
                    liste_files = liste.map(x => {
                        return x.file
                    });
                }
            }
            this.setState({ showindicator: true });
            this.appservice.save_demande(form).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data();
                        if (liste_files.length > 0) {
                            this.filesUpload(liste_files);
                        }
                    }
                }
            });
        }
    }

    /* Annuler l'opération en cours et vider le formulaire traitement */
    annuler_trt() {
        this.setState({
            formDataTrt: {
                id: 0,
                date: null,
                libelle: "",
                commentaire: "",
                idDem: this.state.selectedData, //Clé étrangère de la table Demande
                idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
            }
        });

        this.setState({ showindicator: false });
        this.setState({ modif_trt: false });
        this.setState({ visible_dlg_trt: false });
    }

    /* Enregistrer les informations du formulaire traitement */
    enreg_trt() {
        this.setState({ submitTrt: true });
        if (this.state.formDataTrt.libelle == "" || this.state.formDataTrt.commentaire == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let form = this.state.formDataTrt;
            form.idDem = this.state.formData;
            this.setState({ showindicator: true });
            this.appservice.save_traitement(form).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }

    /* Uploader sur le serveur le logo */
    filesUpload(files) {
        this.setState({ showindicator: true });
        this.appservice.upload_multiple_files(files).then(data => {
            this.setState({ showindicator: false });
            if (data.code == 200) {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement des fichiers sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload des fichiers sur le serveur' });
            }
        });
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        if (data.statut !== 'En attente' && this.appservice.getProfil() == 'Guest') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Désolé, vous ne pouvez plus supprimer cette demande' });
            return;
        }
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue de confirmation de rejet ou de non recevabilité */
    conf_toogle_data(data) {
        this.setState({ visible_dlg_trt: true });
        this.setState({ selectedData: data });
    }


    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle demande */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Affichage de la boite de dialogue de choix du type de demande */
    toogleDlgChoixType() {
        this.annuler();
        this.setState({ visible_dlg_choix_type: !this.state.visible_dlg_choix_type });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlgChoixType} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des demandes en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("LISTE_DEMANDE");
    }


    get_form_demande() {
        if (this.state.formData.type == 'Tarif') {
            return <div style={{ marginTop: -25 }}>
                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        let file = e.target.files[0];
                        let updateds = this.state.liste_document;
                        updateds[this.state.rowIndex].file = file;
                        let name = e.target.files[0].name;
                        let nomfic = 'PT_' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                        updateds[this.state.rowIndex].ficDoc = nomfic;
                        const myNewFile = new File(
                            [file],
                            nomfic,
                            { type: file.type }
                        );
                        updateds[this.state.rowIndex].file = myNewFile;
                        this.setState({ liste_document: updateds });
                    }
                }} />
                <div>
                    <i className="icofont-clip-board" style={{ color: '#0c42c0', fontWeight: 500, fontSize: 18 }}></i> <span style={{ fontWeight: 'bold' }}>Demande de classement en matière de {this.state.formData.type} <span style={{ float: 'right' }}><Button label='Retour' onClick={(e) => this.annuler()} icon='pi pi-arrow-left' /></span></span>
                    <hr style={{ marginTop: 1, color: '#0c42c0' }} />

                </div>


                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <div className="p-fluid grid formgrid" style={{ width: '90%' }} >

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">1 - Demandeur * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formData.idOp.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_operateur}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'idOp', 'fk', this.state.formData) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.idOp })} />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '1 - Demandeur',
                                    helpContent: `Aux fins des décisions anticipées, le terme « demandeur » désigne une personne ayant sollicité auprès des autorités douanières une décision anticipée. Le code NIF et les nom et adresse du demandeur doivent être fournis`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                            </div>
                            {this.state.submit && !this.state.formData.idOp && <small className="p-invalid" style={{ color: 'red' }}>Le champs opérateur est obligatoire</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">2 - Description des marchandises * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="descMarchandise" value={this.state.formData.descMarchandise} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.descMarchandise })}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'descMarchandise', '', this.state.formData) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '2 - Description des marchandises',
                                    helpContent: `La description des marchandises en question doit être suffisamment détaillée pour permettre d’identifier ces marchandises et de les classer dans la nomenclature douanière. De même, il convient de fournir des renseignements détaillés sur la composition des marchandises (lorsque leur classement en dépend), leur degré d’ouvraison, leur emballage (conditionnement de vente au détail, etc.), leur destination prévue, leur dénomination commerciale, les éventuelles marques déposées ou de commerce ou marques d’identification.`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formData.descMarchandise && <small className="p-invalid" style={{ color: 'red' }}>Le champs Marchandise est obligatoire.</small>}

                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">3 - Pièces jointes afin de déterminer le classement des marchandises  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <DataTable ref={(el) => { this.dtDOC = el; }} value={this.state.liste_document} paginator rows={10} alwaysShowPaginator={false}
                                    onSelectionChange={e => this.setState({ selectedDoc: e.value })} selection={this.state.selectedDoc} style={{ width: '100%' }}
                                    responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    footer={
                                        <center>
                                            <Button icon='pi pi-plus' label='Ajouter un nouveau document' onClick={(e) => {
                                                let liste = this.state.liste_document;
                                                liste.push(
                                                    { id: this.state.liste_document.length + 1, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                                                );
                                                this.setState({ liste_document: liste });
                                            }} />
                                        </center>
                                    }>
                                    <Column header='#' body={this.actionTemplate_new_ligne.bind(this)} style={{ width: '3%' }} />
                                    <Column field="idCodifType.id" header="Pièce" body={(rowData, props) => {
                                        return <Dropdown placeholder='Sélectionner' options={this.state.liste_type_doc} value={rowData.idCodifType.id} autoWidth={false} style={{ width: '170px' }}
                                            filter={true} filterBy='label' filterMatchMode='contains' />
                                    }} editor={(options) => this.docEditor(options)} onCellEditComplete={this.onCellEditComplete} style={{ width: '20%' }} />
                                    <Column field="descDoc" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir la description du document' value={rowData.descDoc} style={{ width: '100%' }} />
                                    }} header='Description' filter={true} filterMatchMode='contains' editor={(options) => this.descEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                    <Column header='Fichier' body={this.actionTemplateJoindreDoc.bind(this)} style={{ width: '8%' }} />
                                </DataTable>

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '3 - Pièces jointes afin de déterminer le classement des marchandises',
                                    helpContent: `Les échantillons, photographies, plans, catalogues ou toute autre documentation susceptible de contribuer à déterminer le classement correct des marchandises dans la nomenclature douanière doivent le cas échéant être fournis sous la forme d’annexes`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">4 - Appellation commerciale et renseignements complémentaires * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="descMarchandise" value={this.state.formDataTarif.appelationCommerciale} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.appelationCommerciale })}
                                    onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'appelationCommerciale', '', this.state.formDataTarif) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '4 - Appellation commerciale et renseignements complémentaires',
                                    helpContent: `Tous les renseignements que le demandeur souhaite voir considérés comme confidentiels, notamment la marque de commerce et le numéro de modèle des marchandises, doivent être indiqués`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataTarif.appelationCommerciale && <small className="p-invalid" style={{ color: 'red' }}>Le champs appelation commerciale est obligatoire.</small>}
                        </div>



                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">5 - Classement envisagé par le demandeur (code de la nomenclature douanière) * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formDataTarif.idCodifClasseEnvisagee.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_classe_tarif}
                                    onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'idCodifClasseEnvisagee', 'fk', this.state.formDataTarif) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.idCodifClasseEnvisagee })} />

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '5 - Classement envisagé par le demandeur (code de la nomenclature douanière) ',
                                    helpContent: `Le demandeur est invité à indiquer quel est selon lui le code tarifaire correspondant aux marchandises concernées`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataTarif.idCodifClasseEnvisagee && <small className="p-invalid" style={{ color: 'red' }}>Le champs classement envisagé est obligatoire.</small>}
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">6 - Règle(s) générale(s) interprétative(s) considérée(s) comme applicable(s) aux fins du classement envisagé  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">

                                <Dropdown autoWidth={false} value={this.state.formDataTarif.idCodifRegleApplicable.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_regle_tarif}
                                    onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'idCodifRegleApplicable', 'fk', this.state.formDataTarif) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.idCodifRegleApplicable })} />


                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '6 - Règle(s) générale(s) interprétative(s) considérée(s) comme applicable(s) aux fins du classement envisagé ',
                                    helpContent: `Le demandeur est invité à indiquer quelle(s) Règle(s) générale(s) interprétative(s) constitue(nt) selon lui la base légale du classement envisagé (case 5). Le demandeur peut également fournir tout renseignement complémentaire justifiant le classement envisagé dans la case 5`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && this.state.formDataTarif.idCodifRegleApplicable.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs règles applicables est obligatoire.</small>}
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">7 - Avez-vous déjà effectué une demande de décision anticipée pour des marchandises identiques ou similaires ?   * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'existeAutreDemande', '', this.state.formDataTarif) })} checked={this.state.formDataTarif.existeAutreDemande === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'existeAutreDemande', '', this.state.formDataTarif) })} checked={this.state.formDataTarif.existeAutreDemande === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '7 - Avez-vous connaissance de l’existence d’une décision anticipée pour des marchandises identiques ou similaires ?  ',
                                        helpContent: `Il convient d’indiquer ici si le demandeur a déjà effectué une demande de décision anticipée portant sur des marchandises identiques ou similaires. Des informations détaillées concernant cette demande doivent également être fournies.`
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataTarif.existeAutreDemande && <small className="p-invalid" style={{ color: 'red' }}>Le champs 7  est obligatoire.</small>}

                            {this.state.formDataTarif.existeAutreDemande == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant cette demande</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataTarif.refAutreDemande} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.refAutreDemande })}
                                            onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'refAutreDemande', '', this.state.formDataTarif) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '7 - Précision sur la deamdne de décision anticipée existante  ',
                                            helpContent: `Précision les références de la demande anticipée existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataTarif.refAutreDemande && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision  est obligatoire.</small>}

                                </> : null
                            }

                        </div>



                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">8 - Avez-vous connaissance de l’existence d’une décision anticipée pour des marchandises identiques ou similaires ?   * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'existeAutreDecision', '', this.state.formDataTarif) })} checked={this.state.formDataTarif.existeAutreDecision === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'existeAutreDecision', '', this.state.formDataTarif) })} checked={this.state.formDataTarif.existeAutreDecision === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '8 - AAvez-vous connaissance de l’existence d’une décision anticipée pour des marchandises identiques ou similaires ?  ',
                                        helpContent: `Tout élément connu par le demandeur concernant l’existence d’une décision anticipée antérieure pour des marchandises du même type doit être indiqué, et les références précises pour ces décisions doivent être mentionnées.`
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataTarif.existeAutreDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs 8 est obligatoire.</small>}

                            {this.state.formDataTarif.existeAutreDecision == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant cette demande</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataTarif.refAutreDecision} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.refAutreDecision })}
                                            onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'refAutreDecision', '', this.state.formDataTarif) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '8 - Précision sur la décision anticipée existante  ',
                                            helpContent: `Précision les références de la décision existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataTarif.refAutreDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision  est obligatoire.</small>}

                                </> : null
                            }
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">9 - Savez-vous si les marchandises font l’objet d’un processus de vérification de classement ou de toute procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel ?    * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'existeProcessusVerif', '', this.state.formDataTarif) })} checked={this.state.formDataTarif.existeProcessusVerif === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'existeProcessusVerif', '', this.state.formDataTarif) })} checked={this.state.formDataTarif.existeProcessusVerif === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '9 - Savez-vous si les marchandises font l’objet d’un processus de vérification de classement ou de toute procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel ?  ',
                                        helpContent: `Tout élément connu par le demandeur concernant l’existence d’un processus de vérification du classement ou d’une procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel doit être indiqué, et les références précises doivent être mentionnées. `
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataTarif.existeProcessusVerif && <small className="p-invalid" style={{ color: 'red' }}>Le champs 9  est obligatoire.</small>}

                            {this.state.formDataTarif.existeProcessusVerif == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant ce processus</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataTarif.refProcessusVerif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.refProcessusVerif })}
                                            onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'refProcessusVerif', '', this.state.formDataTarif) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '8 - Précision sur le processus en cours  ',
                                            helpContent: `Précision les références de la décision existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataTarif.refProcessusVerif && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision est obligatoire.</small>}

                                </> : null
                            }
                        </div>

                    </div>

                </div>


            </div>
        } else if (this.state.formData.type == 'Origine') {
            return <div style={{ marginTop: -25 }}>
                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        let file = e.target.files[0];
                        let updateds = this.state.liste_document;
                        updateds[this.state.rowIndex].file = file;
                        let name = e.target.files[0].name;
                        let nomfic = 'PO_' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                        updateds[this.state.rowIndex].ficDoc = nomfic;
                        const myNewFile = new File(
                            [file],
                            nomfic,
                            { type: file.type }
                        );
                        updateds[this.state.rowIndex].file = myNewFile;
                        this.setState({ liste_document: updateds });
                    }
                }} />
                <div>
                    <i className="icofont-clip-board" style={{ color: '#0c42c0', fontWeight: 500, fontSize: 18 }}></i> <span style={{ fontWeight: 'bold' }}>Demande de classement en matière de {this.state.formData.type} <span style={{ float: 'right' }}><Button label='Retour' onClick={(e) => this.annuler()} icon='pi pi-arrow-left' /></span></span>
                    <hr style={{ marginTop: 1, color: '#0c42c0' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>


                    <div className="p-fluid grid formgrid" style={{ width: '90%' }} >

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">1 - Demandeur * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formData.idOp.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_operateur}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'idOp', 'fk', this.state.formData) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.idOp })} />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '1 - Demandeur',
                                    helpContent: `Aux fins des décisions anticipées, le terme « demandeur » désigne une personne ayant sollicité auprès des autorités douanières une décision anticipée. Le code NIF et les nom et adresse du demandeur doivent être fournis`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                            </div>
                            {this.state.submit && !this.state.formData.idOp && <small className="p-invalid" style={{ color: 'red' }}>Le champs opérateur est obligatoire</small>}
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">2 - Importateur, exportateur, producteur et agent (nom, adresse s’ils sont connus)  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputText id="descMarchandise" value={this.state.formDataOrigine.nomImportExport} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.nomImportExport })}
                                    onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'nomImportExport', '', this.state.formDataOrigine) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '2 - Importateur, exportateur, producteur et agent (nom, adresse s’ils sont connus)',
                                    helpContent: `Les noms et adresses de l’importateur, de l’exportateur, du producteur et, le cas échéant, de l’agent, doivent être fournis`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">3 - Cadre juridique (préférentiel/non préférentiel) * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formDataOrigine.cadreJuridique} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={[
                                        { value: 'Préférentiel', label: 'Préférentiel' },
                                        { value: 'Non préférentiel', label: 'Non préférentiel' },
                                    ]}
                                    onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'cadreJuridique', '', this.state.formDataOrigine) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.cadreJuridique })} />

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '3 - Cadre juridique (préférentiel/non préférentiel) ',
                                    helpContent: `Le demandeur doit déclarer si la décision anticipée est requise dans un cadre préférentiel ou non préférentiel. Lorsque des décisions anticipées sont requises dans le cadre d’une origine préférentielle, le demandeur doit préciser quel est le régime applicable`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataOrigine.cadreJuridique && <small className="p-invalid" style={{ color: 'red' }}>Le champs cadre juridique est obligatoire.</small>}
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">4 - Description des marchandises * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="descMarchandise" value={this.state.formData.descMarchandise} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.descMarchandise })}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'descMarchandise', '', this.state.formData) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '2 - Description des marchandises',
                                    helpContent: `La description des marchandises en question doit être suffisamment détaillée pour permettre d’identifier ces marchandises (et de les classer dans la nomenclature douanière). De même, il convient de fournir des renseignements détaillés sur la composition des marchandises et sur les méthodes utilisées pour déterminer leur composition`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formData.descMarchandise && <small className="p-invalid" style={{ color: 'red' }}>Le champs Marchandise est obligatoire.</small>}

                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">5 - Classement tarifaire des marchandises  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formDataOrigine.idCodifClasseEnvisagee.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_classe_tarif}
                                    onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idCodifClasseEnvisagee', 'fk', this.state.formDataOrigine) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idCodifClasseEnvisagee.id == 0 })} />

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '5 - Classement tarifaire des marchandises  ',
                                    helpContent: `Il convient de fournir le code de la nomenclature tarifaire complet correspondant aux marchandises concernées`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && this.state.formDataOrigine.idCodifClasseEnvisagee.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs classement tarifaire est obligatoire.</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">6 - Description des matières utilisées pour la fabrication * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <DataTable ref={(el) => { this.dtMatiere = el; }} value={this.state.liste_matiere} paginator rows={10} alwaysShowPaginator={false}
                                    onSelectionChange={e => this.setState({ selectedMatiere: e.value })} selection={this.state.selectedMatiere} style={{ width: '100%' }}
                                    responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    footer={
                                        <center>
                                            <Button icon='pi pi-plus' label='Ajouter une nouvelle matière' onClick={(e) => {
                                                let liste = this.state.liste_matiere;
                                                liste.push(
                                                    { id: this.state.liste_matiere.length + 1, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                                                );
                                                this.setState({ liste_matiere: liste });
                                            }} />
                                        </center>
                                    }>
                                    <Column header='#' body={this.actionTemplate_new_matiere.bind(this)} style={{ width: '3%' }} />
                                    <Column field="libelle" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir ' value={rowData.libelle} style={{ width: '100%' }} />
                                    }} header='Matière' filter={true} filterMatchMode='contains' editor={(options) => this.matiereEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                    <Column field="sh" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir ' value={rowData.sh} style={{ width: '100%' }} />
                                    }} header='SH' filter={true} filterMatchMode='contains' editor={(options) => this.shEditor(options)} onCellEditComplete={this.onCellEditComplete} />

                                    <Column field="origine" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir ' value={rowData.origine} style={{ width: '100%' }} />
                                    }} header='Origine' filter={true} filterMatchMode='contains' editor={(options) => this.origineEditor(options)} onCellEditComplete={this.onCellEditComplete} />

                                    <Column field="valeur" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir ' value={rowData.valeur} style={{ width: '100%' }} />
                                    }} header='Valeur' filter={true} filterMatchMode='contains' editor={(options) => this.valeurEditor(options)} onCellEditComplete={this.onCellEditComplete} />

                                    <Column field="autre" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir ' value={rowData.autre} style={{ width: '100%' }} />
                                    }} header='Autre' filter={true} filterMatchMode='contains' editor={(options) => this.autreEditor(options)} onCellEditComplete={this.onCellEditComplete} />

                                </DataTable>

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '6 - Description des matières utilisées pour la fabrication',
                                    helpContent: `Une description détaillée des marchandises doit être fournie. Le demandeur doit répertorier dans les lignes et les colonnes prévues à cet effet l’ensemble des matières / composants / parties employés lors de la fabrication, ainsi que leur pays d’origine, leur position tarifaire et leur valeur`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">7 - Règle considérée comme devant être respectée  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formDataOrigine.idCodifRegleApplicable.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_regle_origine}
                                    onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idCodifRegleApplicable', 'fk', this.state.formDataOrigine) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idCodifRegleApplicable.id == 0 })} />

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '7 - Règle considérée comme devant être respectée  ',
                                    helpContent: `La règle d’origine préférentielle ou non préférentielle jugée pertinente pour le produit concerné doit être explicitée. En outre, le demandeur doit expliquer comment cette règle a été respectée dans le pays de fabrication/d’origine en fournissant une description complète et détaillée de l’ensemble des étapes de tout processus de fabrication. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && this.state.formDataOrigine.idCodifRegleApplicable.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs règle est obligatoire.</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">8 - Pays d’origine envisagé par le demandeur  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <div class="grid" >
                                    <div class="col-4">
                                        <div class="text-center p-3 border-round-sm font-bold" style={{ backgroundColor: '#f7f7f7' }}>
                                            Pays <br /> d’origine
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="text-center p-3 border-round-sm font-bold " style={{ backgroundColor: '#f7f7f7' }}>
                                            Pays d’exportation <br /> (si diffèrent)
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="text-center p-3 border-round-sm font-bold " style={{ backgroundColor: '#f7f7f7' }}>
                                            Pays<br /> d’importation
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <Dropdown autoWidth={false} value={this.state.formDataOrigine.idPaysOrigine.id} filter={true} filterBy='label' filterMatchMode='contains'
                                            options={this.state.liste_pays} style={{ width: '100%', margin: 0 }}
                                            onChange={(e) => {
                                                this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idPaysOrigine', 'fk', this.state.formDataOrigine) });
                                                this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idPaysExport', 'fk', this.state.formDataOrigine) });
                                                this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idPaysImport', 'fk', this.state.formDataOrigine) });
                                            }} appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idPaysOrigine.id == 0 })} />
                                    </div>
                                    <div class="col-4">
                                        <Dropdown autoWidth={false} value={this.state.formDataOrigine.idPaysExport.id} filter={true} filterBy='label' filterMatchMode='contains'
                                            options={this.state.liste_pays} style={{ width: '100%', margin: 0 }}
                                            onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idPaysExport', 'fk', this.state.formDataOrigine) })}
                                            appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idPaysExport.id == 0 })} />
                                    </div>
                                    <div class="col-4">
                                        <Dropdown autoWidth={false} value={this.state.formDataOrigine.idPaysImport.id} filter={true} filterBy='label' filterMatchMode='contains'
                                            options={this.state.liste_pays} style={{ width: '100%', margin: 0 }}
                                            onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idPaysImport', 'fk', this.state.formDataOrigine) })}
                                            appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idPaysImport.id == 0 })} />
                                    </div>
                                </div>
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '8 - Pays d’origine envisagé par le demandeur ',
                                    helpContent: `Le demandeur est prié d’exposer son point de vue quant au pays d’origine envisagé. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                            </div>
                            {this.state.submit && this.state.formDataOrigine.idPaysOrigine.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs pays d'origine est obligatoire</small>}
                        </div>





                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">9 - Pièces jointes présentées afin de contribuer à la détermination de l’origine des marchandises * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <DataTable ref={(el) => { this.dtDOC = el; }} value={this.state.liste_document} paginator rows={10} alwaysShowPaginator={false}
                                    onSelectionChange={e => this.setState({ selectedDoc: e.value })} selection={this.state.selectedDoc} style={{ width: '100%' }}
                                    responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    footer={
                                        <center>
                                            <Button icon='pi pi-plus' label='Ajouter un nouveau document' onClick={(e) => {
                                                let liste = this.state.liste_document;
                                                liste.push(
                                                    { id: this.state.liste_document.length + 1, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                                                );
                                                this.setState({ liste_document: liste });
                                            }} />
                                        </center>
                                    }>
                                    <Column header='#' body={this.actionTemplate_new_ligne.bind(this)} style={{ width: '3%' }} />
                                    <Column field="idCodifType.id" header="Pièce" body={(rowData, props) => {
                                        return <Dropdown placeholder='Sélectionner' options={this.state.liste_type_doc} value={rowData.idCodifType.id} autoWidth={false} style={{ width: '170px' }}
                                            filter={true} filterBy='label' filterMatchMode='contains' />
                                    }} editor={(options) => this.docEditor(options)} onCellEditComplete={this.onCellEditComplete} style={{ width: '20%' }} />
                                    <Column field="descDoc" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir la description du document' value={rowData.descDoc} style={{ width: '100%' }} />
                                    }} header='Description' filter={true} filterMatchMode='contains' editor={(options) => this.descEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                    <Column header='Fichier' body={this.actionTemplateJoindreDoc.bind(this)} style={{ width: '8%' }} />
                                </DataTable>

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '9 - Pièces jointes présentées afin de contribuer à la détermination de l’origine des marchandises ',
                                    helpContent: `Les échantillons, photographies, plans, catalogues ou toute autre documentation relatifs à la composition des marchandises ou aux matières qui la composent et de nature à illustrer le procédé de fabrication ou de transformation subie par ces matières doivent, le cas échéant, être fournis sous la forme d’annexes. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">10 - Appellation commerciale et renseignements complémentaires  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="descMarchandise" value={this.state.formDataOrigine.appelationCommerciale} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.appelationCommerciale })}
                                    onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'appelationCommerciale', '', this.state.formDataOrigine) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '4 - Appellation commerciale et renseignements complémentaires',
                                    helpContent: `Tous les renseignements que le demandeur souhaite voir considérés comme confidentiels, notamment la marque de commerce et le numéro de modèle des marchandises, doivent être indiqués`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataOrigine.appelationCommerciale && <small className="p-invalid" style={{ color: 'red' }}>Le champs appelation commerciale est obligatoire.</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">11 - Avez-vous déjà effectué une demande de décision anticipée pour des marchandises identiques ou similaires ?   * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'existeAutreDemande', '', this.state.formDataOrigine) })} checked={this.state.formDataOrigine.existeAutreDemande === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'existeAutreDemande', '', this.state.formDataOrigine) })} checked={this.state.formDataOrigine.existeAutreDemande === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '11 - Avez-vous connaissance de l’existence d’une décision anticipée pour des marchandises identiques ou similaires ?  ',
                                        helpContent: `Il convient d’indiquer ici si le demandeur a déjà effectué une demande de décision anticipée portant sur des marchandises identiques ou similaires. Des informations détaillées concernant cette demande doivent également être fournies.`
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataOrigine.existeAutreDemande && <small className="p-invalid" style={{ color: 'red' }}>Le champs 7  est obligatoire.</small>}

                            {this.state.formDataOrigine.existeAutreDemande == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant cette demande</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataOrigine.refAutreDemande} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.refAutreDemande })}
                                            onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'refAutreDemande', '', this.state.formDataOrigine) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '7 - Précision sur la deamdne de décision anticipée existante  ',
                                            helpContent: `Précision les références de la demande anticipée existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataOrigine.refAutreDemande && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision  est obligatoire.</small>}

                                </> : null
                            }

                        </div>



                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">12 - Avez-vous connaissance de l’existence d’une décision anticipée pour des marchandises identiques ou similaires ?   * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'existeAutreDecision', '', this.state.formDataOrigine) })} checked={this.state.formDataOrigine.existeAutreDecision === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'existeAutreDecision', '', this.state.formDataOrigine) })} checked={this.state.formDataOrigine.existeAutreDecision === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '12 - AAvez-vous connaissance de l’existence d’une décision anticipée pour des marchandises identiques ou similaires ?  ',
                                        helpContent: `Tout élément connu par le demandeur concernant l’existence d’une décision anticipée antérieure pour des marchandises du même type doit être indiqué, et les références précises pour ces décisions doivent être mentionnées.`
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataOrigine.existeAutreDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs 8 est obligatoire.</small>}

                            {this.state.formDataOrigine.existeAutreDecision == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant cette demande</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataOrigine.refAutreDecision} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.refAutreDecision })}
                                            onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'refAutreDecision', '', this.state.formDataOrigine) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '12 - Précision sur la décision anticipée existante  ',
                                            helpContent: `Précision les références de la décision existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataOrigine.refAutreDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision  est obligatoire.</small>}

                                </> : null
                            }
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">13 - Les marchandises font-elles l’objet d’un processus de vérification de l’origine ou de toute procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel ?    * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'existeProcessusVerif', '', this.state.formDataOrigine) })} checked={this.state.formDataOrigine.existeProcessusVerif === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'existeProcessusVerif', '', this.state.formDataOrigine) })} checked={this.state.formDataOrigine.existeProcessusVerif === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '13 - Les marchandises font-elles l’objet d’un processus de vérification de l’origine ou de toute procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel ?   ',
                                        helpContent: `Le demandeur doit informer l’autorité compétente chargée de délivrer les décisions anticipées si les marchandises font l’objet d’un processus de vérification de l’origine ou de toute procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel. `
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataOrigine.existeProcessusVerif && <small className="p-invalid" style={{ color: 'red' }}>Le champs 9  est obligatoire.</small>}

                            {this.state.formDataOrigine.existeProcessusVerif == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant ce processus</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataOrigine.refProcessusVerif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.refProcessusVerif })}
                                            onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'refProcessusVerif', '', this.state.formDataOrigine) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '13 - Précision sur le processus en cours  ',
                                            helpContent: `Précision les références de la décision existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataOrigine.refProcessusVerif && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision est obligatoire.</small>}

                                </> : null
                            }
                        </div>

                    </div>


                </div>


            </div>
        } else {
            return <div style={{ marginTop: -25 }}>
                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        let file = e.target.files[0];
                        let updateds = this.state.liste_document;
                        updateds[this.state.rowIndex].file = file;
                        let name = e.target.files[0].name;
                        let nomfic = 'PT_' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                        updateds[this.state.rowIndex].ficDoc = nomfic;
                        const myNewFile = new File(
                            [file],
                            nomfic,
                            { type: file.type }
                        );
                        updateds[this.state.rowIndex].file = myNewFile;
                        this.setState({ liste_document: updateds });
                    }
                }} />
                <div>
                    <i className="icofont-clip-board" style={{ color: '#0c42c0', fontWeight: 500, fontSize: 18 }}></i> <span style={{ fontWeight: 'bold' }}>Demande de classement en matière de {this.state.formData.type}<span style={{ float: 'right' }}><Button label='Retour' onClick={(e) => this.annuler()} icon='pi pi-arrow-left' /></span></span>
                    <hr style={{ marginTop: 1, color: '#0c42c0' }} />
                </div>


                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <div className="p-fluid grid formgrid" style={{ width: '90%' }} >

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">1 - Demandeur * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formData.idOp.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_operateur}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'idOp', 'fk', this.state.formData) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formData.idOp.id == 0 })} />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '1 - Demandeur',
                                    helpContent: `Aux fins des décisions anticipées, le terme « demandeur » désigne une personne ayant sollicité auprès des autorités douanières une décision anticipée. Le code NIF et les nom et adresse du demandeur doivent être fournis`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                            </div>
                            {this.state.submit && this.state.formData.idOp.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs opérateur est obligatoire</small>}

                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">2 - Description des marchandises * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="descMarchandise" value={this.state.formData.descMarchandise} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.descMarchandise })}
                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'descMarchandise', '', this.state.formData) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '2 - Description des marchandises',
                                    helpContent: `La description des marchandises en question doit être suffisamment détaillée pour permettre d’identifier ces marchandises (et de les classer dans la nomenclature douanière). De même, il convient de fournir des renseignements détaillés sur la composition des marchandises et sur les méthodes utilisées pour déterminer leur composition`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formData.descMarchandise && <small className="p-invalid" style={{ color: 'red' }}>Le champs Marchandise est obligatoire.</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">3 - Objet de la demande * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <Dropdown autoWidth={false} value={this.state.formDataValeur.idCodifObjet.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_objet}
                                    onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'idCodifObjet', 'fk', this.state.formDataValeur) })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataValeur.idCodifObjet.id == 0 })} />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '3 - Objet de la demande',
                                    helpContent: `Le demandeur doit indiquer la nature de la question pour laquelle une décision est demandée. Si la case « Autre » est cochée, veuillez décrire la question spécifique pour laquelle une décision est demandée. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                            </div>
                            {this.state.submit && this.state.formDataValeur.idCodifObjet.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs objet est obligatoire</small>}
                        </div>

                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">4 - L’acheteur et le vendeur sont-ils liés ?  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'lienAcheteurVendeur', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.lienAcheteurVendeur === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'lienAcheteurVendeur', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.lienAcheteurVendeur === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '4 - L’acheteur et le vendeur sont-ils liés ?',
                                        helpContent: `Les liens pouvant existés entre l’acheteur et le vendeur sont définis dans l’Article 15.4 de l’Accord de l’OMC sur l’évaluation en douane. Des documents justificatifs peuvent être nécessaires pour confirmer l’existence d’un lien entre l’acheteur et le vendeur. `
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataValeur.lienAcheteurVendeur && <small className="p-invalid" style={{ color: 'red' }}>Le champs 4  est obligatoire.</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">5 - Description des transactions (montants, dévise, incoterms, fret, assurance, etc...)* </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <div style={{ width: '98%' }}>
                                    <div className="formgrid grid">
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="Nature">Incoterm *</label>
                                            <Dropdown autoWidth={false} value={this.state.formDataValeur.idCodifIncoterm.id} filter={true} filterBy='label' filterMatchMode='contains'
                                                options={this.state.liste_incoterm} style={{ width: '100%', height: 46 }}
                                                onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'idCodifIncoterm', 'fk', this.state.formDataValeur) })}
                                                appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataValeur.idCodifIncoterm.id == 0 })} />
                                            {this.state.submit && this.state.formDataValeur.idCodifIncoterm.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs incoterm est obligatoire.</small>}
                                        </div>
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="Nature">Montant en dévise *</label>
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon" style={{ paddingBlock: 4, width: 150 }}>
                                                    <Dropdown autoWidth={false} placeholder='Dévise' value={this.state.formDataValeur.idCodifDevise.id} filter={true} filterBy='label' filterMatchMode='contains'
                                                        options={this.state.liste_devise} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'idCodifDevise', 'fk', this.state.formDataValeur) })}
                                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataValeur.idCodifDevise.id == 0 })} style={{ width: 80 }} />
                                                </span>
                                                <InputNumber value={this.state.formDataValeur.montantDevise} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.montantDevise })}
                                                    min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'montantDevise', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                            </div>
                                            {this.state.submit && !this.state.formDataValeur.montantDevise && <small className="p-invalid" style={{ color: 'red' }}>Le champs montant en dévise est obligatoire.</small>}
                                        </div>
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="Nature">Montant en local *</label>
                                            <InputNumber value={this.state.formDataValeur.montantLocal} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.montantLocal })}
                                                min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'montantLocal', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                            {this.state.submit && !this.state.formDataValeur.montantLocal && <small className="p-invalid" style={{ color: 'red' }}>Le montant en local est obligatoire.</small>}
                                        </div>
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="Nature">Taux de change *</label>
                                            <InputNumber value={this.state.formDataValeur.taux} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.taux })}
                                                min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'taux', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                            {this.state.submit && !this.state.formDataValeur.taux && <small className="p-invalid" style={{ color: 'red' }}>Le taux de change est obligatoire.</small>}
                                        </div>

                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="montantFret">Montant fret </label>
                                            <InputNumber value={this.state.formDataValeur.montantFret}
                                                min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'montantFret', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                        </div>
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="montantAssurance">Montant assurance </label>
                                            <InputNumber value={this.state.formDataValeur.montantAssurance}
                                                min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'montantAssurance', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                        </div>
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="montantAssurance">Autres frais </label>
                                            <InputNumber value={this.state.formDataValeur.montantAutresFrais}
                                                min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'montantAutresFrais', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                        </div>
                                        <div className="field col-6" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="Nature">Valeur CAF *</label>
                                            <InputNumber value={this.state.formDataValeur.valeurCaf} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.valeurCaf })}
                                                min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'valeurCaf', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                            {this.state.submit && !this.state.formDataValeur.valeurCaf && <small className="p-invalid" style={{ color: 'red' }}>Le valeur CAF est obligatoire.</small>}
                                        </div>

                                        <div className="field col-12" style={{ padding: 2 }}>
                                            <label style={{ fontSize: 12 }} htmlFor="Nature">Description supplémentaire *</label>
                                            <InputTextarea rows={2} id="descTransaction" value={this.state.formDataValeur.descTransaction} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.descTransaction })}
                                                onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'descTransaction', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                                        </div>
                                    </div>
                                </div>
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '5 - Description des transactions',
                                    helpContent: `La description de la (des) transaction(s) doit être suffisamment détaillée pour permettre à la douane de prendre une décision anticipée. De même, il convient de fournir des informations détaillées concernant le flux de la transaction, les conditions de vente (le cas échéant), les conditions de livraison (Incoterms), les données relatives aux intermédiaires impliqués dans la transaction et à leur rôle (par exemple agent d'achat ou de vente, courtiers, etc.), les éléments susceptibles d’être ajoutés en vertu de l'article 8, la méthode de paiement, etc.`
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataValeur.descTransaction && <small className="p-invalid" style={{ color: 'red' }}>Le champs description des transactions est obligatoire.</small>}
                        </div>



                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">6 - Documents communiqués afin de contribuer à la détermination de la décision en matière d’évaluation  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <DataTable ref={(el) => { this.dtDOC = el; }} value={this.state.liste_document} paginator rows={10} alwaysShowPaginator={false}
                                    onSelectionChange={e => this.setState({ selectedDoc: e.value })} selection={this.state.selectedDoc} style={{ width: '100%' }}
                                    responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    footer={
                                        <center>
                                            <Button icon='pi pi-plus' label='Ajouter un nouveau document' onClick={(e) => {
                                                let liste = this.state.liste_document;
                                                liste.push(
                                                    { id: this.state.liste_document.length + 1, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                                                );
                                                this.setState({ liste_document: liste });
                                            }} />
                                        </center>
                                    }>
                                    <Column header='#' body={this.actionTemplate_new_ligne.bind(this)} style={{ width: '3%' }} />
                                    <Column field="idCodifType.id" header="Pièce" body={(rowData, props) => {
                                        return <Dropdown placeholder='Sélectionner' options={this.state.liste_type_doc} value={rowData.idCodifType.id} autoWidth={false} style={{ width: '170px' }}
                                            filter={true} filterBy='label' filterMatchMode='contains' />
                                    }} editor={(options) => this.docEditor(options)} onCellEditComplete={this.onCellEditComplete} style={{ width: '20%' }} />
                                    <Column field="descDoc" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir la description du document' value={rowData.descDoc} style={{ width: '100%' }} />
                                    }} header='Description' filter={true} filterMatchMode='contains' editor={(options) => this.descEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                    <Column header='Fichier' body={this.actionTemplateJoindreDoc.bind(this)} style={{ width: '8%' }} />
                                </DataTable>

                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '6 -  Documents communiqués afin de contribuer à la détermination de la décision en matière d’évaluation',
                                    helpContent: `Le demandeur doit joindre tous les documents se rapportant à la transaction en question qui permettraient à la douane de prendre une décision éclairée sur la décision à rendre. L'absence de pièces justificatives pertinentes pourrait entraîner le rejet de la demande par les autorités douanières. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">7 - Renseignements complémentaires  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="renseignementCompl" value={this.state.formDataValeur.renseignementCompl} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.renseignementCompl })}
                                    onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'renseignementCompl', '', this.state.formDataValeur) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '7 - Renseignements complémentaires ',
                                    helpContent: `Tous les renseignements que le demandeur souhaite voir considérés comme confidentiels, notamment le nom des sociétés impliquées et le pays d’importation et d’exportation, doivent être indiqués. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataValeur.renseignementCompl && <small className="p-invalid" style={{ color: 'red' }}>Le champs renseignement complémentaires des transactions est obligatoire.</small>}
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">8 - Avez-vous déjà effectué une demande de décision anticipée pour la même question ou pour une question similaire ?  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'existeAutreDemande', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.existeAutreDemande === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'existeAutreDemande', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.existeAutreDemande === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '8 - Avez-vous déjà effectué une demande de décision anticipée pour la même question ou pour une question similaire ?  ',
                                        helpContent: `Il convient d’indiquer ici si le demandeur a déjà effectué une demande de décision anticipée portant sur la même question ou sur une question similaire. Des informations détaillées concernant cette demande doivent également être fournies.`
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataValeur.existeAutreDemande && <small className="p-invalid" style={{ color: 'red' }}>Le champs 7  est obligatoire.</small>}

                            {this.state.formDataValeur.existeAutreDemande == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant cette demande</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataValeur.refAutreDemande} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.refAutreDemande })}
                                            onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'refAutreDemande', '', this.state.formDataValeur) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '8 - Précision sur la deamdne de décision anticipée existante  ',
                                            helpContent: `Précision les références de la demande anticipée existante pour la même question ou pour une question similaire`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataValeur.refAutreDemande && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision  est obligatoire.</small>}

                                </> : null
                            }

                        </div>



                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">9 - Avez-vous connaissance de l’existence d’une décision anticipée pour la même question ou pour une question similaire ?   * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'existeAutreDecision', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.existeAutreDecision === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'existeAutreDecision', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.existeAutreDecision === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '9 - Avez-vous connaissance de l’existence d’une décision anticipée pour la même question ou pour une question similaire ?   ',
                                        helpContent: `Tout élément connu par le demandeur concernant l’existence d’une décision anticipée portant sur la même question ou sur une question similaire au sujet de la transaction doit être indiqué, et les références précises doivent être mentionnées. `
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataValeur.existeAutreDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs 8 est obligatoire.</small>}

                            {this.state.formDataValeur.existeAutreDecision == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant cette demande</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataValeur.refAutreDecision} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.refAutreDecision })}
                                            onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'refAutreDecision', '', this.state.formDataValeur) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '9 - Précision sur la décision anticipée existante  ',
                                            helpContent: `Précision les références de la décision existante pour la même question ou pour une question similaire`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataValeur.refAutreDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision  est obligatoire.</small>}

                                </> : null
                            }
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">10 - La question pour laquelle une décision est demandée est-elle en instance auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel ?    * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="row" style={{ marginTop: 10, width: 300 }} >
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemOui" name="autreDemOui" value={'Oui'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'existeProcessusVerif', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.existeProcessusVerif === 'Oui'} />
                                    <label htmlFor="autreDemOui" style={{ marginLeft: 5 }}>Oui</label>
                                </div>
                                <div className="col-lg-5">
                                    <RadioButton inputId="autreDemNon" name="autreDemNon" value={'Non'} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'existeProcessusVerif', '', this.state.formDataValeur) })} checked={this.state.formDataValeur.existeProcessusVerif === 'Non'} />
                                    <label htmlFor="autreDemNon" style={{ marginLeft: 5 }}>Non</label>
                                </div>
                                <div className="col-lg-2">
                                    <Button onClick={() => this.setState({
                                        visible_dlg_help: true,
                                        helpField: '10 - La question pour laquelle une décision est demandée est-elle en instance auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel ?  ',
                                        helpContent: `Tout élément connu par le demandeur concernant l’existence d’une décision demandée ou d’une procédure de réexamen ou de recours auprès d’un organisme gouvernemental ou d’un tribunal ou cour d’appel doit être indiqué, et les références précises doivent être mentionnées.`
                                    })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0', marginTop: -5 }} aria-label="Filter" />

                                </div>
                            </div>
                            {this.state.submit && !this.state.formDataValeur.existeProcessusVerif && <small className="p-invalid" style={{ color: 'red' }}>Le champs 10  est obligatoire.</small>}

                            {this.state.formDataValeur.existeProcessusVerif == "Oui" ?
                                <>
                                    <label>Précisez les informations détaillées concernant ce processus</label>
                                    <div className="p-inputgroup">
                                        <InputTextarea rows={2} id="refAutreDemande" value={this.state.formDataValeur.refProcessusVerif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.refProcessusVerif })}
                                            onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'refProcessusVerif', '', this.state.formDataValeur) })} />
                                        <Button onClick={() => this.setState({
                                            visible_dlg_help: true,
                                            helpField: '10 - Précision sur le processus en cours  ',
                                            helpContent: `Précision les références de la décision existante pour des marchandises identiques ou similaires`
                                        })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />
                                    </div>
                                    {this.state.submit && !this.state.formDataValeur.refProcessusVerif && <small className="p-invalid" style={{ color: 'red' }}>Le champs précision est obligatoire.</small>}

                                </> : null
                            }
                        </div>


                        <div className="field col-12 md:col-5">
                            <label htmlFor="idOp">11 - Avis/position du demandeur au sujet de la décision demandée  * </label>
                        </div>
                        <div className="field col-12 md:col-7">
                            <div className="p-inputgroup">
                                <InputTextarea rows={2} id="avisDemandeur" value={this.state.formDataValeur.avisDemandeur} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.avisDemandeur })}
                                    onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'avisDemandeur', '', this.state.formDataValeur) })} required />
                                <Button onClick={() => this.setState({
                                    visible_dlg_help: true,
                                    helpField: '11 - Avis/position du demandeur au sujet de la décision demandée  ',
                                    helpContent: `Le demandeur est invité à indiquer son avis/sa position concernant la transaction en question et le traitement qu’il propose de lui accorder. `
                                })} icon="pi pi-question-circle" className="p-button-rounded p-button-text p-button-plain" style={{ color: '#0c42c0' }} aria-label="Filter" />

                            </div>
                            {this.state.submit && !this.state.formDataValeur.avisDemandeur && <small className="p-invalid" style={{ color: 'red' }}>Le champs avis est obligatoire.</small>}

                        </div>

                    </div>






                </div>


            </div>
        }
    }

    get_form_decision() {
        if (this.state.formData.type == 'Tarif') {
            return <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Position tarifaire * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataTarif.idCodifClasseFinale.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_classe_tarif}
                        onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'idCodifClasseFinale', 'fk', this.state.formDataTarif) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataTarif.idCodifClasseFinale.id == 0 })} />
                    {this.state.submit && this.state.formDataTarif.idCodifClasseFinale.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs position est obligatoire.</small>}
                </div>
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Règle de classement  * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataTarif.idCodifRegleUtilisee.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_regle_tarif}
                        onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'idCodifRegleUtilisee', 'fk', this.state.formDataTarif) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataTarif.idCodifRegleUtilisee })} />
                    {this.state.submit && this.state.formDataTarif.idCodifRegleUtilisee.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs règle utilisée est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Références service de décision * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <InputTextarea rows={2} id="refServiceDecision" value={this.state.formDataTarif.refServiceDecision} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'refServiceDecision', '', this.state.formDataTarif) })} />
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Autres références </label>
                </div>
                <div className="field col-12 md:col-4">
                    <InputTextarea rows={2} id="autresReferences" value={this.state.formDataTarif.autresReferences} onChange={(e) => this.setState({ formDataTarif: this.appservice.onInputChange(e, 'autresReferences', '', this.state.formDataTarif) })} />
                </div>


            </div>
        } else if (this.state.formData.type == 'Origine') {
            return <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Position tarifaire * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataOrigine.idCodifClasseFinale.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_classe_tarif}
                        onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idCodifClasseFinale', 'fk', this.state.formDataOrigine) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idCodifClasseFinale.id == 0 })} />
                    {this.state.submit && this.state.formDataOrigine.idCodifClasseFinale.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs position est obligatoire.</small>}
                </div>
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Règle * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataOrigine.idCodifRegleUtilisee.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_regle_tarif}
                        onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idCodifRegleUtilisee', 'fk', this.state.formDataOrigine) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataOrigine.idCodifRegleUtilisee })} />
                    {this.state.submit && this.state.formDataOrigine.idCodifRegleUtilisee.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs règle utilisée est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Origine * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataOrigine.idPaysOrigineFinale.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_pays} style={{ width: '100%', margin: 0 }}
                        onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'idPaysOrigineFinale', 'fk', this.state.formDataOrigine) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataOrigine.idPaysOrigineFinale.id == 0 })} />
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Références service de décision *</label>
                </div>
                <div className="field col-12 md:col-4">
                    <InputTextarea rows={2} id="refServiceDecision" value={this.state.formDataOrigine.refServiceDecision} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'refServiceDecision', '', this.state.formDataOrigine) })} />
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Autres références </label>
                </div>
                <div className="field col-12 md:col-10">
                    <InputTextarea rows={2} id="autresReferences" value={this.state.formDataOrigine.autresReferences} onChange={(e) => this.setState({ formDataOrigine: this.appservice.onInputChange(e, 'autresReferences', '', this.state.formDataOrigine) })} />
                </div>


            </div>
        } else {
            return <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Position tarifaire * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataValeur.idCodifClasseFinale.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_classe_tarif}
                        onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'idCodifClasseFinale', 'fk', this.state.formDataValeur) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.idCodifClasseFinale })} />
                    {this.state.submit && this.state.formDataValeur.idCodifClasseFinale.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs position est obligatoire.</small>}
                </div>
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Règle * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataValeur.idCodifRegleUtilisee.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_regle_tarif}
                        onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'idCodifRegleUtilisee', 'fk', this.state.formDataValeur) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.idCodifRegleUtilisee })} />
                    {this.state.submit && this.state.formDataValeur.idCodifRegleUtilisee.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs règle utilisée est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Méthode * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <Dropdown autoWidth={false} value={this.state.formDataValeur.idCodifMethodeEval.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_methode_eval}
                        onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'idCodifMethodeEval', 'fk', this.state.formDataValeur) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.idCodifMethodeEval })} />
                    {this.state.submit && this.state.formDataValeur.idCodifMethodeEval.id == 0 && <small className="p-invalid" style={{ color: 'red' }}>Le champs méthode utilisée est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Valeur * </label>
                </div>
                <div className="field col-12 md:col-4">
                    <InputNumber value={this.state.formDataValeur.valeurFinale} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataValeur.valeurFinale })}
                        min={0} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'valeurFinale', '', this.state.formDataValeur) })} required style={{ width: '100%' }} />
                    {this.state.submit && !this.state.formDataValeur.valeurFinale && <small className="p-invalid" style={{ color: 'red' }}>Le montant est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Références service de décision *</label>
                </div>
                <div className="field col-12 md:col-4">
                    <InputTextarea rows={2} id="refServiceDecision" value={this.state.formDataValeur.refServiceDecision} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'refServiceDecision', '', this.state.formDataValeur) })} />
                </div>
                <div className="field col-12 md:col-2">
                    <label htmlFor="idOp">Autres références </label>
                </div>
                <div className="field col-12 md:col-4">
                    <InputTextarea rows={2} id="autresReferences" value={this.state.formDataValeur.autresReferences} onChange={(e) => this.setState({ formDataValeur: this.appservice.onInputChange(e, 'autresReferences', '', this.state.formDataValeur) })} />
                </div>


            </div>
        }
    }

    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des demandes */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Historique',
                icon: 'pi pi-list',
                command: (e) => {
                    this.setState({ selectedData: rowData, visible_dlg_historique_trt: true }, () => {
                        this.get_liste_trt();
                    });
                }
            },
        ]
        if (rowData.statut == 'En attente') {
            items.push(
                {
                    label: 'Ouvrir',
                    icon: 'pi pi-folder-open',
                    command: (e) => {
                        this.edit_data(rowData);
                        this.setState({ visible_dlg_form: true });
                    }
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_data(rowData);
                    }
                },
            )
            if (this.appservice.getProfil() == 'Back office' || this.appservice.getProfil() == 'Administrateur') {
                items.push(
                    {
                        label: 'Recevable',
                        icon: 'pi pi-thumbs-up',
                        command: (e) => {
                            this.setState({
                                selectedData: rowData,
                                formData: rowData,
                                submitTrt: false,
                                formDataTrt: {
                                    id: 0,
                                    date: null,
                                    libelle: "Recevabilité",
                                    commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_RECEVABILITE_DEMANDE').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date),
                                    idDem: rowData, //Clé étrangère de la table Demande
                                    idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                },
                                visible_dlg_trt: true
                            })
                        }
                    },
                    {
                        label: "Demande d'information",
                        icon: 'pi pi-bell',
                        command: (e) => {
                            this.setState({
                                selectedData: rowData,
                                formData: rowData,
                                submitTrt: false,
                                formDataTrt: {
                                    id: 0,
                                    date: null,
                                    libelle: "Demande d'information",
                                    commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_DEMANDE_INFO').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date),
                                    idDem: rowData, //Clé étrangère de la table Demande
                                    idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                },
                                visible_dlg_trt: true
                            })
                        }
                    },
                    {
                        label: 'Rejeter',
                        icon: 'pi pi-ban',
                        command: (e) => {
                            this.setState({
                                selectedData: rowData,
                                formData: rowData,
                                submitTrt: false,
                                formDataTrt: {
                                    id: 0,
                                    date: null,
                                    libelle: "Rejet",
                                    commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_REJET_DEMANDE').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date).replace("#MOTIF", rowData.motifDecision),
                                    idDem: rowData, //Clé étrangère de la table Demande
                                    idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                },
                                visible_dlg_trt: true
                            })
                        }
                    },
                )
            }
        } else if (rowData.statut == 'Recevable') {
            if (rowData.dateDecision !== null && rowData.dateDecision !== '') {
                if (this.appservice.getProfil() == 'Superviseur' || this.appservice.getProfil() == 'Administrateur') {
                    items.push(
                        {
                            label: 'Confirmer',
                            icon: 'pi pi-thumbs-up',
                            command: (e) => {
                                this.setState({
                                    selectedData: rowData,
                                    formData: rowData,
                                    submitTrt: false,
                                    formDataTrt: {
                                        id: 0,
                                        date: null,
                                        libelle: "Confirmation",
                                        commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_CONFIRMATION_DEMANDE').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date),
                                        idDem: rowData, //Clé étrangère de la table Demande
                                        idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                    },
                                    visible_dlg_trt: true
                                })
                            }
                        },
                    )
                }
            } else {
                if (this.appservice.getProfil() == 'Back office' || this.appservice.getProfil() == 'Administrateur') {
                    items.push(
                        {
                            label: 'Décider',
                            icon: 'pi pi-filter',
                            command: (e) => {
                                this.edit_data(rowData);
                                this.setState({
                                    selectedData: rowData,
                                    visible_dlg_decision: true
                                });
                            }
                        },
                    )
                }
            }
        } else if (rowData.statut == 'Confirmé') {
            if (this.appservice.getProfil() == 'Directeur' || this.appservice.getProfil() == 'Administrateur') {
                items.push(
                    {
                        label: 'Valider',
                        icon: 'pi pi-thumbs-up',
                        command: (e) => {
                            this.setState({
                                selectedData: rowData,
                                formData: rowData,
                                submitTrt: false,
                                formDataTrt: {
                                    id: 0,
                                    date: null,
                                    libelle: "Validation",
                                    commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_VALIDATION_DEMANDE').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date),
                                    idDem: rowData, //Clé étrangère de la table Demande  
                                    idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                },
                                visible_dlg_trt: true
                            })
                        }
                    },
                    {
                        label: 'Rejeter',
                        icon: 'pi pi-ban',
                        command: (e) => {
                            this.setState({
                                selectedData: rowData,
                                formData: rowData,
                                submitTrt: false,
                                formDataTrt: {
                                    id: 0,
                                    date: null,
                                    libelle: "Rejet",
                                    commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_REJET_DEMANDE').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date).replace("#MOTIF", rowData.motifDecision),
                                    idDem: rowData, //Clé étrangère de la table Demande
                                    idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                },
                                visible_dlg_trt: true
                            })
                        }
                    },
                )
            }
        } else if (rowData.statut == 'Validé') {
            items.push(
                {
                    label: 'Télécharger décision',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.setState({ selectedData: rowData });
                        this.print_decision(rowData);
                    }
                },
            )
            if (this.appservice.getProfil() == 'Directeur' || this.appservice.getProfil() == 'Administrateur') {
                items.push(
                    {
                        label: 'Suspendre',
                        icon: 'pi pi-ban',
                        command: (e) => {
                            this.setState({
                                selectedData: rowData,
                                formData: rowData,
                                submitTrt: false,
                                formDataTrt: {
                                    id: 0,
                                    date: null,
                                    libelle: "Suspension",
                                    commentaire: this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_SUSPENSION_DECISION').replace("#NO", rowData.numEnreg).replace("#DATE", rowData.date).replace("#MOTIF", rowData.motifDecision).replace("#DECISION", rowData.numDecision),
                                    idDem: rowData, //Clé étrangère de la table Demande
                                    idUser: { id: this.appservice.get_userconnected().id } //Clé étrangère de la table Utilisateur
                                },
                                visible_dlg_trt: true
                            })
                        }
                    },
                )
            }
        }

        if ((rowData.statut == 'En attente' || rowData.statut == 'Recevable') && (this.appservice.getProfil() == 'Guest') && (rowData.feedBackWaiting == true)) {
            items.push(
                {
                    label: 'Joindre documents',
                    icon: 'pi pi-upload',
                    command: (e) => {
                        this.edit_data(rowData);
                        this.setState({ visible_dlg_upload: true });
                    }
                }
            )
        }

        return <SplitButton label="Ouvrir" icon="pi pi-folder-open" onClick={(e) => {
            this.edit_data(rowData);
            this.setState({ visible_dlg_form: true });
        }} model={items} />
    }

    actionTemplate_new_ligne(rowData, props) {
        let disable = false;
        if (this.state.modif_data == true && this.appservice.getProfil() == 'Guest') {
            if (rowData.newDoc == false) {
                disable = true;
            }
        }
        return <Button disabled={disable} onClick={() => this.conf_delete_new_ligne(rowData)} className="p-button-danger" style={{ width: 30, height: 30 }} icon="pi pi-times" />
    }

    actionTemplate_new_matiere(rowData, props) {
        return <Button onClick={() => this.conf_delete_new_matiere(rowData)} className="p-button-danger" style={{ width: 30, height: 30 }} icon="pi pi-times" />
    }

    upload_doc(data, rowIndex) {
        this.setState({ selectedDoc: data, rowIndex: rowIndex }, () => {
            document.getElementById("hiddenFile").click();
        })
    }

    /* Colonne Action du tableau contenant la liste des codifications */
    actionTemplateJoindreDoc(rowData, props) {
        let disable = false;
        if (this.state.modif_data == true && this.appservice.getProfil() == 'Guest') {
            if (rowData.newDoc == false) {
                disable = true;
            }
        }
        let items = [
            {
                label: 'Joindre document',
                icon: 'pi pi-upload',
                command: (e) => {
                    this.setState({ selectedDoc: rowData });
                    this.upload_doc(rowData, props.rowIndex);
                }
            },
        ]

        if (rowData.newDoc == false && rowData.ficDoc !== '') {
            items.push(
                {
                    label: 'Télécharger le document',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.setState({ selectedDoc: rowData });
                        this.appservice.download_fichier_joint(rowData.ficDoc);
                    }
                },
            )
        }


        return <SplitButton style={{ width: 110 }} disabled={disable} className={rowData.file !== null ? 'p-button-success' : 'p-button-secondary'} label="Joindre" icon="pi pi-upload" onClick={(e) => this.upload_doc(rowData, props.rowIndex)} model={items} />
    }

    /* Fonction de suppression de la demande sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.appservice.delete_demande(this.state.selectedData).then(reponse => {
                this.setState({ showindicator: false });
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }


    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div style={{ minHeight: 570 }}>
                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />
                        {this.state.visible_dlg_form == false ?
                            <>
                                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                                <Toolbar className="p-mb-4" style={{ paddingTop: 2, paddingBottom: 2 }} left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                                <br />

                                {/* Tableau contenant la liste des demandes existantes dans la base */}
                                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                                    paginator rows={10} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers tdLessPadding" showGridlines
                                    selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                                    emptyMessage="No data found" selectionMode="single"
                                    onRowClick={e => this.setState({ selectedData: e.data }, () => {
                                    })}>
                                    <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                                    <Column field="numEnreg" header="Numero" style={{ width: '8%' }} />
                                    <Column field="date" header="Date" style={{ width: '8%' }} sortable />
                                    <Column field="type" header="Type" style={{ width: '6%' }} sortable />
                                    <Column field="statut" body={(rowData, column) => {
                                        let cl = 'p-badge-secondary';
                                        if (rowData.statut == 'Recevable') {
                                            cl = 'p-badge-warning';
                                        } else if (rowData.statut == 'Rejeté') {
                                            cl = 'p-badge-danger';
                                        } else if (rowData.statut == 'Suspendu') {
                                            cl = 'p-badge-suspendu';
                                        } else if (rowData.statut == 'Confirmé') {
                                            cl = 'p-badge-info';
                                        } else if (rowData.statut == 'Validé') {
                                            cl = 'p-badge-success';
                                        }
                                        return <div>
                                            <Badge value={rowData.statut} className={cl} style={{ fontSize: 14 }} />
                                        </div>
                                    }} header="Statut" style={{ width: '8%' }} sortable />
                                    <Column field="numDecision" header="N° décision" style={{ width: '8%' }} />
                                    <Column field="idOp.nom" header="Requérant" />
                                    <Column field="idOp.email" header="Email" style={{ width: '15%' }} />
                                    <Column field="idOp.telephone" header="Telephone" style={{ width: '8%' }} />
                                </DataTable>
                            </>
                            :
                            <>
                                {this.get_form_demande()}
                                <center>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 150 }} onClick={(e) => this.annuler()} />
                                    <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 150, marginLeft: 10 }} />
                                </center>
                            </>
                        }

                        {/* Boite de dialogue de l'affichage de l'historique */}
                        <Dialog visible={this.state.visible_dlg_historique_trt} style={{ width: '70%' }} header="Historique" modal
                            onHide={(e) => this.setState({ visible_dlg_historique_trt: false })}>
                            <div className="flex align-items-center justify-content-center">
                                {/* Tableau contenant la liste des traitements existantes dans la base */}
                                <DataTable ref={(el) => { this.dtTrt = el; }} value={this.state.liste_trt}
                                    rows={10} paginator={true} showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    selection={this.state.selectedTrt} onRowSelect={e => this.setState({ selectedTrt: e.data })}
                                    emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={true}
                                    onRowClick={e => this.setState({ selectedTrt: e.data }, () => {
                                    })}>
                                    <Column field="date" header="Date" style={{ width: '8%' }} />
                                    <Column field="libelle" header="Action" style={{ width: '12%' }} />
                                    <Column field="idUser.nom" header="Utilisateur" style={{ width: '17%' }} />
                                    <Column field="commentaire" header="Commentaire" />
                                </DataTable>
                            </div>
                        </Dialog>


                        {/* Boite de dialogue de décision */}
                        <Dialog visible={this.state.visible_dlg_decision} style={{ width: '80%' }} header="Décision" modal
                            footer={
                                <>
                                    <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_decision: false });
                                    }} style={{ width: 150 }} />
                                    <Button label="Valider" icon="pi pi-check" className="p-button-success" onClick={() => {
                                        this.enreg_decision();
                                    }} loading={this.state.showindicator} style={{ width: 150 }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_decision: false })}>
                            <div className="flex align-items-center justify-content-center">
                                {this.appservice.getProfil() !== 'Guest' ?
                                    this.get_form_decision() : null}
                            </div>
                        </Dialog>


                        {/* Boite de dialogue de choix du type de demande */}
                        <Dialog visible={this.state.visible_dlg_choix_type} style={{ width: '650px' }} header="Choisir le type" modal
                            footer={
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                    this.setState({ visible_dlg_choix_type: false });
                                }} style={{ width: 120 }} />
                            } onHide={(e) => this.setState({ visible_dlg_choix_type: false })}>

                            <div className="row justify-content-center" style={{ marginTop: 10, marginBottom: -20 }}>

                                <div className="col-sm-6 col-lg-4">

                                    <div className="service-item" onClick={(e) => {
                                        let form = this.state.formData;
                                        form.type = 'Tarif';

                                        this.setState({ formData: form, visible_dlg_choix_type: false }, () => {
                                            this.toogleDlg();
                                        });

                                    }} style={{ cursor: 'pointer', padding: 20 }}>
                                        <div className="service-front">
                                            <i className="icofont-cube" ></i>
                                            <h3>Tarif</h3>
                                            <p>
                                                Enregistrer une nouvelle demande de tarif
                                            </p>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-6 col-lg-4">
                                    <div className="service-item" onClick={(e) => {
                                        let form = this.state.formData;
                                        form.type = 'Origine';


                                        this.setState({ formData: form, visible_dlg_choix_type: false }, () => {
                                            this.toogleDlg();
                                        });
                                    }} style={{ cursor: 'pointer', padding: 20 }}>
                                        <div className="service-front">
                                            <i className="icofont-flag"></i>
                                            <h3>Origine</h3>
                                            <p>
                                                Enregistrer une nouvelle demande d'origine
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-4">
                                    <div className="service-item" onClick={(e) => {
                                        let form = this.state.formData;
                                        form.type = 'Valeur';
                                        this.setState({ formData: form, visible_dlg_choix_type: false }, () => {
                                            this.toogleDlg();
                                        });
                                    }} style={{ cursor: 'pointer', padding: 20 }}>
                                        <div className="service-front">
                                            <i className="icofont-money-bag" ></i>
                                            <h3>Valeur</h3>
                                            <p>
                                                Enregistrer une nouvelle demande de valeur
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </Dialog>


                        {/* Boite de dialogue permettant de joindre des documents */}
                        <Dialog visible={this.state.visible_dlg_upload} style={{ width: '550px' }} header="Joindre des documents" modal
                            footer={
                                <>
                                    <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_upload: false });
                                    }} style={{ width: 150 }} />
                                    <Button label="Valider" icon="pi pi-check" onClick={() => {
                                        let form = this.state.formData;
                                        form.feedBackWaiting = false;
                                        this.setState({ formData: form }, () => {
                                            this.enreg_data();
                                        })
                                    }} style={{ width: 150 }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_upload: false })}>
                            <div className="p-fluid">
                                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        let file = e.target.files[0];
                                        let updateds = this.state.liste_document;
                                        updateds[this.state.rowIndex].file = file;
                                        let name = e.target.files[0].name;
                                        let nomfic = 'PT_' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                                        updateds[this.state.rowIndex].ficDoc = nomfic;
                                        const myNewFile = new File(
                                            [file],
                                            nomfic,
                                            { type: file.type }
                                        );
                                        updateds[this.state.rowIndex].file = myNewFile;
                                        this.setState({ liste_document: updateds });
                                    }
                                }} />
                                <DataTable ref={(el) => { this.dtDOC = el; }} value={this.state.liste_document} paginator rows={10} alwaysShowPaginator={false}
                                    onSelectionChange={e => this.setState({ selectedDoc: e.value })} selection={this.state.selectedDoc} style={{ width: '100%' }}
                                    responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    footer={
                                        <center>
                                            <Button icon='pi pi-plus' label='Ajouter un nouveau document' onClick={(e) => {
                                                let liste = this.state.liste_document;
                                                liste.push(
                                                    { id: this.state.liste_document.length + 1, descDoc: '', ficDoc: null, idCodifType: { id: 0, value: 0, label: '' }, idDem: { id: 0 }, file: null, newDoc: true, iduser: this.appservice.get_userconnected().id },
                                                );
                                                this.setState({ liste_document: liste });
                                            }} style={{ width: 250 }} />
                                        </center>
                                    }>
                                    <Column header='#' body={this.actionTemplate_new_ligne.bind(this)} style={{ width: '3%' }} />
                                    <Column field="idCodifType.id" header="Pièce" body={(rowData, props) => {
                                        return <Dropdown placeholder='Sélectionner' options={this.state.liste_type_doc} value={rowData.idCodifType.id} autoWidth={false} style={{ width: '170px' }}
                                            filter={true} filterBy='label' filterMatchMode='contains' />
                                    }} editor={(options) => this.docEditor(options)} onCellEditComplete={this.onCellEditComplete} style={{ width: '20%' }} />
                                    <Column field="descDoc" body={(rowData, props) => {
                                        return <InputText placeholder='Saisir la description du document' value={rowData.descDoc} style={{ width: '100%' }} />
                                    }} header='Description' filter={true} filterMatchMode='contains' editor={(options) => this.descEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                    <Column header='Fichier' body={this.actionTemplateJoindreDoc.bind(this)} style={{ width: '8%' }} />
                                </DataTable>

                            </div>
                        </Dialog>


                        {/* Boite de dialogue de confirmation de rejet */}
                        <Dialog visible={this.state.visible_dlg_trt} style={{ width: '450px' }} header={this.state.formDataTrt.libelle} modal
                            footer={
                                <>
                                    <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 150 }} onClick={(e) => this.annuler_trt()} />
                                    <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_trt()} style={{ width: 150, marginLeft: 10 }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_trt: false })}>
                            <div className="p-fluid">
                                {this.state.formDataTrt.libelle == 'Rejet' || this.state.formDataTrt.libelle == 'Suspension' ?
                                    <>
                                        {this.state.formDataTrt.libelle == 'Rejet' ?
                                            <div className="field">
                                                <label htmlFor="libelle">Motif décision *</label>
                                                <InputTextarea rows={3} id="motif" value={this.state.formData.motifDecision} className={classNames({ 'p-invalid': this.state.submitTrt && !this.state.formData.motifDecision })}
                                                    onChange={(e) => {
                                                        this.setState({ formDataTrt: this.appservice.onInputChange(e, 'motifDecision', '', this.state.formData) });
                                                        let formTrt = this.state.formDataTrt;
                                                        formTrt.commentaire = this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_REJET_DEMANDE').replace("#NO", this.state.selectedData.numEnreg).replace("#DATE", this.state.selectedData.date).replace("#MOTIF", e.target.value);
                                                        this.setState({ formDataTrt: formTrt });
                                                    }} required autoFocus />
                                                {this.state.submitTrt && !this.state.formData.motifDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs motif est obligatoire.</small>}
                                            </div>
                                            :
                                            <div className="field">
                                                <label htmlFor="libelle">Motif suspension *</label>
                                                <InputTextarea rows={3} id="motif" value={this.state.formData.motifDecision} className={classNames({ 'p-invalid': this.state.submitTrt && !this.state.formData.motifDecision })}
                                                    onChange={(e) => {
                                                        this.setState({ formDataTrt: this.appservice.onInputChange(e, 'motifDecision', '', this.state.formData) });
                                                        let formTrt = this.state.formDataTrt;
                                                        formTrt.commentaire = this.appservice.getSelectedParam(this.state.liste_param, 'NOTIF_SUSPENSION_DECISION').replace("#NO", this.state.selectedData.numEnreg).replace("#DATE", this.state.selectedData.date).replace("#MOTIF", e.target.value).replace("#DECISION", this.state.selectedData.numDecision)
                                                        this.setState({ formDataTrt: formTrt });
                                                    }} required autoFocus />
                                                {this.state.submitTrt && !this.state.formData.motifDecision && <small className="p-invalid" style={{ color: 'red' }}>Le champs motif est obligatoire.</small>}
                                            </div>
                                        }

                                    </>

                                    : null}
                                <div className="field">
                                    <label htmlFor="libelle">Commentaire *</label>
                                    <InputTextarea rows={7} id="commentaire" value={this.state.formDataTrt.commentaire} className={classNames({ 'p-invalid': this.state.submitTrt && !this.state.formDataTrt.commentaire })}
                                        onChange={(e) => this.setState({ formDataTrt: this.appservice.onInputChange(e, 'commentaire', '', this.state.formDataTrt) })} required />
                                    {this.state.submitTrt && !this.state.formDataTrt.commentaire && <small className="p-invalid" style={{ color: 'red' }}>Le champs commentaire est obligatoire.</small>}
                                </div>
                            </div>

                        </Dialog>


                        {/* Boite de dialogue permettant d'affiche le contenu de l'aide */}
                        <Dialog header="Aide" dismissableMask visible={this.state.visible_dlg_help} contentStyle={{ paddingBottom: 20 }} style={{ width: '50vw' }} onHide={() => this.setState({ visible_dlg_help: false })} >
                            <span style={{ fontWeight: 800 }}>{this.state.helpField}</span>
                            <p className="m-0">
                                {this.state.helpContent}
                            </p>
                        </Dialog>

                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer la demande N° <b>{this.state.selectedData.numEnreg}</b> ?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div >
        );
    }
}
