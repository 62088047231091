import React from 'react';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';

export const Access = () => {

	const history = useHistory();

	const goDashboard = () => {
		history.push('/');
	}


	return <div className="exception-body access-body">
		<div className="formgrid grid">
			<div className="field col-12 md:col-6">
				<label for="firstname2">Firstname</label>
				<InputText id="firstname2"  />
			</div>
			<div className="field col-12 md:col-6">
				<label for="lastname2">Lastname</label>
				<InputText id="lastname2" />
			</div> 
		</div>
		<div className="exception-container">
			<img src="assets/layout/images/logo-dark.png" alt="apollo-layout" />
			<h1>Denied</h1>
			<p>
				You do not have the necessary privileges.
			</p>

			<Button label="Go To Dashboard" icon="pi pi-arrow-left" onClick={goDashboard} />
		</div>
	</div>
}