import { Button } from "primereact/button";
import React from "react";

const Services = () => {
  return (
    <>
      <div className="services-area pb-70" style={{marginTop: -50}} >
        <div className="container">
          <div className="section-title">
            <h2>Faire une demande anticipée</h2>
          </div>
 
          <div className="row justify-content-center">

            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <div className="service-front">
                  <i className="icofont-cube" ></i>
                  <a href="/service-details" style={{color: '#000'}}>
                    <h3>Classement tarifiaire</h3>
                  </a>
                  <Button icon='pi pi-pencil' label="Faire la demande" className="p-button-success"/>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <div className="service-front">
                  <i className="icofont-flag"></i>
                  <a href="/service-details" style={{color: '#000'}}>
                    <h3>Origine</h3>
                  </a>
                  <Button icon='pi pi-pencil' label="Faire la demande" className="p-button-success"/>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="service-item">
                <div className="service-front">
                  <i className="icofont-money-bag"></i>
                  <a href="/service-details" style={{color: '#000'}}>
                    <h3>Valeur</h3>
                  </a>
                  <Button icon='pi pi-pencil' label="Faire la demande" className="p-button-success"/>
                </div>
              </div>
            </div>

          </div>



        </div>
      </div>
    </>
  );
};

export default Services;
