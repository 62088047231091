import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

/* Page de gestion des Params */
export default class FormParam extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [],
            formData: {
                id: 0,
                label: "",
                value: "",
                typeValue: "Chaine de caractère",
                code: "",
            },
            liste_type: [
                { label: 'Nombre', value: 'Nombre' },
                { label: 'Chaine de caractère', value: 'Chaine de caractère' },
                { label: 'Date', value: 'Date' },
                { label: 'Image', value: 'Image' },
            ],
            file: null,
            urlfile: null,
            showindicator: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);

        this.appservice = new AppServices();
    }

    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }


    /* Récupération de la liste globale des opérateurs */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_param().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                label: "",
                value: "",
                typeValue: "Chaine de caractère",
                code: "",
                file: null,
                urlfile: null,
            }
        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }

    /* Uploader un fichier sur le serveur */
    fileUpload(file, namefile) {
        this.appservice.upload_file(file, namefile, 'etats').then(data => {
            if (data.code == 200) {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
            if (data.typeValue == 'Image' !== null && data.value !== '') {
                this.setState({ urlfile: this.appservice.download_file(data.value, 'etats') })
            } else {
                this.setState({ urlfile: null })
            }
        }
    }

    /* Affichage du bon composant selon le type du paramètre */
    get_value_compoment() {
        if (this.state.formData.typeValue == 'Chaine de caractère') {
            return <InputTextarea rows={5} id="name1" value={this.state.formData.value} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.value })}
                onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'value', '', this.state.formData) })}
                required />
        } else if (this.state.formData.typeValue == 'Date') {
            return <InputMask mask="99/99/9999" id="name1" value={this.state.formData.value} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.value })}
                onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'value', '', this.state.formData) })}
                required />
        } else if (this.state.formData.typeValue == 'Nombre') {
            return <InputText keyfilter='pnum' id="name1" value={this.state.formData.value} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.value })}
                onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'value', '', this.state.formData) })}
                required />
        } else if (this.state.formData.typeValue == 'Image') {
            return <div className="field" style={{ marginBottom: -10 }}>
                <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                    <div style={{ height: 150, width: 300, borderRadius: 10 }} >
                        <center>
                            {this.state.urlfile == null ?
                                <div style={{ height: '150px', width: '300px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                    <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                                </label></div> :
                                <img src={this.state.urlfile} style={{ height: '150px', width: '300px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                        </center>
                    </div>
                </a>
                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        this.setState({ file: e.target.files[0] }, () => {
                            let url = URL.createObjectURL(this.state.file);
                            this.setState({ urlfile: url });
                        })
                    }
                }} />
            </div>
        }
    }

    /* Enregistrer les informations du formulaire param */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.label == "" || this.state.formData.typeValue == "" || this.state.formData.code == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else if (this.state.formData.typeValue !== "Image" && this.state.formData.value == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner le champs value' });
        } else {
            if (this.state.formData.typeValue == 'Image' && this.state.modif_data == false && this.state.file == null) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une image' });
                return;
            }
            let nomfic = this.state.formData.value;
            let upload = false;
            let form = this.state.formData;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'p_' + this.state.formData.code.replace(" ", "").toLowerCase() + "_" + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
                form.value = nomfic;
            }
            this.appservice.save_param(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.get_liste_data();
                    }
                }
            });
        }
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'un nouveau opérateur */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )
    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des classes en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("LISTE_PARAM");
    }

    /* Ouvrir la boite de dialogue de sélection de l'image */
    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des codifications */
    actionTemplate(rowData, column) {
        return <Button icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} style={{ backgroundColor: 'transparent', color: '#0c42c0', border: 0 }} />;
    }

    /* Fonction de suppression de la dvaleur sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_param(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }


    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 570 }}>
                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <br />



                        {/* Tableau contenant la liste des notifications existantes dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data}
                            dataKey='id' paginator rows={30} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" showGridlines
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single"
                            filterDisplay="row" alwaysShowPaginator={false}
                            onRowClick={e => this.setState({ selectedData: e.data })}>
                            <Column body={this.actionTemplate.bind(this)} style={{ width: '5%' }} />
                            {/* <Column field="code" style={{ width: '10%' }} />  */}
                            <Column field="label" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                            <Column field="value" body={(rowData, props) => {
                                if (rowData.typeValue == 'Image' && rowData.value !== '' && rowData.value !== undefined) {
                                    let fic = rowData.value;
                                    return <img src={this.appservice.download_file(fic, 'etats')} alt='Photo' style={{ width: 320, height: 150 }} />
                                } else {
                                    return <div>
                                        <span >{rowData.value}</span>
                                    </div>
                                }
                            }}
                                filter={true} filterMatchMode='contains' />
                        </DataTable>

                        {/* Boite de dialogue d'ajout et de modification des Opérateurs  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>

                            <div className="p-fluid grid formgrid">
                                <div className="field col-12 md:col-3">
                                    <label htmlFor="label">Code *</label>
                                </div>
                                <div className="field col-12 md:col-9">
                                    <InputText id="label" value={this.state.formData.code} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.code })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'code', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.code && <small className="p-invalid" style={{ color: 'red' }}>Le champs code est obligatoire.</small>}
                                </div>
                                <div className="field col-12 md:col-3">
                                    <label htmlFor="typeValue">Type *</label>
                                </div>
                                <div className="field col-12 md:col-9">
                                    <Dropdown autoWidth={false} value={this.state.formData.typeValue} filter={true} filterBy='label' filterMatchMode='contains'
                                        options={this.state.liste_type}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'typeValue', '', this.state.formData) })}
                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.typeValue })} />
                                    {this.state.submit && !this.state.formData.typeValue && <small className="p-invalid" style={{ color: 'red' }}>Le champs typeValue est obligatoire.</small>}
                                </div>
                                <div className="field col-12 md:col-3">
                                    <label htmlFor="label">Titre  *</label>
                                </div>
                                <div className="field col-12 md:col-9">
                                    <InputText id="label" value={this.state.formData.label} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.label })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'label', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.label && <small className="p-invalid" style={{ color: 'red' }}>Le champs label est obligatoire.</small>}
                                </div>

                                <div className="field col-12 md:col-3">
                                    <label htmlFor="value">Valeur *</label>
                                </div>
                                <div className="field col-12 md:col-9">
                                    {this.get_value_compoment()}
                                    {this.state.submit && !this.state.formData.value && this.state.formData.typeValue !== "Image" && <small className="p-invalid" style={{ color: 'red' }}>Le champs value est obligatoire.</small>}
                                </div>


                            </div>
                        </Dialog>
                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedData.libelle}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div >
        );
    }
}
