import React, { useEffect } from 'react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import App from "./App";
import { Login } from "./pages/Login";
import { Error } from "./pages/Error";
import { NotFound } from "./pages/NotFound";
import { Access } from "./pages/Access";
import { Home } from "./pages/Home";
import { Register } from "./pages/Register";

const AppWrapper = (props) => {
	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);


	switch (props.location.pathname) {
		case "/login":
			return <Route path="/login" component={Login} />
		case "/register":
			return <Route path="/register" component={Register} />
		case "/error":
			return <Route path="/error" component={Error} />
		case "/notfound":
			return <Route path="/notfound" component={NotFound} />
		case "/access":
			return <Route path="/access" component={Access} />

		default:
			let key = localStorage.getItem('userconnected');
			if (key == null || key == undefined || key == 'undefined' || key == '') {
				window.location = "#/home";
				return <Route path="/home" component={Home} />
			} else {
				let user = JSON.parse(key);
				if (user == null && user == undefined) {
					window.location = "#/home";
				}
				return <App />;
			}
	}

}

export default withRouter(AppWrapper);