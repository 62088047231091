import React from "react";
import TopHeader from "../components/widgets/TopHeader";
import HeroSlider from "../components/widgets/HeroSlider";
import Stats from "../components/widgets/Stats";
import Footer from "../components/widgets/Footer";
import AboutSection from "../components/widgets/AboutSection";
import Services from "../components/widgets/Services";

export const Home = () => {
  return (
    <>
      <TopHeader />

      {/* <Navbar /> */}

      <HeroSlider />

      <Stats />

      <AboutSection />

      <Services />

      <Footer />
    </>
  );
};

