import React from "react";

const AboutSection = () => {
  return ( 
    <>
      <div className="about-area pt-50 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-item">
                <div className="about-left">
                  <img src="assets/layout/images/about1.jpg" alt="About" />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-item about-right">
                {/* <img src="assets/layout/images/about-shape1.png" alt="About" /> */}
                <h2>Mot du DG</h2>
                <p style={{fontSize: 16}}>
                Devant le flux de commerce international accru, nous avons déployés des mécanismes 
                de facilitation et de transparence pour aider les opérateurs économiques à déposer 
                des demandes anticipées de classement tarifaire , d’origine et de détermination 
                de la valeur  des produits à importer ou à exporter pour:{" "}
                </p>

                <ul>
                  <li>
                    <i className="icofont-check-circled"></i>
                    Avoir une visibilité précise avant de s’engager dans une opération d’importation ou d’exportation 
                  </li>
                  <li>
                    <i className="icofont-check-circled"></i>
                    Déclarer en toute sécurité les marchandises  importées en ayant défini au préalable la position tarifaire, l’origine et   la valeur en douane 3 éléments clés pour détermination de la fiscalité douanière
                  </li>
                  <li>
                    <i className="icofont-check-circled"></i>
                    Les décisions anticipées relatives à la détermination du classement tarifaire ou à l’origine ou la valeur d’un produit, engagent la responsabilité de l’administration des douanes et l’opérateur de commerce extérieur, sauf incompatibilité avec le produit importé
                  </li>
                  
                </ul>

                <a href="/about">En savoir plus ...</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
