import React from 'react';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { Badge } from 'primereact/badge';
import AppServices from './service/AppServices';

const AppTopbar = (props) => {

    const appservice = new AppServices();
    var userconnected = appservice.get_userconnected();
    var nom_user = '';
    var profil = '';
    var login = '';
    var nb = appservice.get_nb_notifications() !== "" && appservice.get_nb_notifications() !== null && appservice.get_nb_notifications() !== undefined ? appservice.get_nb_notifications().toString() : "0";

    if (userconnected !== null && userconnected !== undefined) {
        nom_user = userconnected.nom;
        profil = userconnected.profil;
        login = userconnected.login;
    }

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    };

    let topbarItemsClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });

    return (
        <div className="topbar clearfix">

            <div className="logo-link">
                <img className="logo" alt="apollo-layout" src="assets/layout/images/logo.png" />
            </div>

            <button type="button" className="p-link menu-button" id="menu-button" onClick={props.onMenuButtonClick}>
                <i className="pi pi-align-left"></i>
            </button>



            <button type="button" className="p-link profile" onClick={props.onTopbarMenuButtonClick}>
                <span className="username">{nom_user}</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" />
                <i className="pi pi-angle-down"></i>
            </button>


            <span className="p-link profile" style={{ padding: 8 }}>
                <button type="button" className="p-link" onClick={(e) => {
                    window.location = "#/notifications";
                    window.location.reload();

                }}>
                    <i className="pi pi-envelope mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '2rem' }}><Badge severity="danger" value={nb} ></Badge></i>
                </button>
            </span>

            <ul className={topbarItemsClassName}>
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'profile' })}
                    onClick={(e) => onTopbarItemClick(e, 'profile')}>
                    <button type="button" className="p-link">
                        <i className="topbar-icon pi pi-fw pi-user"></i>
                        <span className="topbar-item-name">Mon profile</span>
                    </button>

                    <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                        <ul>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-user-edit"></i>
                                    <span>{profil}</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" onClick={(e) => {
                                    window.location = "#/chpwd";
                                    window.location.reload();
                                }} className="p-link">
                                    <i className="pi pi-fw pi-eye"></i>
                                    <span>Changer mot de passe</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </li>


                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'notifications' })}
                    onClick={(e) => onTopbarItemClick(e, 'notifications')}>
                    <button type="button" onClick={(e) => {
                        window.location = "#/notifications";
                    }} className="p-link">
                        <i className="topbar-icon pi pi-fw pi-bell"></i>
                        <span className="topbar-item-name">Notifications</span>
                        <span className="topbar-badge">20</span>
                    </button>
                </li>

                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'logout' })}
                    onClick={(e) => onTopbarItemClick(e, 'logout')}>
                    <button type="button" className="p-link" onClick={(e) => {
                        localStorage.removeItem('userconnected');
                        window.location = "#/login";
                    }}>
                        <i className="topbar-icon pi pi-fw pi-sign-out"></i>
                        <span className="topbar-item-name">Déconnexion</span>
                    </button>
                </li>
            </ul>
        </div>
    );

}

export default AppTopbar;
