import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import AppServices from '../service/AppServices';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Password } from 'primereact/password';

export class Login extends Component {
	constructor() {
		super();
		this.state = {
			showindicator: false,
			login: '',
			pwd: '',
			new_pwd: '',
			msg: '',
			changerPwd: false,
			user: null,
			token: ''
		};
		this.appservice = new AppServices();
	}

	handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.identification();
		}
	}


	identification() {
		if (this.state.login == '' || this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
		} else {
			this.setState({ showindicator: true });
			this.appservice.get_identification(this.state.login, this.state.pwd).then(data => {
				this.setState({ showindicator: false });
				if (data !== null && data != undefined && data !== '') {
					if (data.statut === 'Actif') {
						if (data.changerPwd == false) {
							localStorage.setItem('userconnected', JSON.stringify(data));
							localStorage.setItem('token', data.token);
							this.appservice.get_nb_msg(data.token).then(nb => {
								localStorage.setItem('nb_msg', nb);
							});
							window.location = "#/dashboard";
						} else {
							this.setState({
								user: data,
								changerPwd: true,
								pwd: '',
								new_pwd: '',
								msg: 'Vous devez changer ce mot de passe',
								token: data.token
							})
						}
					} else {
						this.setState({ showindicator: false });
						this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
					}
				} else {
					this.setState({ showindicator: false });
					this.setState({ msg: "Login ou mot de passe incorrect !" });
				}
			});
		}
	}

	changerPwd() {
		if (this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le nouveau mot de passe' });
		} else if (this.state.new_pwd == '') {
			this.setState({ msg: 'Veuilllez confirmer le nouveau mot de passe' });
		} else if (this.state.new_pwd !== this.state.pwd) {
			this.setState({
				msg: "Les 2 mots de passe ne correspondent pas",
				pwd: '',
				new_pwd: ''
			});
		} else if (this.state.new_pwd == this.state.user.password) {
			this.setState({ msg: "Le nouveau mot de passe ne peut être identique à l'ancien" });
		} else {
			let data = this.state.user;
			data.changerPwd = false;
			data.pwd = this.state.new_pwd;
			this.appservice.update_password(data, this.state.token).then(reponse => {
				if (reponse !== undefined && reponse !== null) {
					if (reponse.code == 200) {
						this.identification();
					}
				}
			});
		}
	}

	render() {
		return (
			<div className="login-body">
				<div className="body-container">
					<div className="grid">
						<div className="col-12 lg:col-6 left-side">
							<img onClick={(e) => window.location = "/"} src="assets/layout/images/logo-dark.png" style={{ width: '200px', cursor: 'pointer' }} alt="apollo-layout" className="logo" />
							<h1>Bienvenue</h1>
							<p>
								Système de gestion des demandes anticipées en matière de classement tarifaire, origine et valeur
							</p>
						</div>
						<div className="col-12 lg:col-6 right-side">
							<div className="login-wrapper">
								<div className="login-container">
									<span className="title">{this.state.changerPwd == false ? 'Identifiez-vous' : 'Changement de mot de passe'}</span>

									<div className="grid p-fluid">
										<div className="col-12">
											<InputText placeholder="Login" disabled={this.state.changerPwd} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} value={this.state.login} />
										</div>
										<div className="col-12">
											{this.state.changerPwd == true ?
												<Password placeholder="Nouveau mot de passe" onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} />
												:
												<InputText type="password" placeholder="Mot de passe" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} />
											}
										</div>
										{this.state.changerPwd == true ?
											<div className="col-12">
												<Password placeholder="Confirmez le nouveau mot de passe" onChange={(e) => this.setState({ new_pwd: e.target.value })} value={this.state.new_pwd} />
											</div>
											: null}

										<div className="col-6" style={{ flex: 1, justifyItems: 'center' }}>
											{this.state.changerPwd == false ?
												<Button label="Se connecter" icon="pi pi-sign-in" onClick={(e) => this.identification()} style={{ borderRadius: 20, width: 200 }} />
												:
												<>
													<div className="grid" >
														<div className="col-6"><Button label="Retour" icon="pi pi-ban" onClick={(e) => { this.setState({ changerPwd: false }) }} className="p-button-secondary" style={{ width: 120, borderRadius: 20 }} /></div>
														<div className="col-6"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.changerPwd()} className="p-button-success" style={{ width: 120, marginLeft: 60, borderRadius: 20 }} /></div>
													</div>
												</>

											}
											<br />
											{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
											<label style={{ color: 'red', marginTop: 8 }} >{this.state.msg}</label>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}