import Numeral from 'numeral'; //Composant de Formatage des nombre en montant
import 'moment';    //Composant de manipulation des dates
import 'moment/locale/fr';
import moment from 'moment';
import jsPDF from 'jspdf'
import $ from 'jquery';
import QRCode from 'qrcode';

export default class AppServices {
    constructor() {
        this.state = {
            //URL de base
            wsurl: 'http://localhost:8798/dactov',
            //wsurl: 'http://192.168.4.138/ws_dactov/dactov',
            //wsurl: 'http://192.168.4.158:8070/ws_dactov/dactov',
            //wsurl: 'http://41.77.184.36:8070/ws_dactov/dactov',
            //Utilisateur connecté
            token: localStorage.getItem('token'),
            nbMsg: localStorage.getItem('nb_msg'),
            //userconnected: {id:1, login: 'issouff', profil: 'Administrateur'}
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
    }

    validatePassword(pw) {
        return /[A-Z]/.test(pw) &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[^A-Za-z0-9]/.test(pw) &&
            pw.length > 4;
    }

    get_key() {
        let length = 3;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result.toUpperCase();
    }

    get_ref() {
        let length = 2;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result.toUpperCase();
    }


    disconnect() {
        localStorage.removeItem('userconnected');
        localStorage.removeItem('token');
        window.location = "#/login";
    }

    get_nb_notifications() {
        return this.state.nbMsg;
    }

    get_liste_last_connexion(liste_action) {
        let liste = [];
        if (liste_action.length > 0) {
            liste = liste_action.map(x => {
                return x.date
            });
        }
    }

    // Retourne une liste de couleurs
    get_liste_colors() {
        return [
            "#0673c7",
            "#f07d82",
            "#0d5943",
            "#2c87b8",
            "#FF6384",
            "#36A2EB",
            "#4071a7",
            "#fcba03",
            "#a6a5a2",
            "#9cb090",
            "#52ba9b",
            "#06c1cf",
            "#275996",
            "#ff7900",
            "#003366",
            "#295380",
            "#d87a3c",
            "#36A2Eh",
            "#FFCE56",
            "#293780",
            "#086b54",
            "#FFC107",
            "#2ECC71",
        ];
    }

    // Permet de vérifier si une adresse email est correcte
    validateEmail(value) {
        return String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) != null;
    };

    // Permet de vérifier si un numéro de téléphone est correcte
    validatePhoneNumber(value) {
        return !isNaN(value) && value.length == 9 && value[0] == '6' && (function (x) { return (x | 0) === x; })(parseFloat(value))
    };


    //Retourne l'url de base du serveur
    wsurl() {
        return this.state.wsurl;
    }

    //Permet de mettre des séparateur pour les montants
    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    //Retourne l'utilisateur connecté
    get_userconnected() {
        return this.state.userconnected;
    }

    //Permet de retourner le tocken en cours
    getToken() {
        return this.state.token;
    }

    //Retourne le profil de l'utilisateur connecté
    getProfil() {
        let profil = 'Administrateur';
        if (this.state.userconnected !== null && this.state.userconnected !== undefined && this.state.userconnected !== '') {
            profil = this.state.userconnected.profil;
        }
        return profil;
    }

    // Affectation des variables de state
    onInputChange = (e, name, typeInput, data) => {
        const val = (e.target && e.target.value || e.value) || '';
        if (typeInput == 'fk') {
            data[`${name}`]['id'] = val;
        } else {
            data[`${name}`] = val;
        }
        return data;
    }

    // Affectation des variables de state
    onCalendarChange = (e, name, data) => {
        const val = (e.target.value) || '';
        data[`${name}`] = val;
        return data;
    }

    // Permet de retourner un objet dans une liste d'objet en fonction de son identifiant
    getSelectedData(liste, id) {
        return liste.filter(function (el) {
            return el.value === id;
        })[0];
    }

    // Permet de retourner un paramètre par code
    getSelectedParam(liste_values, code) {
        let val = "";
        let liste = liste_values.filter(function (el) {
            return el.code === code;
        });
        if (liste !== null && liste !== undefined) {
            if (liste.length > 0) {
                val = liste[0].value;
            }
        }
        return val;
    }

    /*###################  ENDPOINTS DE GESTION DES ETATS            ################### */

    // Tableau de bord
    get_dashboard() {
        var lien = this.wsurl() + '/etats/get_dashboard';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Journal des demandes
    get_journal_demande(pd1, pd2, pOpId, pStatut) {
        var lien = this.wsurl() + '/etats/get_liste_demande?pd1=' + pd1 + '&pd2=' + pd2 + '&pOpId=' + pOpId + '&pStatut=' + pStatut;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    // Statistiques des demandes par mois
    get_liste_stat_demande_mois(pd1, pd2) {
        var lien = this.wsurl() + '/etats/get_liste_stat_demande_mois?pd1=' + pd1 + '&pd2=' + pd2;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Statistiques des demandes par statut
    get_liste_stat_demande_statut(pd1, pd2) {
        var lien = this.wsurl() + '/etats/get_liste_stat_demande_statut?pd1=' + pd1 + '&pd2=' + pd2;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Statistiques des demandes par opérateur
    get_liste_stat_demande_operateur(pd1, pd2) {
        var lien = this.wsurl() + '/etats/get_liste_stat_demande_operateur?pd1=' + pd1 + '&pd2=' + pd2;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Impression de décision
    generer_decision(data) {
        var lien = this.wsurl() + '/etats/generer_decision?idDem=' + data.id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "DECISION_" + data.numDecision + '_' + moment(new Date()).format('DDMMYYYYhhmmss') + ".pdf");
                a.click();
            }
            );
        });
    }


    /*###################  ENDPOINTS DE GESTION DES ETATS            ################### */



    /*###################  ENDPOINTS DE GESTION DES UTILISATEURS            ################### */

    //Update password
    update_password(data, token) {
        var lien = this.wsurl() + '/users/updatepassword';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    //Reset password
    reset_password(data) {
        var lien = this.wsurl() + '/users/resetpassword';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Register
    register(data) {
        var lien = this.wsurl() + '/register';
        let methode = 'POST';
        data.iduser = '0';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter et modifier un user
    save_utilisateur(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/users/add';
        } else {
            lien = this.wsurl() + '/users/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des users
    get_liste_utilisateur() {
        var lien = this.wsurl() + '/users/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    check_login(login) {
        var lien = this.wsurl() + '/check_login?login=' + login;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un user
    delete_utilisateur(data) {
        var lien = this.wsurl() + '/users/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    //Authentification d'un utilisateur
    get_identification(login, pwd) {
        var lien = this.wsurl() + '/get_identification';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userName: login, password: pwd })
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES USERS         ################### */



    /*###################  ENDPOINTS DE GESTION DES DEMANDES DE VALEUR            ################### */

    //Ajouter et modifier une dvaleur
    save_dvaleur(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/dvaleur/add';
        } else {
            lien = this.wsurl() + '/dvaleur/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // Retourne une demande par ID
    get_dvaleur(id) {
        var lien = this.wsurl() + '/dvaleur/get_demande?id=' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une dvaleur
    delete_dvaleur(data) {
        var lien = this.wsurl() + '/dvaleur/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES DEMANDES DE VALEUR          ################### */




    /*###################  ENDPOINTS DE GESTION DES DEMANDES DE TARIF            ################### */

    //Ajouter et modifier une dtarif
    save_dtarif(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/dtarif/add';
        } else {
            lien = this.wsurl() + '/dtarif/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // Retourne une demande par ID
    get_dtarif(id) {
        var lien = this.wsurl() + '/dtarif/get_demande?id=' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    //Suppression d'une dtarif
    delete_dtarif(data) {
        var lien = this.wsurl() + '/dtarif/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES DEMANDES DE TARIF          ################### */




    /*###################  ENDPOINTS DE GESTION DES DEMANDES D'ORIGINE            ################### */

    //Ajouter et modifier une dorigine
    save_dorigine(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/dorigine/add';
        } else {
            lien = this.wsurl() + '/dorigine/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne une demande par ID
    get_dorigine(id) {
        var lien = this.wsurl() + '/dorigine/get_demande?id=' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une dorigine
    delete_dorigine(data) {
        var lien = this.wsurl() + '/dorigine/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES DEMANDES D'ORIGINE          ################### */



    /*###################  ENDPOINTS DE GESTION DES MATIERES            ################### */

    //Ajouter et modifier une matiere
    save_matiere(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/matiere/add';
        } else {
            lien = this.wsurl() + '/matiere/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des matieres
    get_liste_matiere(idDem) {
        var lien = this.wsurl() + '/matiere/get_liste_matiere?idDem=' + idDem;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une matiere
    delete_matiere(data) {
        var lien = this.wsurl() + '/matiere/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES MATIERES         ################### */




    /*###################  ENDPOINTS DE GESTION DES DOCUMENTS            ################### */

    //Ajouter et modifier une document
    save_document(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/document/add';
        } else {
            lien = this.wsurl() + '/document/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des documents
    get_liste_document(idDem) {
        var lien = this.wsurl() + '/document/get_liste_doc?idDem=' + idDem;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une document
    delete_document(data) {
        var lien = this.wsurl() + '/document/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES DOCUMENTS         ################### */





    /*###################  ENDPOINTS DE GESTION DES DEMANDES            ################### */

    //Ajouter et modifier une demande
    save_demande(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/demande/add';
        } else {
            lien = this.wsurl() + '/demande/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Rendre une décision
    update_decision_demande(data) {
        var lien = this.wsurl() + '/demande/update_decision';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Opération en bloc sur les demandes
    toogle_demande(data) {
        var lien = this.wsurl() + '/demande/toogle_bloc';
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // Retourne la liste générale des demandes
    get_liste_demande(pd1, pd2, pidOp, pStatut, pType) {
        var lien = this.wsurl() + '/demande/get_all?pd1=' + pd1 + '&pd2=' + pd2 + '&pidOp=' + pidOp + '&pStatut=' + pStatut + '&pType=' + pType;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une demande
    delete_demande(data) {
        var lien = this.wsurl() + '/demande/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES DEMANDES         ################### */




    /*###################  ENDPOINTS DE GESTION DES OPERATEURS            ################### */

    //Ajouter et modifier une operateur
    save_operateur(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/operateur/add';
        } else {
            lien = this.wsurl() + '/operateur/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne une demande par ID
    get_demande(id) {
        var lien = this.wsurl() + '/demande/get_demande?id=' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    check_email_operateur(email) {
        var lien = this.wsurl() + '/check_email_operateur?email=' + email;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des operateurs
    get_liste_operateur() {
        var lien = this.wsurl() + '/operateur/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un operateur
    delete_operateur(data) {
        var lien = this.wsurl() + '/operateur/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES OPERATEURS         ################### */




    /*###################  ENDPOINTS DE GESTION DES TRAITEMENTS            ################### */

    //Ajouter et modifier une traitement
    save_traitement(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/traitement/add';
        } else {
            lien = this.wsurl() + '/traitement/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    // Retourne la liste générale des traitements par demande
    get_liste_traitement(idDem) {
        var lien = this.wsurl() + '/traitement/get_liste_trt?idDem=' + idDem;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une traitement
    delete_traitement(data) {
        var lien = this.wsurl() + '/traitement/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES TRAITEMENTS         ################### */




    /*###################  ENDPOINTS DE GESTION DES PARAMS            ################### */

    //Ajouter et modifier une param
    save_param(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/param/add';
        } else {
            lien = this.wsurl() + '/param/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des params
    get_liste_param() {
        var lien = this.wsurl() + '/param/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne un  paramètre par code
    get_param(code) {
        var lien = this.wsurl() + '/param/get_param?code=' + code;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une param
    delete_param(data) {
        var lien = this.wsurl() + '/param/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES PARAMS         ################### */





    /*###################  ENDPOINTS DE GESTION DES PAYS            ################### */

    //Ajouter et modifier une pays
    save_pays(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/pays/add';
        } else {
            lien = this.wsurl() + '/pays/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des payss
    get_liste_pays() {
        var lien = this.wsurl() + '/pays/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une pays
    delete_pays(data) {
        var lien = this.wsurl() + '/pays/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES PAYS         ################### */



    /*###################  ENDPOINTS DE GESTION DES CODIFICATIONS            ################### */

    //Ajouter et modifier une codification
    save_codification(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/codification/add';
        } else {
            lien = this.wsurl() + '/codification/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des codifications
    get_liste_codification(paramCodif) {
        var lien = this.wsurl() + '/codification/get_liste_codif?paramCodif=' + paramCodif;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une codification
    delete_codification(data) {
        var lien = this.wsurl() + '/codification/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES CODIFICATIONS         ################### */


    /*###################  ENDPOINTS DE GESTION DES NOTIFICATIONS            ################### */

    //Ajouter une notification
    save_notif_bloc(data) {
        var lien = this.wsurl() + '/notification/add_bloc';
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter et modifier une notification
    save_notification(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/notification/add';
        } else {
            lien = this.wsurl() + '/notification/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne nombre de notificaitons
    get_nb_msg(token) {
        var lien = this.wsurl() + '/notification/get_nb_msg';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des notificaiton
    get_liste_notifications(pd1, pd2, pObjet, pStatut) {
        var lien = this.wsurl() + '/notification/get_all?pd1=' + pd1 + '&pd2=' + pd2 + '&pObjet=' + pObjet + '&pStatut=' + pStatut;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES NOTIFICATIONS         ################### */




    /*###################  ENDPOINTS DE GESTION DES UTILS            ################### */


    download_fichier_joint(namefile) {
        var lien = this.wsurl() + '/utils/download_attachment?namefile=' + namefile;
        window.location = lien;
    }

    download_file(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_file?namefile=' + namefile + '&destination=' + (destination == undefined ? 'fichiers' : destination);
        return lien
    }

    upload_file(file, namefile, destination) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namefile', namefile);
        formData.append('destination', destination == undefined ? 'fichiers' : destination);
        return fetch(lien, {
            method: methode,
            headers: {
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    upload_multiple_files(files) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload_multifiles';
        const formData = new FormData();
        /*  for (var i = files.length; i--;) {
             formData.append('files', files);
         } */
        for (const [i, file] of Array.from(files).entries()) {
            formData.append(`files`, file);
        }
        console.log(formData);
        return fetch(lien, {
            method: methode,
            headers: {
                'Authorization': `Bearer ${this.getToken()}`,
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // Retourne la liste des dernières actions
    get_liste_last_actions(pAction) {
        var lien = this.wsurl() + '/trace/find_by_user_action?pAction=' + pAction + '&pidUser=' + this.get_userconnected().id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    /*###################  FIN ENDPOINTS DE GESTION DES UTILS         ################### */





}