import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import 'moment/locale/fr';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';

/* Page de gestion des Notificaiton */
export default class FormNotificaiton extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [],
            liste_demande: [],
            formData: {
                id: 0,
                date: null,
                contact: "",
                contenu: "",
                canal: '',
                statut: 'En attente',
                lu: 'Non',
                libelleRef: '',
                ref: '',
                read: false
            },
            liste_objet: [
                { label: 'Inscription', value: 'Inscription' },
                { label: 'Inscription validée', value: 'Inscription validée' },
                { label: 'Compte activé', value: 'Compte activé' },
                { label: 'Compte suspendu', value: 'Compte suspendu' },
                { label: 'Mot de passe réinitialisé', value: 'Mot de passe réinitialisé' },
                { label: 'Validation de votre demande', value: 'Validation de votre demande' },
                { label: 'Confirmation de votre demande', value: 'Confirmation de votre demande' },
                { label: 'Rejet de votre demande', value: 'Rejet de votre demande' },
                { label: 'Soumission de demande', value: 'Soumission de demande' },
                { label: 'Recevabilité de votre demande', value: 'Recevabilité de votre demande' },
                { label: "Demande d'information", value: "Demande d'information" },
            ],
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ],
            pd1: new Date(),
            pd2: new Date(),
            pObjet: 'Tous',
            pStatut: 'Tous',
            showindicator: false,
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 30);
        this.appservice = new AppServices();
    }

    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }


    /* Récupération de la liste globale des notifications */
    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.appservice.get_liste_notifications(spd1, spd2, this.state.pObjet, this.state.pStatut).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                date: null,
                contact: "",
                contenu: "",
                canal: '',
                statut: 'En attente',
                lu: 'Non',
                libelleRef: '',
                ref: '',
                read: false
            }
        });
        this.setState({ showindicator: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }


    /* Enregistrer les informations du formulaire notification */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.date == null || this.state.formData.contact == "" || this.state.formData.contenu == "" || this.state.formData.idDem == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_notification(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }


    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle codification */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des classes en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("LISTE_NOTIFICATIONS");
    }


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    resend(){

    }
    /* Colonne Action du tableau contenant la liste des codifications */
    actionTemplate(rowData, column) {
        return <Button label="Renvoyer" icon="pi pi-refresh" disabled={rowData.statut!=='Echec'} onClick={(e) => this.resend(rowData)} />
    }



    /* Bloc visuel (propre à React) */
    render() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 570 }}>
                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        {this.appservice.getProfil() !== 'Guest' ?
                            <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 2, paddingBottom: 2 }} />
                            : <div style={{marginTop: -25}}></div>}
                        <br />

                        <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="grid" >

                                        <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                            <span className="p-float-label">
                                                <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                                <label htmlFor="inputtext">Date début</label>
                                            </span>
                                        </div>
                                        <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                            <span className="p-float-label">
                                                <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2021:2070" />
                                                <label htmlFor="inputtext">Date fin</label>
                                            </span>
                                        </div>

                                        <div className="col-6 md:col-3" style={{ marginTop: 5 }}>
                                            <span className="p-float-label">
                                                <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_objet)}
                                                    value={this.state.pObjet} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ pObjet: e.value }, () => {
                                                        this.get_liste_data();
                                                    })} autoWidth={false} style={{ width: '100%' }} />
                                                <label htmlFor="inputtext">Objet</label>
                                            </span>
                                        </div>

                                        <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                            <span className="p-float-label">
                                                <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'En attente', label: 'En attente' }, { value: 'Envoyé', label: 'Envoyé' }, { value: 'Echec', label: 'Echec' }]} value={this.state.pStatut} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ pStatut: e.value }, () => {
                                                    this.get_liste_data();
                                                })} autoWidth={false} style={{ width: '100%' }} />
                                                <label htmlFor="inputtext">Statut</label>
                                            </span>
                                        </div>

                                        <div className="col-6 md:col-2" style={{ marginTop: 5 }}>
                                            <SplitButton loading={this.state.showindicator} label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        </div>
                                        <div className="col-6 md:col-1" style={{ marginTop: 5 }}>
                                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Panel> 
 
                        {/* Tableau contenant la liste des notifications existantes dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} paginator rows={20}
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" showGridlines
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={false}
                            onRowClick={e => this.setState({ selectedData: e.data })}>
                            <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                            <Column field="date" body={(rowData, column) => {
                                return <span style={{fontWeight: rowData.contact.includes(this.appservice.get_userconnected().username) && rowData.read==false?'bold':'normal' }}>{rowData.date}</span>
                            }} header="Date" style={{ width: '8%' }} />
                            <Column field="contact" body={(rowData, column) => {
                                return <span style={{fontWeight: rowData.contact.includes(this.appservice.get_userconnected().username) && rowData.read==false?'bold':'normal' }}>{rowData.contact}</span>
                            }} header="Contact" style={{ width: '15%' }} />
                            <Column field="objet" body={(rowData, column) => {
                                return <span style={{fontWeight: rowData.contact.includes(this.appservice.get_userconnected().username) && rowData.read==false?'bold':'normal' }}>{rowData.objet}</span>
                            }} header="Objet" style={{ width: '15%' }} />
                            <Column field="canal" body={(rowData, column) => {
                                return <span style={{fontWeight: rowData.contact.includes(this.appservice.get_userconnected().username) && rowData.read==false?'bold':'normal' }}>{rowData.canal}</span>
                            }} header="Canal" style={{ width: '6%' }} />
                            <Column field="contenu" body={(rowData, column) => {
                                return <span style={{fontWeight: rowData.contact.includes(this.appservice.get_userconnected().username) && rowData.read==false?'bold':'normal' }}>{rowData.contenu}</span>
                            }} header="Contenu" />
                            <Column field="statut" body={(rowData, column) => {
                                return <span style={{fontWeight: rowData.contact.includes(this.appservice.get_userconnected().username) && rowData.read==false?'bold':'normal' }}>
                                    {rowData.statut}<br/> 
                                    {rowData.dateStatut}
                                    </span>
                            }} header="Statut" style={{ width: '12%' }} />
                        </DataTable>

                        {/* Boite de dialogue d'ajout et de modification des notifications  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="date">Date *</label>
                                    <Calendar keyfilter='pint' id="name1" value={this.state.formData.date} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.date })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'date', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.date && <small className="p-invalid" style={{ color: 'red' }}>Le champs date est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="contact">Contact *</label>
                                    <InputText id="contact" value={this.state.formData.contact} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.contact })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'contact', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.contact && <small className="p-invalid" style={{ color: 'red' }}>Le champs contact est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="contenu">Contenu *</label>
                                    <InputText id="contenu" value={this.state.formData.contenu} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.contenu })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'contenu', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.contenu && <small className="p-invalid" style={{ color: 'red' }}>Le champs contenu est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="canal">Canal *</label>
                                    <InputText id="canal" value={this.state.formData.canal} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.canal })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'canal', '', this.state.formData) })}
                                        required />
                                    {this.state.submit && !this.state.formData.canal && <small className="p-invalid" style={{ color: 'red' }}>Le champs canal est obligatoire.</small>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="idDem">Demande * </label>
                                    <Dropdown autoWidth={false} value={this.state.formData.idDem} filter={true} filterBy='label' filterMatchMode='contains'
                                        options={this.state.liste_demande}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'idDem', '', this.state.formData) })}
                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.idDem })} />
                                    {this.state.submit && !this.state.formData.idDem && <small className="p-invalid" style={{ color: 'red' }}>Le champs demande est obligatoire</small>}
                                </div>
                            </div>
                        </Dialog>
                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedData.libelle}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div >
        );
    }
}
