import React from "react";

const Stats = () => {
  return (
    <>
      <div className="counter-area" style={{marginTop: -150}}>
        <div className="container">
          <div className="row counter-bg">
           

          <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-people"></i>
                <h3>
                  <span className="counter">2500</span>+
                </h3>
                <p>Comptes</p>
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-clip-board"></i>
                <h3 className="counter">85</h3>
                <p>Décisions rendues</p>
              </div>
            </div>


            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-files-stack"></i>
                <h3 className="counter">750</h3>
                <p>Demandes</p>
              </div> 
            </div>

        
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="counter-item">
                <i className="icofont-badge"></i>
                <h3>
                  <span className="counter">30</span>J
                </h3>
                <p>Durée</p>
              </div>
            </div>



          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
