import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel';
 
/* Page d'édition des demandes */
export default class EditionJournalDemande extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_operateur: [],
            pStatut: 'Tous',
            pOpId: 'Tous',
            pd1: new Date(),
            pd2: new Date(),
            showindicator: false,
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
            ], 
        };
        this.state.pd1.setDate(this.state.pd1.getDate() - 30);
        this.appservice = new AppServices();
    }



    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_operateur();
    }

    /* Récupération de la liste globale des pme */
    get_liste_operateur() {
        this.setState({ showindicator: true,liste_operateur: [] });
        this.appservice.get_liste_operateur().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nom, code: x.code }
                    });
                    this.setState({liste_operateur: liste });
                }
            }
        });
    }



    /* Récupération de la liste globale des demandes */
    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.appservice.get_journal_demande(spd1, spd2, this.state.pOpId, this.state.pStatut).then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }




    getPanelRecherche() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        return <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
            <div className="grid">
                <div className="col-6 md:col-2" >
                    <span className="p-float-label" style={{ marginTop: 8 }}>
                        <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => { this.get_liste_data() })} dateFormat="dd/mm/yy" yearNavigator yearRange="2024:2070" style={{width: '100%'}}/>
                        <label htmlFor="inputtext" >Date début</label>
                    </span>
                </div>
                <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                    <span className="p-float-label">
                        <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => { this.get_liste_data() })}  dateFormat="dd/mm/yy" yearNavigator yearRange="2024:2070" style={{width: '100%'}}/>
                        <label htmlFor="inputtext" >Date fin</label>
                    </span>
                </div>
                <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                    <span className="p-float-label">
                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_operateur)}
                            value={this.state.pOpId} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ pOpId: e.value }, () => {
                                this.get_liste_data();
                            })} autoWidth={false} style={{ width: '100%' }} />
                        <label htmlFor="inputtext">Opérateur</label>
                    </span>
                </div>

                <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                    <span className="p-float-label">
                        <Dropdown options={[{ value: 'Tous', label: 'Tous' }, { value: 'En attente', label: 'En attente' }, { value: 'Validé', label: 'Validé' }, { value: 'Rejeté', label: 'Rejeté' }, { value: 'Confirmé', label: 'Confirmé' }, { value: 'Recevable', label: 'Recevable' }]} value={this.state.pStatut} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ pStatut: e.value }, () => { this.get_liste_data() })} autoWidth={false} style={{ width: '100%' }} />
                        <label htmlFor="inputtext" >Statut</label>
                    </span>
                </div>
                <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                        <SplitButton label="Rechercher" icon="pi pi-search"  onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                    }
                </div>

            </div>

        </Panel>;
    }



    /* Exportation du tableau contenant la liste des messages en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("JOURNAL_DEMANDE");
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => { 
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'NUMERO': x.numEnreg,'DATE': x.date,'TYPE': x.typeDem,'STATUT': x.statut,'DATE_DEBUT': x.dateDebut,'DELAI': x.delai, 'DATE_DECISION': x.dateDecision,'TRAITEMENT': x.statutDelai, 'NUM_DECISION': x.numDecision, 'DESC_MARCHANDISE': x.descMarchandise,'REQUERANT': x.nomOp,'TELEPHONE': x.telephoneOp}
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'JOURNAL_DEMANDE_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    /* Bloc visuel (propre à React) */
    render() {

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ minHeight: 850, paddingBlock: 2 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {this.getPanelRecherche()}

                        {/* Tableau contenant la liste  des factures */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} showGridlines
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={false}>
                            <Column body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} header="No" style={{ width: '3%' }} />
                            <Column field="numEnreg" header="Numero" style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                            <Column field="date" header="Date" style={{ width: '10%' }} sortable />
                            <Column field="typeDem" header="Type" sortable style={{ width: '10%' }} />
                            <Column field="statut" header="Statut" style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                            <Column field="delai" header="Délai" style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                            <Column field="nomOp" header="Opérateur" filter={true} filterMatchMode='contains'/>
                            <Column field="telephoneOp" header="Téléphone" style={{ width: '4%' }} filter={true} filterMatchMode='contains'/>
                        </DataTable>
                    </div>
                </div>
            </div >
        );
    }
}
